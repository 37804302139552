import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { SharedModule } from 'app/shared/shared.module';
import { ClassicLayoutComponent } from 'app/layout/layouts/vertical/classic/classic.component';
import {MatSelectModule} from '@angular/material/select';
import {TranslocoModule} from '@ngneat/transloco';
import { MobileTrisSelectComponent } from './mobile-tris-select/mobile-tris-select.component';
import {FuseDrawerModule} from '../../../../../@fuse/components/drawer';
import {GenericDialogModule} from '../../../common/generic-components/generic-dialog/generic-dialog.module';
import { DialogSetCicloCorsoRuoloComponent } from './dialog-set-ciclo-corso-ruolo/dialog-set-ciclo-corso-ruolo.component';
import {InfoWrapModule} from '../../../common/info-dialog/info-wrap.module';
import {DialogWrapModule} from '../../../common/dialog-wrap/dialog-wrap.module';
import { ShowCicloCorsoRuoloInfoComponent } from './show-ciclo-corso-ruolo-info/show-ciclo-corso-ruolo-info.component';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
import {DialogPrivacyModule} from '../../../../shared/dialogs/dialog-privacy/dialog-privacy.module';
import {FuseAlertModule} from '../../../../../@fuse/components/alert';
import {DialogApprovalModule} from '../../../../shared/dialogs/dialog-approval/dialog-approval.module';
import {DialogLicensesModule} from '../../../../shared/dialogs/dialog-licenses/dialog-licenses.module';
import {DialogSetCicloComponent} from './dialog-set-ciclo/dialog-set-ciclo.component';
import {MatChipsModule} from '@angular/material/chips';
import { SupportMenuComponent } from './support-menu/support-menu.component';
import {
    DialogErrorsListExcelDottorandiModule
} from "../../../../shared/dialogs/dialog-errors-list-excel-dottorandi/dialog-errors-list-excel-dottorandi.module";

@NgModule({
    declarations: [
        ClassicLayoutComponent,
        MobileTrisSelectComponent,
        DialogSetCicloCorsoRuoloComponent,
        ShowCicloCorsoRuoloInfoComponent,
        DialogSetCicloComponent,
        SupportMenuComponent
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        LanguagesModule,
        NotificationsModule,
        UserModule,
        SharedModule,
        MatSelectModule,
        TranslocoModule,
        FuseDrawerModule,
        GenericDialogModule,
        InfoWrapModule,
        DialogWrapModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        DialogPrivacyModule,
        FlexModule,
        FuseAlertModule,
        DialogApprovalModule,
        DialogLicensesModule,
        DialogErrorsListExcelDottorandiModule,
        MatChipsModule
    ],
    exports     : [
        ClassicLayoutComponent
    ],

})
export class ClassicLayoutModule
{
}
