/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddOrUpdateFondoRequest } from '../model/addOrUpdateFondoRequest';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { FondoView } from '../model/fondoView';
import { PageFondoView } from '../model/pageFondoView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FondiService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette di aggiungere un fondo.
     * 
     * @param fileFooterPdf 
     * @param fileHeaderPdf 
     * @param request 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFondoForm(fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe?: 'body', reportProgress?: boolean): Observable<PageFondoView>;
    public addFondoForm(fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageFondoView>>;
    public addFondoForm(fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageFondoView>>;
    public addFondoForm(fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileFooterPdf !== undefined) {
            formParams = formParams.append('fileFooterPdf', <any>fileFooterPdf) as any || formParams;
        }
        if (fileHeaderPdf !== undefined) {
            formParams = formParams.append('fileHeaderPdf', <any>fileHeaderPdf) as any || formParams;
        }
        if (request !== undefined) {
            formParams = formParams.append('request', new Blob([JSON.stringify(request)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<PageFondoView>('post',`${this.basePath}/api/v1/fondo/add`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del footer pdf per un fondo.
     * 
     * @param id_fondo id del fondo
     * @param nome_file nome del file footer da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFooterPdfFondo(id_fondo: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getFooterPdfFondo(id_fondo: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getFooterPdfFondo(id_fondo: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getFooterPdfFondo(id_fondo: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_fondo === null || id_fondo === undefined) {
            throw new Error('Required parameter id_fondo was null or undefined when calling getFooterPdfFondo.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getFooterPdfFondo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_fondo !== undefined && id_fondo !== null) {
            queryParameters = queryParameters.set('id_fondo', <any>id_fondo);
        }
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/fondo/footer-pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;header pdf per un fondo.
     * 
     * @param id_fondo id del fondo
     * @param nome_file nome del file header da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHeaderPdfFondo(id_fondo: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getHeaderPdfFondo(id_fondo: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getHeaderPdfFondo(id_fondo: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getHeaderPdfFondo(id_fondo: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_fondo === null || id_fondo === undefined) {
            throw new Error('Required parameter id_fondo was null or undefined when calling getHeaderPdfFondo.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getHeaderPdfFondo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_fondo !== undefined && id_fondo !== null) {
            queryParameters = queryParameters.set('id_fondo', <any>id_fondo);
        }
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/fondo/header-pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;anteprima pdf per un fondo.
     * 
     * @param id_fondo id del fondo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfAntemprimaHeaderFooterFondo(id_fondo: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfAntemprimaHeaderFooterFondo(id_fondo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfAntemprimaHeaderFooterFondo(id_fondo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfAntemprimaHeaderFooterFondo(id_fondo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_fondo === null || id_fondo === undefined) {
            throw new Error('Required parameter id_fondo was null or undefined when calling getPdfAntemprimaHeaderFooterFondo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_fondo !== undefined && id_fondo !== null) {
            queryParameters = queryParameters.set('id_fondo', <any>id_fondo);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/fondo/anteprima-pdf-header-footer`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista dei fondi.
     * 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchFondi(page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageFondoView>;
    public searchFondi(page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageFondoView>>;
    public searchFondi(page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageFondoView>>;
    public searchFondi(page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageFondoView>('post',`${this.basePath}/api/v1/fondo/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di aggiornare un fondo.
     * 
     * @param id_fondo id del fondo
     * @param fileFooterPdf 
     * @param fileHeaderPdf 
     * @param request 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFondoForm(id_fondo: string, fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe?: 'body', reportProgress?: boolean): Observable<FondoView>;
    public updateFondoForm(id_fondo: string, fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FondoView>>;
    public updateFondoForm(id_fondo: string, fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FondoView>>;
    public updateFondoForm(id_fondo: string, fileFooterPdf?: Blob, fileHeaderPdf?: Blob, request?: AddOrUpdateFondoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_fondo === null || id_fondo === undefined) {
            throw new Error('Required parameter id_fondo was null or undefined when calling updateFondo.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_fondo !== undefined && id_fondo !== null) {
            queryParameters = queryParameters.set('id_fondo', <any>id_fondo);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileFooterPdf !== undefined) {
            formParams = formParams.append('fileFooterPdf', <any>fileFooterPdf) as any || formParams;
        }
        if (fileHeaderPdf !== undefined) {
            formParams = formParams.append('fileHeaderPdf', <any>fileHeaderPdf) as any || formParams;
        }
        if (request !== undefined) {
            formParams = formParams.append('request', new Blob([JSON.stringify(request)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<FondoView>('put',`${this.basePath}/api/v1/fondo/update`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
