/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi funzionalità
 */
export type ChiaveFunzionalitaEnum = 'area_didattica' | 'registro_attivita' | 'registro_tutor' | 'gestione_fondi' | 'gestione_titolare_fondo' | 'missioni' | 'spese' | 'modifica_budget_dottorando' | 'mobilita' | 'preautorizzazione_richiesta_mobilita' | 'superamento_anno' | 'gestione_esterni' | 'gestione_dott_no_matricola' | 'sincronizzazioni' | 'caricamento_excel_dottorandi';

export const ChiaveFunzionalitaEnum = {
    AreaDidattica: 'area_didattica' as ChiaveFunzionalitaEnum,
    RegistroAttivita: 'registro_attivita' as ChiaveFunzionalitaEnum,
    RegistroTutor: 'registro_tutor' as ChiaveFunzionalitaEnum,
    GestioneFondi: 'gestione_fondi' as ChiaveFunzionalitaEnum,
    GestioneTitolareFondo: 'gestione_titolare_fondo' as ChiaveFunzionalitaEnum,
    Missioni: 'missioni' as ChiaveFunzionalitaEnum,
    Spese: 'spese' as ChiaveFunzionalitaEnum,
    ModificaBudgetDottorando: 'modifica_budget_dottorando' as ChiaveFunzionalitaEnum,
    Mobilita: 'mobilita' as ChiaveFunzionalitaEnum,
    PreautorizzazioneRichiestaMobilita: 'preautorizzazione_richiesta_mobilita' as ChiaveFunzionalitaEnum,
    SuperamentoAnno: 'superamento_anno' as ChiaveFunzionalitaEnum,
    GestioneEsterni: 'gestione_esterni' as ChiaveFunzionalitaEnum,
    GestioneDottNoMatricola: 'gestione_dott_no_matricola' as ChiaveFunzionalitaEnum,
    Sincronizzazioni: 'sincronizzazioni' as ChiaveFunzionalitaEnum,
    CaricamentoExcelDottorandi: 'caricamento_excel_dottorandi' as ChiaveFunzionalitaEnum
};