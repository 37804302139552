<ng-container *transloco="let t">

    <div class="grid" [class]="isScreenSmall ? 'flex flex-col' : ('grid-cols-'+(configuration.gridCols ?? configuration.steps.length))">

        <ng-container *ngFor="let step of configuration.steps; let i = index; let last = last">

            <div class="flex flex-col gap-2"
                 [class]="isScreenSmall ? 'pt-4' : undefined"
                 *ngIf="!data?.[i]?.skip || data?.[i]?.state === FlowTimelineStepState.COMPLETED">

                <app-flow-timeline-step [data]="data"
                                        [step]="step"
                                        [configuration]="configuration"
                                        [last]="last"
                                        [i]="i"
                                        [isScreenSmall]="isScreenSmall">
                </app-flow-timeline-step>

                <ng-container *ngIf="step.alternativeSteps as alternativeSteps; let first = first">
                    <ng-container *ngFor="let alternativeStep of alternativeSteps">
                        <span *ngIf="!first" class="text-xs font-semibold w-full text-center text-secondary italic">oppure</span>
                        <app-flow-timeline-step
                            [data]="data"
                            [step]="alternativeStep"
                            [hideLine]="true"
                            [configuration]="getAlternativeStepsConfig(alternativeSteps)"
                            [last]="last"
                            [i]="i"
                            [isScreenSmall]="isScreenSmall">
                        </app-flow-timeline-step>
                    </ng-container>
                </ng-container>

            </div>

            <div *ngIf="configuration.nestedConfigurations as nestedConfigs"
                 class="gap-3 flex flex-col"
                 [class]="'col-span-'+(configuration.nestedConfigurationsCols ?? 1)">
                <ng-container *ngFor="let nestedConfig of nestedConfigs; let first = first">
                    <span *ngIf="!first" class="text-xs font-semibold w-full text-center">oppure</span>
                    <app-flow-timeline [configuration]="nestedConfig"
                                       [data]="nestedConfig?.data">
                    </app-flow-timeline>
                </ng-container>
            </div>

        </ng-container>

    </div>

</ng-container>
