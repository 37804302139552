import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AggiornamentiBudgetStudenteService } from './api/aggiornamentiBudgetStudente.service';
import { AggiornamentiPianiDiStudiService } from './api/aggiornamentiPianiDiStudi.service';
import { AteneoService } from './api/ateneo.service';
import { AuditService } from './api/audit.service';
import { AuthService } from './api/auth.service';
import { BonificaDatiService } from './api/bonificaDati.service';
import { BudgetStudentiCicloService } from './api/budgetStudentiCiclo.service';
import { CicliService } from './api/cicli.service';
import { CicliCorsiDiStudiService } from './api/cicliCorsiDiStudi.service';
import { DelibereService } from './api/delibere.service';
import { DocumentoService } from './api/documento.service';
import { DottorandiCressiWsService } from './api/dottorandiCressiWs.service';
import { FondiService } from './api/fondi.service';
import { ImportService } from './api/import.service';
import { InfoService } from './api/info.service';
import { MediaService } from './api/media.service';
import { MissioneStudentiCicloService } from './api/missioneStudentiCiclo.service';
import { MissioniService } from './api/missioni.service';
import { MobilitaStudentiCicloService } from './api/mobilitaStudentiCiclo.service';
import { OfferteFormativeService } from './api/offerteFormative.service';
import { OperazioniMassiveService } from './api/operazioniMassive.service';
import { PeriodiDiMobilitaService } from './api/periodiDiMobilita.service';
import { PeriodiRegistroAttivitaService } from './api/periodiRegistroAttivita.service';
import { PianiDiStudiService } from './api/pianiDiStudi.service';
import { PianoDiStudiStudentiCicloService } from './api/pianoDiStudiStudentiCiclo.service';
import { QuestionariService } from './api/questionari.service';
import { RegistroStudentiCicloService } from './api/registroStudentiCiclo.service';
import { SpeseStudentiCicloService } from './api/speseStudentiCiclo.service';
import { StatsService } from './api/stats.service';
import { StudentiCicloService } from './api/studentiCiclo.service';
import { SuperamentiAnnoService } from './api/superamentiAnno.service';
import { SuperamentoAnnoStudentiCicloService } from './api/superamentoAnnoStudentiCiclo.service';
import { TemplateDelibereService } from './api/templateDelibere.service';
import { TicketsService } from './api/tickets.service';
import { UsersService } from './api/users.service';
import { UtilitiesService } from './api/utilities.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AggiornamentiBudgetStudenteService,
    AggiornamentiPianiDiStudiService,
    AteneoService,
    AuditService,
    AuthService,
    BonificaDatiService,
    BudgetStudentiCicloService,
    CicliService,
    CicliCorsiDiStudiService,
    DelibereService,
    DocumentoService,
    DottorandiCressiWsService,
    FondiService,
    ImportService,
    InfoService,
    MediaService,
    MissioneStudentiCicloService,
    MissioniService,
    MobilitaStudentiCicloService,
    OfferteFormativeService,
    OperazioniMassiveService,
    PeriodiDiMobilitaService,
    PeriodiRegistroAttivitaService,
    PianiDiStudiService,
    PianoDiStudiStudentiCicloService,
    QuestionariService,
    RegistroStudentiCicloService,
    SpeseStudentiCicloService,
    StatsService,
    StudentiCicloService,
    SuperamentiAnnoService,
    SuperamentoAnnoStudentiCicloService,
    TemplateDelibereService,
    TicketsService,
    UsersService,
    UtilitiesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
