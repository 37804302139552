/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { GenericResponseMessagesDTO } from '../model/genericResponseMessagesDTO';
import { JobDTO } from '../model/jobDTO';
import { JobType } from '../model/jobType';
import { QuestionarioImportDTO } from '../model/questionarioImportDTO';
import { QuestionarioInfoView } from '../model/questionarioInfoView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ImportService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Consente di caricare un file excel contenente la lista dei dottorandi.
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public caricaExcelDottorandiForm(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public caricaExcelDottorandiForm(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public caricaExcelDottorandiForm(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public caricaExcelDottorandiForm(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/dottorandi_excel`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di ricevere la data di ultima sincronizzazione per una tipologia di job
     * 
     * @param tipo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUltimaSincronizzazione(tipo: JobType, observe?: 'body', reportProgress?: boolean): Observable<JobDTO>;
    public getUltimaSincronizzazione(tipo: JobType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<JobDTO>>;
    public getUltimaSincronizzazione(tipo: JobType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<JobDTO>>;
    public getUltimaSincronizzazione(tipo: JobType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tipo === null || tipo === undefined) {
            throw new Error('Required parameter tipo was null or undefined when calling getUltimaSincronizzazione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<JobDTO>('get',`${this.basePath}/api/v1/sincronizzazioni/${encodeURIComponent(String(tipo))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare le altre attività o attivita trasversali e/o le categorie delle offerte formative per un determinato ciclo a partire dall&#x27;excel cineca
     * 
     * @param numeroCiclo 
     * @param excelDatiCineca 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAltreAttivitaOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importAltreAttivitaOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importAltreAttivitaOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importAltreAttivitaOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importAltreAttivitaOrCategorieFromExcelCineca.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelDatiCineca !== undefined) {
            formParams = formParams.append('excelDatiCineca', <any>excelDatiCineca) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/excelDatiCineca/altreAttivitaOrCategorie/${encodeURIComponent(String(numeroCiclo))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare le attività didattiche programmate e/o le categorie delle offerte formative per un determinato ciclo a partire dall&#x27;excel cineca
     * 
     * @param numeroCiclo 
     * @param excelDatiCineca 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAttivitaDidatticheProgrammateOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importAttivitaDidatticheProgrammateOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importAttivitaDidatticheProgrammateOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importAttivitaDidatticheProgrammateOrCategorieFromExcelCinecaForm(numeroCiclo: number, excelDatiCineca?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importAttivitaDidatticheProgrammateOrCategorieFromExcelCineca.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelDatiCineca !== undefined) {
            formParams = formParams.append('excelDatiCineca', <any>excelDatiCineca) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/excelDatiCineca/attivitaDidatticheOrCategorie/${encodeURIComponent(String(numeroCiclo))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare le attività extra dell&#x27;offerta formativa per un corso e per un determinato ciclo
     * 
     * @param numeroCiclo 
     * @param codiceCorsoEsse3 
     * @param excelAttivitaExtra 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAttivitaExtraOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaExtra?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importAttivitaExtraOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaExtra?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importAttivitaExtraOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaExtra?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importAttivitaExtraOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaExtra?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importAttivitaExtraOffertaFormativa.');
        }

        if (codiceCorsoEsse3 === null || codiceCorsoEsse3 === undefined) {
            throw new Error('Required parameter codiceCorsoEsse3 was null or undefined when calling importAttivitaExtraOffertaFormativa.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelAttivitaExtra !== undefined) {
            formParams = formParams.append('excelAttivitaExtra', <any>excelAttivitaExtra) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/attivitaExtraOffertaFormativa/${encodeURIComponent(String(numeroCiclo))}/${encodeURIComponent(String(codiceCorsoEsse3))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare le attività trasversali dell&#x27;offerta formativa per un corso e per un determinato ciclo
     * 
     * @param numeroCiclo 
     * @param codiceCorsoEsse3 
     * @param excelAttivitaTrasversali 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAttivitaTrasversaliOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaTrasversali?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importAttivitaTrasversaliOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaTrasversali?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importAttivitaTrasversaliOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaTrasversali?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importAttivitaTrasversaliOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelAttivitaTrasversali?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importAttivitaTrasversaliOffertaFormativa.');
        }

        if (codiceCorsoEsse3 === null || codiceCorsoEsse3 === undefined) {
            throw new Error('Required parameter codiceCorsoEsse3 was null or undefined when calling importAttivitaTrasversaliOffertaFormativa.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelAttivitaTrasversali !== undefined) {
            formParams = formParams.append('excelAttivitaTrasversali', <any>excelAttivitaTrasversali) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/attivitaTrasversaliOffertaFormativa/${encodeURIComponent(String(numeroCiclo))}/${encodeURIComponent(String(codiceCorsoEsse3))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare il collegio docenti di tutte le tipologie di personale
     * 
     * @param numeroCiclo 
     * @param excelCollegioDocentiAcc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCollegioDocentiForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseMessagesDTO>;
    public importCollegioDocentiForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseMessagesDTO>>;
    public importCollegioDocentiForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseMessagesDTO>>;
    public importCollegioDocentiForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importCollegioDocenti.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelCollegioDocentiAcc !== undefined) {
            formParams = formParams.append('excelCollegioDocentiAcc', <any>excelCollegioDocentiAcc) as any || formParams;
        }

        return this.httpClient.request<GenericResponseMessagesDTO>('post',`${this.basePath}/api/v1/import/collegioDocenti/all/${encodeURIComponent(String(numeroCiclo))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare il collegio docenti di tipo PERSONALE ACCADEMICO
     * 
     * @param numeroCiclo 
     * @param excelCollegioDocentiAcc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCollegioDocentiAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseMessagesDTO>;
    public importCollegioDocentiAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseMessagesDTO>>;
    public importCollegioDocentiAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseMessagesDTO>>;
    public importCollegioDocentiAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importCollegioDocentiAcc.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelCollegioDocentiAcc !== undefined) {
            formParams = formParams.append('excelCollegioDocentiAcc', <any>excelCollegioDocentiAcc) as any || formParams;
        }

        return this.httpClient.request<GenericResponseMessagesDTO>('post',`${this.basePath}/api/v1/import/collegioDocenti/acc/${encodeURIComponent(String(numeroCiclo))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare il collegio docenti di tipo ALTRO PERSONALE
     * 
     * @param numeroCiclo 
     * @param excelCollegioDocentiAcc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCollegioDocentiAltroForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseMessagesDTO>;
    public importCollegioDocentiAltroForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseMessagesDTO>>;
    public importCollegioDocentiAltroForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseMessagesDTO>>;
    public importCollegioDocentiAltroForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importCollegioDocentiAltro.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelCollegioDocentiAcc !== undefined) {
            formParams = formParams.append('excelCollegioDocentiAcc', <any>excelCollegioDocentiAcc) as any || formParams;
        }

        return this.httpClient.request<GenericResponseMessagesDTO>('post',`${this.basePath}/api/v1/import/collegioDocenti/altro/${encodeURIComponent(String(numeroCiclo))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare il collegio docenti di tipo PERSONALE NON ACCADEMICO
     * 
     * @param numeroCiclo 
     * @param excelCollegioDocentiAcc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCollegioDocentiNonAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseMessagesDTO>;
    public importCollegioDocentiNonAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseMessagesDTO>>;
    public importCollegioDocentiNonAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseMessagesDTO>>;
    public importCollegioDocentiNonAccForm(numeroCiclo: number, excelCollegioDocentiAcc?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importCollegioDocentiNonAcc.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelCollegioDocentiAcc !== undefined) {
            formParams = formParams.append('excelCollegioDocentiAcc', <any>excelCollegioDocentiAcc) as any || formParams;
        }

        return this.httpClient.request<GenericResponseMessagesDTO>('post',`${this.basePath}/api/v1/import/collegioDocenti/non-acc/${encodeURIComponent(String(numeroCiclo))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare i corsi dell&#x27;offerta formativa per un corso e per un determinato ciclo
     * 
     * @param numeroCiclo 
     * @param codiceCorsoEsse3 
     * @param excelOffertaFormativa 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCorsiOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importCorsiOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importCorsiOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importCorsiOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importCorsiOffertaFormativa.');
        }

        if (codiceCorsoEsse3 === null || codiceCorsoEsse3 === undefined) {
            throw new Error('Required parameter codiceCorsoEsse3 was null or undefined when calling importCorsiOffertaFormativa.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelOffertaFormativa !== undefined) {
            formParams = formParams.append('excelOffertaFormativa', <any>excelOffertaFormativa) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/corsiOffertaFormativa/${encodeURIComponent(String(numeroCiclo))}/${encodeURIComponent(String(codiceCorsoEsse3))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare i curriculum dei corsi di studi
     * 
     * @param numeroCiclo 
     * @param excelCurriculum 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCurriculumForm(numeroCiclo: number, excelCurriculum?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importCurriculumForm(numeroCiclo: number, excelCurriculum?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importCurriculumForm(numeroCiclo: number, excelCurriculum?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importCurriculumForm(numeroCiclo: number, excelCurriculum?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importCurriculum.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelCurriculum !== undefined) {
            formParams = formParams.append('excelCurriculum', <any>excelCurriculum) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/curriculum/${encodeURIComponent(String(numeroCiclo))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare le informazioni generali dei corsi di studi
     * 
     * @param excelInformazioniGenerali 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importInformazioniGeneraliForm(excelInformazioniGenerali?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importInformazioniGeneraliForm(excelInformazioniGenerali?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importInformazioniGeneraliForm(excelInformazioniGenerali?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importInformazioniGeneraliForm(excelInformazioniGenerali?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelInformazioniGenerali !== undefined) {
            formParams = formParams.append('excelInformazioniGenerali', <any>excelInformazioniGenerali) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/informazioniGenerali`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare i corsi, le altre attività e le attività trasversali dell&#x27;offerta formativa per un corso e per un determinato ciclo
     * 
     * @param numeroCiclo 
     * @param codiceCorsoEsse3 
     * @param excelOffertaFormativa 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importOffertaFormativaForm(numeroCiclo: number, codiceCorsoEsse3: string, excelOffertaFormativa?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (numeroCiclo === null || numeroCiclo === undefined) {
            throw new Error('Required parameter numeroCiclo was null or undefined when calling importOffertaFormativa.');
        }

        if (codiceCorsoEsse3 === null || codiceCorsoEsse3 === undefined) {
            throw new Error('Required parameter codiceCorsoEsse3 was null or undefined when calling importOffertaFormativa.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (excelOffertaFormativa !== undefined) {
            formParams = formParams.append('excelOffertaFormativa', <any>excelOffertaFormativa) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/offertaFormativa/${encodeURIComponent(String(numeroCiclo))}/${encodeURIComponent(String(codiceCorsoEsse3))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare un Questionario
     * 
     * @param file 
     * @param questionarioImportDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importQuestionarioForm(file?: Blob, questionarioImportDTO?: QuestionarioImportDTO, observe?: 'body', reportProgress?: boolean): Observable<QuestionarioInfoView>;
    public importQuestionarioForm(file?: Blob, questionarioImportDTO?: QuestionarioImportDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<QuestionarioInfoView>>;
    public importQuestionarioForm(file?: Blob, questionarioImportDTO?: QuestionarioImportDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionarioInfoView>>;
    public importQuestionarioForm(file?: Blob, questionarioImportDTO?: QuestionarioImportDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (questionarioImportDTO !== undefined) {
            formParams = formParams.append('questionarioImportDTO', <any>questionarioImportDTO) as any || formParams;
        }

        return this.httpClient.request<QuestionarioInfoView>('post',`${this.basePath}/api/v1/import/questionario`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di importare i budget di default dei corsi di studi per ciclo dal csv di setup
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importaBudgetCicliCorsiDiStudiFromFilePath(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public importaBudgetCicliCorsiDiStudiFromFilePath(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public importaBudgetCicliCorsiDiStudiFromFilePath(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public importaBudgetCicliCorsiDiStudiFromFilePath(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/import/budgetCicliCorsiDiStudi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di propagare i superamenti d&#x27;anno completati con data effettiva nel passato
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public propagaSuperamentiDAnnoDottorandi(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public propagaSuperamentiDAnnoDottorandi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public propagaSuperamentiDAnnoDottorandi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public propagaSuperamentiDAnnoDottorandi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/propaga-superamenti-d-anno`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare tutte le entità da esse3
     * 
     * @param annoAccademico anno accademico da sincronizzare, se NON passato sincronizza tutti gli anni attivi
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncAllByApi(annoAccademico?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncAllByApi(annoAccademico?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncAllByApi(annoAccademico?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncAllByApi(annoAccademico?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (annoAccademico !== undefined && annoAccademico !== null) {
            queryParameters = queryParameters.set('annoAccademico', <any>annoAccademico);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare la lista dei docenti da esse3
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncDocenti(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncDocenti(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncDocenti(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncDocenti(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/docenti`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare la lista dei dottorandi da esse3
     * 
     * @param annoAccademico anno accademico da sincronizzare, se NON passato sincronizza tutti gli anni attivi
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncDottorandi(annoAccademico?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncDottorandi(annoAccademico?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncDottorandi(annoAccademico?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncDottorandi(annoAccademico?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (annoAccademico !== undefined && annoAccademico !== null) {
            queryParameters = queryParameters.set('annoAccademico', <any>annoAccademico);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/dottorandi`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare le informazioni relative ai periodi di mobilità da esse3.
     *  sincronizza: le tipologie di mobilità, i tipi di interventi-supporto, le nazioni estere, gli atenei esteri.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncInfoMobilitaByApi(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncInfoMobilitaByApi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncInfoMobilitaByApi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncInfoMobilitaByApi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/mobilita`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare il paniere dei dottorandi da esse3
     * 
     * @param annoAccademico anno accademico da sincronizzare, se NON passato sincronizza tutti gli anni attivi
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncPaniereDottorandiCressiWs(annoAccademico?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncPaniereDottorandiCressiWs(annoAccademico?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncPaniereDottorandiCressiWs(annoAccademico?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncPaniereDottorandiCressiWs(annoAccademico?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (annoAccademico !== undefined && annoAccademico !== null) {
            queryParameters = queryParameters.set('annoAccademico', <any>annoAccademico);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/paniere-dottorandi-cressi-ws`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare la lista dei percorsi da esse3
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncPercorsi(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncPercorsi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncPercorsi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncPercorsi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/percorsi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare la lista del personale PTA da esse3
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncPersonalePTA(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncPersonalePTA(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncPersonalePTA(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncPersonalePTA(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/personalePTA`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di sincronizzare la lista dei tipi borse da esse3
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncTipiBorse(observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public syncTipiBorse(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public syncTipiBorse(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public syncTipiBorse(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/sincronizzazioni/tipi-borse`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
