import {Component, Input} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-recaptcha',
    templateUrl: './recaptcha.component.html',
    styleUrls: ['./recaptcha.component.scss'],
})
export class RecaptchaComponent {

    siteKey = environment.recaptcha.siteKey;
    @Input() formCtrl: FormControl;

    onResolved($event: string) {
        this.formCtrl.setValue($event);
    }

}
