import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isArray, isNil} from "lodash";
import moment, {Moment} from "moment";
import {DeliberaInfoViewImpl} from "../../../api-clients/generated/services";

export class CustomValidators {

    /**
     * Validatore che controlla se il dominio della mail è valido
     * @param notForbiddenDomain
     */



    static forbiddenDomain(notForbiddenDomain: string[]): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            const controlEmail = formControl;

            if (!controlEmail || !notForbiddenDomain) {
                return null;
            }

            const currentEmail: string = controlEmail.value;
            const emailDomain: string = currentEmail.split('@')[1];
            if (controlEmail.hasError('forbiddenDomain')) {
                delete controlEmail.errors.forbiddenDomain;
                controlEmail.updateValueAndValidity();
            }
            if (notForbiddenDomain.indexOf(emailDomain) !== -1) {
                return null;
            }
            const errors = {forbiddenDomain: true};
            controlEmail?.setErrors(errors);
            return errors;
        };
    }

    static atLeastOneFielFisRequired(name: string | string[], surname: string, text = 'form.name_or_surname_required'): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlName = formGroup.get(name);
            const controlSurname = formGroup.get(surname);

            const error = {checkNameSurname: text};

            const isError = isArray(controlName?.value) ? (controlName?.value?.length > 0 || controlSurname?.value?.trim()) : (controlName?.value?.trim() || controlSurname?.value?.trim())
            if (isError) {
                controlName?.setErrors(null);
                controlSurname?.setErrors(null);
                return null;
            } else {
                controlName?.setErrors(error);
                controlSurname?.setErrors(error);
            }

            return error;
        };

    }

    static rangeDataValidator(dataDa: string, dataA: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);

            const error = {checkRangeData: 'form.invalid_range_data'};

            if ((controlDataDa?.value && controlDataA?.value) || (!controlDataDa?.value && !controlDataA?.value)) {
                controlDataDa?.setErrors(null);
                controlDataA?.setErrors(null);
                return null;
            } else if ((!controlDataDa?.value || !controlDataA.value)) {
                controlDataDa?.setErrors(error);
                controlDataA?.setErrors(error);
            }
            return error;
        };
    }

    static noSpace(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control?.value == null) {
                return null;
            }
            return control?.value?.includes(' ') ? {
                noSpace: true,
            } : null;
        };
    }


    static datePresentiNelRange(dataDa: string, dataA: string, date: {
        dataInizioCalendario: Moment,
        dataFineCalendario: Moment
    }[], format?: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            if (!date?.length) {
                return null
            }
            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);

            const error = {datePresentiNelRange: 'form.date_presenti_nel_range'};
            if (!controlDataDa?.value || !controlDataA?.value) {
                return null;
            } else if (date.some(data => {
                return moment(controlDataDa.value, format).isBetween(data.dataInizioCalendario, data.dataFineCalendario, undefined, '[]')
                    || moment(controlDataA.value, format).isBetween(data.dataInizioCalendario, data.dataFineCalendario, undefined, '[]')
            })) {
                console.log(error)
                return error;
            } else {
                return null;
            }
        };
    }

    // shows the error on end date (as I'm setting the error on dataA ctrl)
    static dataAMaggioreDiDataA(dataDa: string, dataA: string, format: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);
            const error = {dataAMaggioreDiDataA: 'form.error_data_range'};
            if (!controlDataDa?.value || !controlDataA?.value) {
                return null;
            } else if (moment(controlDataDa.value, format).isAfter(moment(controlDataA.value, format))) {
                controlDataA.setErrors(error);
                return error;
            } else {
                return null;
            }
        };
    }

    static duplicateInArray(arrayName: string, controlName: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const arrayControl = formGroup.get(arrayName) as FormArray

            const valueArr = arrayControl.controls.map((item) => item.get(controlName)?.value);
            const isDuplicate = valueArr.some((item, idx) => {
                return !!item && valueArr.indexOf(item) != idx
            });
            return isDuplicate ? {duplicateInArray: 'Duplicate'} : null
        };
    }

    static duplicaDataInArray(arrayName: string, controlName: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const arrayControl = formGroup.get(arrayName) as FormArray

            const dateArr = arrayControl.controls.map((item) => item.get(controlName)?.value) as Date[];
            const dateTimeArr = dateArr?.map(v => v?.getTime())
            const isDuplicate = dateTimeArr.some((item, idx) => {
                return !!item && dateTimeArr.indexOf(item) != idx
            });
            return isDuplicate ? {duplicateInArray: 'Duplicate'} : null
        };
    }


    static onlyHoursNumber(event) {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const controlHoursNumber = formGroup.get(event);
            let isValidHours;
            if ((controlHoursNumber.value > 0 && controlHoursNumber.value < 25)) {
                isValidHours = true;
            } else {
                isValidHours = false;
            }
            return !isValidHours ? {invalidNumberHours: 'Il valore massimo consentito è 24'} : null;
        };
    }

    static noWhitespaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (control.hasError('whitespace')) {
                delete control.errors.whitespace;
                control.updateValueAndValidity();
            }
            const isWhitespace = (control?.value || '')?.trim()?.length === 0;
            const isValid = !isWhitespace;
            if (!isValid) {
                const errors = {'whitespace': true};
                control?.setErrors(errors);
            }

            return isValid ? null : {'whitespace': true};
        };
    }

    static ReferenteEsteroValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const nameCtrl = group.get('nome');
            const surnameCtrl = group.get('cognome');
            const emailCtrl = group.get('email');
            if(!nameCtrl?.value && !surnameCtrl?.value && !emailCtrl?.value){
                return null;
            } else {
                [nameCtrl, surnameCtrl, emailCtrl]?.forEach(control => {
                    if (control.hasError('whitespace')) {
                        delete control.errors.whitespace;
                        control.updateValueAndValidity();
                    }
                    const isWhitespace = (control?.value || '')?.trim()?.length === 0;
                    const isValidWhiteSpace = !isWhitespace;
                    const isValidRequired = !!control?.value;
                    if (!isValidWhiteSpace) {
                        const errors = {'whitespace': true};
                        control?.setErrors(errors);
                    }
                    if (!isValidRequired) {
                        const errors = {'required': true};
                        control?.setErrors(errors);
                    }
                    if(isValidWhiteSpace && isValidRequired){
                        control.setErrors(null);
                    }
                })
            }
        };
    }

    static noEmptyInnerHTMLValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let div = document.createElement("div");
            div.innerHTML = control?.value;
            if (control.hasError('whitespace')) {
                delete control.errors.whitespace;
                control.updateValueAndValidity();
            }
            const isWhitespace = (div.innerText || '')?.trim()?.length === 0 ;
            const isValid = !isWhitespace;
            if (!isValid) {
                const errors = {'whitespace': true};
                control?.setErrors(errors);
            }

            return isValid ? null : {'whitespace': true};
        };
    }

    static AtLeastOneYearValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const isValid = group.get('primoAnno')?.value
                || group.get('secondoAnno')?.value
                || group.get('terzoAnno')?.value;

            return isValid ? null : {'yearsRequired': true};
        };
    }

    static DocentiValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const isValid = group.get('docenti').value?.length > 0 || group.get('docentiExtra').value?.length > 0;
            return isValid ? null : {'teachersRequired': true};
        };
    }

    static minArrayLength(minLength: number): ValidatorFn {
        return (control: FormArray): {[key: string]: any} | null => {
            if (control.length < minLength) {
                return {'minArrayLength': {value: control.value}};
            }

            return null;
        };
    }


    static OrganizatorValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            const isValid = control.value?.length > 0;
            return isValid ? null : {'organizersRequired': true};
        };
    }

    static PresenzaDocumentoValidator() {
        return (group: FormGroup): ValidationErrors | null => {
            const isNotValid = (group.get('presenza').value && !group.get('tipo').value);
            return !isNotValid ? null : {'tipoDocumentoRequired': true};
        };
    }

    static StrutturaEsteraOspitanteValidator() {
        return (group: FormGroup): ValidationErrors | null => {
            const strutturaEsteraCtrl = group?.get('struttura');
            const strutturaEstera = strutturaEsteraCtrl?.value?.descrizione;
            const isEmpty = !strutturaEstera;
            const hasWhitespace = (strutturaEstera || '')?.trim()?.length === 0;
            const isValid = !isEmpty && !hasWhitespace;
            const errors = {'strutturaEsteraOspitanteCustomWhitespace': !isEmpty && hasWhitespace}
            if(!isValid) {
                strutturaEsteraCtrl?.setErrors(errors);
            } else {
                strutturaEsteraCtrl.setErrors(null)
            }
            return isValid ? null : errors;
        };
    }

    static CotutelaValidator() {
        return (group: FormGroup): ValidationErrors | null => {
            const sedeCotutelaCtrl = group?.get('sedeCotutela');
            const cotutelaFlag = group?.get('cotutelaFlag')?.value;
            const sedeCotutela = sedeCotutelaCtrl?.value;
            const isEmpty = (!!cotutelaFlag && !sedeCotutela);
            const hasWhitespace = (!!cotutelaFlag && (sedeCotutela || '')?.trim()?.length === 0);
            const isValid = !isEmpty && !hasWhitespace;
            const errors = {'sedeCotutelaRequired': isEmpty, 'sedeCotutelaWhitespace': !isEmpty && hasWhitespace}
            if(!isValid) {
                sedeCotutelaCtrl?.setErrors(errors);
            } else {
                sedeCotutelaCtrl.setErrors(null)
            }
            return isValid ? null : errors;
        };
    }

    static DoctorEuropeausValidator() {
        return (group: FormGroup): ValidationErrors | null => {
            const sedeDoctorEuropeausCtrl = group?.get('sedeDoctorEuropeaus');
            const doctorEuropeausFlag = group?.get('doctorEuropeausFlag')?.value;
            const sedeDoctorEuropeaus = sedeDoctorEuropeausCtrl?.value;
            const isEmpty = (!!doctorEuropeausFlag && !sedeDoctorEuropeaus);
            const hasWhitespace = (!!doctorEuropeausFlag && (sedeDoctorEuropeaus || '')?.trim()?.length === 0);
            const isValid = !isEmpty && !hasWhitespace;
            const errors = {'sedeDoctorEuropeausRequired': isEmpty, 'sedeDoctorEuropeausWhitespace': !isEmpty && hasWhitespace}
            if(!isValid){
                sedeDoctorEuropeausCtrl?.setErrors(errors);
            } else {
                sedeDoctorEuropeausCtrl.setErrors(null)
            }
            return isValid ? null : errors;

        };
    }

    static struttureEstereSelectAltro = 'other';

    static DateNotLessThanTodayValidator(today: moment.Moment, inputDateFormat: string = 'DD/MM/YYYY') {
        return (control: FormControl): ValidationErrors | null => {
            if (control?.value && moment(control?.value, inputDateFormat).isBefore(today, 'day')) {
                return {"LessThanToday": true};
            }
            return null;
        }
    }

    static EditOffertaConfigurationValidator(today: moment.Moment,
                                             numeroModificheMaxInitialValue: number,
                                             dateInitialValue: string,
                                             inputDateFormat: string = 'DD/MM/YYYY') {
        return (group: FormGroup): ValidationErrors | null => {
            console.log(group.get('numero_modifiche_max')?.value, numeroModificheMaxInitialValue)

            // check date only if numero modifiche max hasn't change
            if (group.get('numero_modifiche_max')?.value == numeroModificheMaxInitialValue
                && group?.get('data_limite_prima_presentazione_piano')?.value
                // check date only if date max has changed
                && moment(group.get('data_limite_prima_presentazione_piano')?.value).format(inputDateFormat) !== dateInitialValue
                && moment(group?.get('data_limite_prima_presentazione_piano')?.value, inputDateFormat).isBefore(today, 'day')) {
                group?.get('data_limite_prima_presentazione_piano')?.setErrors({"LessThanToday": true});
                return {"LessThanToday": true};
            } else {
                group?.get('data_limite_prima_presentazione_piano')?.setErrors(null);
            }
            return null;
        }
    }

    static EmailChangedValidator(initialEmail: string, errorMessage: string): ValidatorFn {
        return (group: FormGroup) : ValidationErrors | null => {
            const emailCtrl = group.get('email');
            console.log(emailCtrl)
            if(emailCtrl.value !== initialEmail){
                return null;
            } else {
                console.log('emailNotChanged')
                return {"emailNotChanged": errorMessage}
            }
        }
    }

    static VincoliCFUValidator(cfuRelazionePrimoAnno: number, cfuRelazioneSecondoAnno: number, cfuRelazioneTerzoAnno: number,): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const values = formGroup?.getRawValue();
            //the sum of the upper limit of ranges must be at least 60
            const invalidSumPrimoAnno = (
                cfuRelazionePrimoAnno
                + values.cfu_primo_anno_attivita_didattiche?.max
                + values.cfu_primo_anno_altre_attivita?.max
                + values.cfu_primo_anno_attivita_dottorando?.max) < 60;
            const invalidSumSecondoAnno = (
                cfuRelazioneSecondoAnno
                + values.cfu_secondo_anno_attivita_didattiche?.max
                + values.cfu_secondo_anno_attivita_dottorando?.max
                + values.cfu_secondo_anno_altre_attivita?.max) < 60;
            const invalidSumTerzoAnno = (
                cfuRelazioneTerzoAnno
                + values.cfu_terzo_anno_attivita_didattiche?.max
                + values.cfu_terzo_anno_attivita_dottorando?.max
                + values.cfu_terzo_anno_altre_attivita?.max) < 60;
            if(invalidSumPrimoAnno || invalidSumSecondoAnno || invalidSumTerzoAnno){
                return {
                    invalidSumPrimoAnno: invalidSumPrimoAnno,
                    invalidSumSecondoAnno: invalidSumSecondoAnno,
                    invalidSumTerzoAnno: invalidSumTerzoAnno
                };
            } else {
                return null;
            }
        };

    }

    static VincoliCFUIfOneFilledValidator(cfuRelazionePrimoAnno: number, cfuRelazioneSecondoAnno: number, cfuRelazioneTerzoAnno: number,): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const values = formGroup?.getRawValue();
            // if at least one value then validate
            const invalidSumPrimoAnno = (values.cfu_primo_anno_attivita_didattiche?.max || values.cfu_primo_anno_altre_attivita?.max || values.cfu_primo_anno_attivita_dottorando?.max)
                && (( cfuRelazionePrimoAnno + (values.cfu_primo_anno_attivita_didattiche?.max || 0) + (values.cfu_primo_anno_altre_attivita?.max || 0)  + (values.cfu_primo_anno_attivita_dottorando?.max || 0) ) < 60);
            const invalidSumSecondoAnno = (values.cfu_secondo_anno_attivita_didattiche?.max || values.cfu_secondo_anno_altre_attivita?.max || values.cfu_secondo_anno_attivita_dottorando?.max)
                && ((cfuRelazioneSecondoAnno + (values.cfu_secondo_anno_attivita_didattiche?.max || 0) + (values.cfu_secondo_anno_altre_attivita?.max || 0)  + (values.cfu_secondo_anno_attivita_dottorando?.max || 0) ) < 60);
            const invalidSumTerzoAnno = (values.cfu_terzo_anno_attivita_didattiche?.max || values.cfu_terzo_anno_altre_attivita?.max || values.cfu_terzo_anno_attivita_dottorando?.max)
                && ((cfuRelazioneTerzoAnno + (values.cfu_terzo_anno_attivita_didattiche?.max || 0) + (values.cfu_terzo_anno_altre_attivita?.max || 0)  + (values.cfu_terzo_anno_attivita_dottorando?.max || 0) ) < 60);
            if (invalidSumPrimoAnno || invalidSumSecondoAnno || invalidSumTerzoAnno) {
                return {
                    invalidSumPrimoAnno: invalidSumPrimoAnno,
                    invalidSumSecondoAnno: invalidSumSecondoAnno,
                    invalidSumTerzoAnno: invalidSumTerzoAnno
                };
            } else {
                return null;
            }
        };
    }

    static mustHaveFileDelibera(delibere?: DeliberaInfoViewImpl[]): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const deliberaSelectedId = formGroup?.getRawValue();
            const hasDelibera = !!delibere?.find(d => d.id === deliberaSelectedId);
            const hasFileDelibera = !!delibere?.find(d => d.id === deliberaSelectedId)?.url;
            if(!(hasDelibera && !hasFileDelibera)){
                return null;
            } else {
                return { notHasFileDelibera: hasDelibera && !hasFileDelibera }
            }
        };
    }

    static RipartizioneImportoSpesaValidator(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const sumError = (
                    formGroup.get('importoSpesaPrimoAnno')?.value +
                    formGroup.get('importoSpesaSecondoAnno')?.value +
                    formGroup.get('importoSpesaTerzoAnno')?.value +
                    formGroup.get('importoSpesaAltriFondi')?.value
                )?.toFixed(2) !== formGroup.get('importoSpesa')?.value?.toFixed(2);
            //

            if(!sumError){
                return null;
            } else {
                return { sumError }
            }
        };
    }

    static MissioniMezzoProprioValidator(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            return null
        };
    }


    static atLeastOneConfigurationValidator(configurations: string[]): ValidatorFn {
        return (group: FormGroup): {[key: string]: any} | null => {
            const atleastOneFilled = configurations.some(config => !!group.get(config)?.value?.length);
            return atleastOneFilled ? null : { 'noConfiguration': true };
        };
    }

    static RequiredIfOtherFilled(otherCtrlName: string): ValidatorFn {
        return (formCtrl: FormControl): ValidationErrors | null => {
            if (!isNil(formCtrl.parent?.get(otherCtrlName).value) && isNil(formCtrl.value)){
                return { 'incompleteRange': true};
            } else {
                return null;
            }
        };
    }

    static RequiredIfOtherParent(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            group.get('min').updateValueAndValidity({onlySelf: true});
            group.get('max').updateValueAndValidity({onlySelf: true});
            return null;
        };
    }

    static DocumentValidator(numeroMinimoDocumenti?: number, numeroMassimoDocumenti?: number, caricabileARatifica?: boolean): ValidatorFn {
        return (ctrl: FormControl): ValidationErrors | null => {
            // anyway to be constistent ciclo config must provide numeroMinimoDocumenti = 0 for documents caricabili a ratifica
            if(!caricabileARatifica && numeroMinimoDocumenti && ctrl?.value?.length < numeroMinimoDocumenti){
                return {
                    'numeroMinimoDocumenti': true
                }
            }
            if(numeroMassimoDocumenti && ctrl?.value?.length > numeroMassimoDocumenti){
                return {
                    'numeroMassimoDocumenti': true
                }
            }
            return null;
        };
    }


    static MobilityRequestStartDateValidator(currentTime: moment.Moment,
                                             maxBefore: number,
                                             minBefore: number,
                                             minAfter: number,
                                             maxAfter: number) {
        return (ctrl: FormControl): ValidationErrors | null => {
            if(!ctrl.value){
                return null
            }
            if((maxBefore && moment(ctrl.value).isBefore(moment(currentTime).subtract(maxBefore, 'days')))
             || (minBefore && moment(ctrl.value).isAfter(moment(currentTime).subtract(minBefore, 'days')))
             || (minAfter && moment(ctrl.value).isBefore(moment(currentTime).add(minAfter, 'days')))
             || (maxAfter && moment(ctrl.value).isAfter(moment(currentTime).add(maxAfter, 'days')))){
                return {
                    'mobilityRequestStartDateNotValid': true
                }
            }
            return null;
        };
    }

    static PasswordValidator() {
        return (ctrl: FormControl): ValidationErrors | null => {
            const errors: ValidationErrors = {};
            const passwordCompliance: ValidationErrors = {};
            const typedPassword: string = ctrl.value;

            if (!typedPassword || !/(?=.*[0-9]+)/.test(typedPassword)) {
                passwordCompliance['missingNumber'] = 'Password must contain at least one numeric character';
            }
            if (!typedPassword || !/(?=.*[a-z]+)/.test(typedPassword)) {
                passwordCompliance['missingLowercase'] = 'Password must contain at least one lowercase letter';
            }
            if (!typedPassword || !/(?=.*[A-Z]+)/.test(typedPassword)) {
                passwordCompliance['missingUppercase'] = 'Password must contain at least one uppercase letter';
            }
            if (!typedPassword || !/(?=.*[!@#$%.,^&*]+)/.test(typedPassword)) {
                passwordCompliance['missingSpecial'] = 'Password must contain at least one special character (!@#$%.,^&*)';
            }
            if (!typedPassword || typedPassword?.length < 8) {
                passwordCompliance['tooShort'] = 'Password must be at least 8 characters long';
            }

            // Add `passwordCompliance` object only if there are compliance issues
            if (Object.keys(passwordCompliance).length > 0) {
                errors['passwordCompliance'] = passwordCompliance;
            }

            // Return the final error object or null if valid
            return Object.keys(errors).length > 0 ? errors : null;
        };
    }



}
