import {Pipe, PipeTransform} from '@angular/core';
import {
    AggiornamentoBudgetStudenteStatus,
    AggiornamentoPianoDiStudiStatus,
    AttivitaExtraPianoDiStudiStatus,
    AttivitaTrasversaleStatus,
    JobStatus, MissioneStatus, OffertaFormativaStatus,
    OnboardingEsternoStatus,
    PeriodoDiMobilitaStudenteStatus,
    PeriodoRegistroAttivitaStatus,
    PianoDiStudiStatus,
    QuestionarioSottomessoStatus, RichiestaDiRetribuzioneMobilitaStatus,
    SpesaStudenteStatus, StudenteCicloMotivoStatus, StudenteCicloStatus, SuperamentoAnnoStudenteStatus,
    TicketPriority,
    TicketStatus,
    TipoModificaCorsoPianoDiStudi, TipoOperazioneStoricoMobilitaStudente
} from '../../../api-clients/generated/services';
import {
    getColorByAggiornamentoBudgetStudenteStatus,
    getColorByAggiornamentoPianoDiStudiStatus,
    getColorByAttivitaExtraPianoDiStudiStatus,
    getColorByAttivitaTrasversale,
    getColorByAutorizzazioneStatus,
    getColorByBooleanNotNullStatus,
    getColorByBooleanStatus,
    getColorByEsternoStatus,
    getColorByHttpStatus,
    getColorByJobStatus,
    getColorByMissioneStatus,
    getColorByMobilitaStatus,
    getColorByOffertaFormativaStatus,
    getColorByPeriodoRegistroStatus,
    getColorByPianoDiStudiStatus,
    getColorByQuestionarioSottomessoStatus,
    getColorByRetribuzioneMobilitaStatus,
    getColorBySpesaStudenteStatus,
    getColorByStoricoMobilitaStatus,
    getColorByStudenteStatus, getColorBySuperamentoAnnoStatus,
    getColorByTicketPriority,
    getColorByTicketStatus,
    getColorByTicketTipologia,
    getColorByTipoModificaCorsoPianoDiStudi,
    getColorYesOrNo
} from '../utils/utils';
import {TicketTipologia} from "../../modules/landing/support/support.component";
import {YesOrNotEnum} from "../costants/app-constants";
import {
    PianoDiStudiStatusExtended
} from "../../modules/landing/cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {BooleanStatus, BooleanStatusNotNull} from "../components/table/model/generic-table-model";
import {
    StatoAutorizzazione
} from "../../modules/landing/requests/approvazione-periodi-di-mobilita-ricerca/tabs-periodi-mobilita-ricerca/tabs-periodi-mobilita-ricerca.component";
import {
    OnboardingEsternoStatusForAmm,
    OnboardingEsternoStatusForKeycloakMode
} from "../../modules/landing/users-list/esterni-list/esterni-list-utils";

@Pipe({
  name: 'statusEnumToColorTheme'
})
export class StatusEnumToColorThemePipe implements PipeTransform {

  transform(value: AggiornamentoPianoDiStudiStatus | PianoDiStudiStatus | PianoDiStudiStatusExtended | TipoModificaCorsoPianoDiStudi
            | AttivitaExtraPianoDiStudiStatus | TicketStatus | TicketPriority | TicketTipologia
            | QuestionarioSottomessoStatus | SpesaStudenteStatus | JobStatus | AttivitaTrasversaleStatus
            | PeriodoDiMobilitaStudenteStatus | OnboardingEsternoStatus | OffertaFormativaStatus
            | RichiestaDiRetribuzioneMobilitaStatus | AggiornamentoBudgetStudenteStatus | TipoOperazioneStoricoMobilitaStudente
            | StudenteCicloStatus | StudenteCicloMotivoStatus | MissioneStatus | SuperamentoAnnoStudenteStatus
            | OnboardingEsternoStatusForAmm | OnboardingEsternoStatusForKeycloakMode | BooleanStatusNotNull | string, valueEnum: any): string {
      switch (valueEnum){
          case AggiornamentoPianoDiStudiStatus:
              return getColorByAggiornamentoPianoDiStudiStatus(value as AggiornamentoPianoDiStudiStatus);
          case PianoDiStudiStatus:
          case PianoDiStudiStatusExtended:
              return getColorByPianoDiStudiStatus(value as PianoDiStudiStatusExtended);
          case TipoModificaCorsoPianoDiStudi:
              return getColorByTipoModificaCorsoPianoDiStudi(value as TipoModificaCorsoPianoDiStudi);
          case AttivitaExtraPianoDiStudiStatus:
              return getColorByAttivitaExtraPianoDiStudiStatus(value as AttivitaExtraPianoDiStudiStatus);
          case TicketStatus:
              return getColorByTicketStatus(value as TicketStatus);
          case TicketPriority:
              return getColorByTicketPriority(value as TicketPriority);
          case TicketTipologia:
              return getColorByTicketTipologia(value as TicketTipologia);
          case QuestionarioSottomessoStatus:
              return getColorByQuestionarioSottomessoStatus(value as QuestionarioSottomessoStatus);
          case SpesaStudenteStatus:
              return getColorBySpesaStudenteStatus(value as SpesaStudenteStatus);
          case JobStatus:
              return getColorByJobStatus(value as JobStatus);
          case AttivitaTrasversaleStatus:
              return getColorByAttivitaTrasversale(value as AttivitaTrasversaleStatus);
          case PeriodoDiMobilitaStudenteStatus:
              return getColorByMobilitaStatus(value as PeriodoDiMobilitaStudenteStatus);
          case OnboardingEsternoStatus:
          case OnboardingEsternoStatusForAmm:
          case OnboardingEsternoStatusForKeycloakMode:
              return getColorByEsternoStatus(value as OnboardingEsternoStatus);
          case PeriodoRegistroAttivitaStatus:
              return getColorByPeriodoRegistroStatus(value as PeriodoRegistroAttivitaStatus);
          case OffertaFormativaStatus:
              return getColorByOffertaFormativaStatus(value as OffertaFormativaStatus);
          case AggiornamentoBudgetStudenteStatus:
              return getColorByAggiornamentoBudgetStudenteStatus(value as AggiornamentoBudgetStudenteStatus);
          case RichiestaDiRetribuzioneMobilitaStatus:
              return getColorByRetribuzioneMobilitaStatus(value as RichiestaDiRetribuzioneMobilitaStatus);
          case TipoOperazioneStoricoMobilitaStudente:
              return getColorByStoricoMobilitaStatus(value as TipoOperazioneStoricoMobilitaStudente);
          case StudenteCicloStatus:
              return getColorByStudenteStatus(value as StudenteCicloStatus);
          case StatoAutorizzazione:
              return getColorByAutorizzazioneStatus(value as StatoAutorizzazione);
          case MissioneStatus:
              return getColorByMissioneStatus(value as MissioneStatus);
          case SuperamentoAnnoStudenteStatus:
              return getColorBySuperamentoAnnoStatus(value as SuperamentoAnnoStudenteStatus);
          case YesOrNotEnum:
              return getColorYesOrNo(value as YesOrNotEnum);
          case BooleanStatus:
              return getColorByBooleanStatus(value as unknown as BooleanStatus);
          case BooleanStatusNotNull:
              return getColorByBooleanNotNullStatus(value as unknown as BooleanStatusNotNull);
          case undefined:
              return getColorByHttpStatus(value as string);
      }
  }

}
