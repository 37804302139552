export enum TypeDialogFormEnum {
    TEXT = 'TEXT',
    SELECT = 'SELECT',
    NUMBER = 'NUMBER',
    TEXTAREA = 'TEXTAREA',
    DATA = 'DATA',
    AMOUNT = 'AMOUNT',
    FILE= 'FILE',
    BOOLEAN = 'BOOLEAN',
    DATAORA = "DATAORA",
    MULTIFILE = "MULTIFILE",
    TWO_OPTIONS_RADIO = "TWO_OPTIONS_RADIO",
    DOCUMENT = "DOCUMENT",
    RECAPTCHA = "RECAPTCHA",
}
