import {FormArray} from '@angular/forms';
import {get, isArray, isObject, isString} from 'lodash';
import {TranslocoService} from '@ngneat/transloco';
import {
    HttpEventType,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import {
    AggiornamentoBudgetStudenteStatus,
    AggiornamentoPianoDiStudiStatus,
    AnnoRiferimentoValues,
    AttivitaExtraPianoDiStudiStatus,
    AttivitaTrasversaleStatus,
    AuthorityType,
    ElementoOffertaFormativaType,
    JobStatus,
    MissioneStatus,
    OffertaFormativaInfoViewImpl,
    OffertaFormativaStatus,
    OnboardingEsternoStatus,
    PeriodoDiMobilitaStudenteStatus,
    PeriodoRegistroAttivitaStatus,
    QuestionarioSottomessoStatus,
    RichiestaDiRetribuzioneMobilitaStatus,
    SpesaStudenteStatus,
    StudenteCicloInfoView,
    StudenteCicloMotivoStatus,
    StudenteCicloStatus,
    StudenteCicloStatusPerStatsConIncoming, SuperamentoAnnoStudenteStatus,
    TicketPriority,
    TicketStatus,
    TipoModificaCorsoPianoDiStudi, TipoOperazioneSbloccoPiano,
    TipoOperazioneStoricoMobilitaStudente,
    TipoPeriodoEnum
} from "../../../api-clients/generated/services";
import moment from "moment";
import {italianDateWithFormat} from "./utils-date";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";
import {TicketTipologia} from "../../modules/landing/support/support.component";
import {CicloCorsoRuoloInterface} from "../interface/CicloCorsoRuoloInterface";
import * as fs from "file-saver";
import {LocalStorageService} from "../service/local-storage.service";
import {YesOrNotEnum} from "../costants/app-constants";
import {
    PianoDiStudiStatusExtended
} from "../../modules/landing/cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {BooleanStatus, BooleanStatusNotNull} from "../components/table/model/generic-table-model";
import {
    StatoAutorizzazione
} from "../../modules/landing/requests/approvazione-periodi-di-mobilita-ricerca/tabs-periodi-mobilita-ricerca/tabs-periodi-mobilita-ricerca.component";
import {
    OnboardingEsternoStatusForAmm,
    OnboardingEsternoStatusForKeycloakMode
} from "../../modules/landing/users-list/esterni-list/esterni-list-utils";

export function keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
    } else {
        return true;
    }
}

export function clearFormArray(formArray: FormArray, emitEvent = false): void {
    while (formArray.length !== 0) {
        formArray.removeAt(0, {emitEvent: emitEvent});
    }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function trimObjValues(obj): any {
    Object.keys(obj).forEach((key) => {
        if (isString(obj[key]) && !!obj[key]) {
            obj[key] = obj[key]?.trim();
        } else if (isObject(obj[key])) {
            trimObjValues(obj[key]);
        } else if (isArray(obj[key]) && obj[key].length > 0) {
            obj[key].forEach(elem => trimObjValues(elem));
        }
    });
}


export function removeUnderscore(value: string): string {
    return isString(value) ? value?.replace(/_/g, ' ')?.trim() : value;
}

export const getTransalate = (_translocoService: TranslocoService, translate: string): string => {
    const activeLang = _translocoService.getActiveLang();
    const translation = _translocoService.getTranslation().get(activeLang);
    return get(translation, translate, translate);
}
export const progressBar = (value: HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>, uploadProgress$): void => {
    if (value.type === HttpEventType.UploadProgress) {
        uploadProgress$.next(Math.round(100 * (value.loaded / value.total)) - 5);
    }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function readUserIdByParseJwtToken(token: string | null | undefined) {
    if (!!token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload).sub;
    }
}

export function annoRiferimentoToRomanNumeral(year: AnnoRiferimentoValues): string {
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            return 'I';
        case AnnoRiferimentoValues.SECONDO:
            return 'II';
        case AnnoRiferimentoValues.TERZO:
            return 'III';
        default:
            return '';
    }
}

export function annoRiferimentoFromRomanNumeral(year: string): AnnoRiferimentoValues {
    switch (year) {
        case 'I':
            return AnnoRiferimentoValues.PRIMO;
        case 'II':
            return AnnoRiferimentoValues.SECONDO;
        case 'III':
            return AnnoRiferimentoValues.TERZO;
        default:
            return AnnoRiferimentoValues.PRIMO;
    }
}


export function getLanguagePathByAggiornamentoPianoDiStudiStatus(status: AggiornamentoPianoDiStudiStatus, sottoruolo?: AuthorityType): string {
    switch (status) {
        case AggiornamentoPianoDiStudiStatus.BOZZA:
            return 'study_plan_status.draft';
        case AggiornamentoPianoDiStudiStatus.RIFIUTATO:
            return 'study_plan_status.refused';
        case AggiornamentoPianoDiStudiStatus.APPROVATO:
            return 'study_plan_status.approved';
        case AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE:
            switch (sottoruolo) {
                case AuthorityType.SUPERVISORE:
                    return 'study_plan_status.approved_by_supervisor';
                case AuthorityType.COSUPERVISORE:
                    return 'study_plan_status.approved_by_co_supervisor';
                default:
                    return 'study_plan_status.approved_by';
            }
        case AggiornamentoPianoDiStudiStatus.DAAPPROVARE:
            return 'study_plan_status.to_be_approved';
        default:
            return '';
    }
}

export function getLanguagePathByPianoDiStudiStatus(status: PianoDiStudiStatusExtended): string {
    switch (status) {
        case PianoDiStudiStatusExtended.RIFIUTATO:
            return 'study_plan_status.refused';
        case PianoDiStudiStatusExtended.APPROVATOVALIDO:
            return 'study_plan_status.approved';
        case PianoDiStudiStatusExtended.APPROVATO:
            return 'study_plan_status.approved';
        case PianoDiStudiStatusExtended.NONPRESENTATO:
            return 'study_plan_status.not_presented';
        case PianoDiStudiStatusExtended.ATTESAAPPROVAZIONE:
            return 'study_plan_status.waiting_for_approval';
        case PianoDiStudiStatusExtended.APPROVATONONVALIDO:
            return 'study_plan_status.approved';
        default:
            return '';
    }
}

export function getLanguagePathByTipoModificaCorsoPianoDiStudi(status: TipoModificaCorsoPianoDiStudi): string {
    switch (status) {
        case TipoModificaCorsoPianoDiStudi.NESSUNAMODIFICA:
            return 'study_plan_status.no_changes';
        case TipoModificaCorsoPianoDiStudi.RIMOZIONE:
            return 'study_plan_status.removal';
        case TipoModificaCorsoPianoDiStudi.AGGIUNTA:
            return 'study_plan_status.add';
        case TipoModificaCorsoPianoDiStudi.MODIFICATA:
            return 'study_plan_status.changed';
        default:
            return '';
    }
}

export function getLanguagePathByAttivitaExtraPianoDiStudiStatus(status: AttivitaExtraPianoDiStudiStatus): string {
    switch (status) {
        case AttivitaExtraPianoDiStudiStatus.INSERITA:
            return 'study_plan_status.insert';
        case AttivitaExtraPianoDiStudiStatus.APPROVATA:
            return 'study_plan_status.approved_1';
        case AttivitaExtraPianoDiStudiStatus.RIFIUTATA:
            return 'study_plan_status.refused_1';
        case AttivitaExtraPianoDiStudiStatus.DAAPPROVARE:
            return 'study_plan_status.activity_to_be_approved';
        case AttivitaExtraPianoDiStudiStatus.ATTESTATOPRESENTATO:
            return 'study_plan_status.presented_certificate';
        default:
            return '';
    }
}

export function getLanguagePathByAttivitaTrasversale(status: AttivitaTrasversaleStatus): string {
    switch (status) {
        case AttivitaTrasversaleStatus.ATTESADELIBERACOORDINATORE:
            return 'study_plan_status.waiting_for_approval_coordinator';
        case AttivitaTrasversaleStatus.ATTESADOCUMENTODOTTORANDO:
            return 'study_plan_status.waiting_for_student_document';
        case AttivitaTrasversaleStatus.DOCUMENTODOTTORANDOCARICATO:
            return 'study_plan_status.student_document_uploaded';
        case AttivitaTrasversaleStatus.DOCUMENTODOTTORANDORIFIUTATO:
            return 'study_plan_status.student_document_refused';
        case AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE:
            return 'study_plan_status.waiting_for_supervisor_document';
        case AttivitaTrasversaleStatus.ATTESASUPERAMENTO:
            return 'study_plan_status.waiting_for_supervisor_document';
        case AttivitaTrasversaleStatus.RICHIESTASUPERAMENTOCONDOCUMENTOSUPERVISORE:
            return 'study_plan_status.approval_request_supervisor_document';
        case AttivitaTrasversaleStatus.RICHIESTASUPERAMENTO:
            return 'study_plan_status.approval_request_supervisor_document';
        case AttivitaTrasversaleStatus.COMPLETATA:
            return 'study_plan_status.approved_1';
        default:
            return '';
    }
}

export function getLanguagePathByJobStatus(status: JobStatus): string {
    switch (status) {
        case JobStatus.DAAVVIARE:
            return 'sync_status.to_start';
        case JobStatus.INCORSO:
            return 'sync_status.in_progress';
        case JobStatus.FALLITO:
            return 'sync_status.failed';
        case JobStatus.TERMINATOCONSUCCESSO:
            return 'sync_status.successfully_completed';
        default:
            return '';
    }
}

export function getLanguagePathByTicketStatus(status: TicketStatus): string {
    switch (status) {
        case TicketStatus.Open:
            return 'support.status_open';
        case TicketStatus.Closed:
            return 'support.status_closed';
        case TicketStatus.Pending:
            return 'support.status_pending';
        case TicketStatus.Answered:
            return 'support.status_answered';
        case TicketStatus.Resolved:
            return 'support.status_resolved';
        case TicketStatus.Spam:
            return 'support.status_spam';
        default:
            return '';
    }
}

export function getLanguagePathByTicketPriority(status: TicketPriority): string {
    switch (status) {
        case TicketPriority.Low:
            return 'support.priority_low';
        case TicketPriority.Medium:
            return 'support.priority_medium';
        case TicketPriority.High:
            return 'support.priority_high';
        case TicketPriority.Urgent:
            return 'support.priority_urgent';
        default:
            return '';
    }
}

export function getLanguagePathByTicketTipologia(status: TicketTipologia): string {
    switch (status) {
        case TicketTipologia.STANDARD:
            return 'support.typology_standard';
        case TicketTipologia.CRITICA:
            return 'support.typology_critic';
        case TicketTipologia.URGENTE:
            return 'support.typology_urgent';
        default:
            return '';
    }
}

export function getLanguagePathByQuestionarioSottomessoStatus(status: QuestionarioSottomessoStatus): string {
    switch (status) {
        case QuestionarioSottomessoStatus.COMPLETATO:
            return 'anvur_surveys.completed_state';
        case QuestionarioSottomessoStatus.DACOMPLETARE:
            return 'anvur_surveys.to_compile_state';
        case QuestionarioSottomessoStatus.BOZZA:
            return 'anvur_surveys.draft_state';
        default:
            return '';
    }
}

export function getLanguagePathByAutorizzazioneStatus(status: StatoAutorizzazione): string {
    switch (status) {
        case StatoAutorizzazione.NON_AUTORIZZATO:
            return 'common.non_authorized';
        case StatoAutorizzazione.DELIBERA_DI_AUTORIZZAZIONE_INSERITA:
            return 'common.authorization_resolution_inserted';
        case StatoAutorizzazione.AUTORIZZATO:
            return 'common.authorized';
        default:
            return '';
    }
}

export function getLanguagePathBySuperamentoAnnoStatus(status: SuperamentoAnnoStudenteStatus): string {
    switch (status) {
        case SuperamentoAnnoStudenteStatus.NONAVVIATO:
            return 'year_passing.status.not_started';
        case SuperamentoAnnoStudenteStatus.INATTESADOCUMENTIDOTTORANDO:
            return 'year_passing.status.waiting_student_document';
        case SuperamentoAnnoStudenteStatus.INATTESACORREZIONECFUOREDOTTORANDO:
            return 'year_passing.status.waiting_student_cfu';
        case SuperamentoAnnoStudenteStatus.INATTESAVERIFICACFUORESUPERVISORE:
            return 'year_passing.status.waiting_verify_cfu_sup';
        case SuperamentoAnnoStudenteStatus.INATTESAVERIFICACFUORECOORDINATORE:
            return 'year_passing.status.waiting_verify_cfu_coord';
        case SuperamentoAnnoStudenteStatus.INATTESAATTESTAZIONESUPERAMENTO:
            return 'year_passing.status.waiting_delibera';
        case SuperamentoAnnoStudenteStatus.DOCUMENTIDOTTORANDOSOTTOMESSI:
            return 'year_passing.status.student_document_added';
        case SuperamentoAnnoStudenteStatus.DOCUMENTISUPERVISORESOTTOMESSI:
            return 'year_passing.status.sup_document_added';
        case SuperamentoAnnoStudenteStatus.DOCUMENTIDOTTORANDORIFIUTATI:
            return 'year_passing.status.student_document_refused';
        case SuperamentoAnnoStudenteStatus.DOCUMENTISUPERVISORERIFIUTATI:
            return 'year_passing.status.sup_document_refused';
        case SuperamentoAnnoStudenteStatus.COMPLETATO:
            return 'year_passing.status.completed';
        default:
            return '';
    }
}

export function getLanguagePathBySpesaStudenteStatus(status: SpesaStudenteStatus, sottoruolo?: AuthorityType): string {
    switch (status) {
        case SpesaStudenteStatus.RIFIUTATO:
            return 'budget.refused';
        case SpesaStudenteStatus.APPROVATOPTA:
            return 'budget.approved';
        case SpesaStudenteStatus.APPROVATO:
            return 'budget.approved_coordinator';
        case SpesaStudenteStatus.APPROVATOPARZIALE:
            switch (sottoruolo) {
                case AuthorityType.SUPERVISORE:
                    return 'study_plan_status.approved_by_supervisor';
                case AuthorityType.COSUPERVISORE:
                    return 'study_plan_status.approved_by_co_supervisor';
                default:
                    return 'study_plan_status.approved_by';
            }
        case SpesaStudenteStatus.DAAPPROVARE:
            return 'study_plan_status.to_be_approved';
        default:
            return '';
    }
}

export function getLanguagePathByMobilitaStatus(status: PeriodoDiMobilitaStudenteStatus, sottoruolo?: AuthorityType): string {
    switch (status) {
        case PeriodoDiMobilitaStudenteStatus.RIFIUTATO:
            return 'mobility.status.refused';
        case PeriodoDiMobilitaStudenteStatus.APPROVATO:
            return 'mobility.status.approved';
        case PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE:
            switch (sottoruolo) {
                case AuthorityType.SUPERVISORE:
                    return 'study_plan_status.approved_by_supervisor';
                case AuthorityType.COSUPERVISORE:
                    return 'study_plan_status.approved_by_co_supervisor';
                default:
                    return 'study_plan_status.approved_by';
            }
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONEAPPROVATAPARZIALMENTE:
            switch (sottoruolo) {
                case AuthorityType.SUPERVISORE:
                    return 'mobility.status.conclusion_request_approved_by_supervisor';
                case AuthorityType.COSUPERVISORE:
                    return 'mobility.status.conclusion_request_approved_by_co_supervisor';
                default:
                    return 'mobility.status.conclusion_request_approved_by';
            }
        case PeriodoDiMobilitaStudenteStatus.INSERITO:
            return 'mobility.status.inserted';
        case PeriodoDiMobilitaStudenteStatus.SOSPESO:
            return 'mobility.status.suspended';
        case PeriodoDiMobilitaStudenteStatus.ANNULLATO:
            return 'mobility.status.canceled';
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONE:
            return 'mobility.status.conclusion_request';
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONERIFIUTATA:
            return 'mobility.status.conclusion_request_refused';
        case PeriodoDiMobilitaStudenteStatus.CONCLUSO:
            return 'mobility.status.conclusion';
        default:
            return '';
    }
}

export function getLanguagePathByPeriodiRegistroStatus(status: PeriodoRegistroAttivitaStatus, sottoruolo?: AuthorityType): string {
    switch (status) {
        case PeriodoRegistroAttivitaStatus.BOZZA:
            return 'activity_log.draft';
        case PeriodoRegistroAttivitaStatus.RIFIUTATO:
            return 'activity_log.status.refused';
        case PeriodoRegistroAttivitaStatus.APPROVATO:
            return 'activity_log.status.approved';
        case PeriodoRegistroAttivitaStatus.APPROVATOPARZIALE:
            switch (sottoruolo) {
                case AuthorityType.SUPERVISORE:
                    return 'study_plan_status.approved_by_supervisor';
                case AuthorityType.COSUPERVISORE:
                    return 'study_plan_status.approved_by_co_supervisor';
                default:
                    return 'study_plan_status.approved_by';
            }
        case PeriodoRegistroAttivitaStatus.INSERITO:
            return 'activity_log.status.inserted';
        default:
            return '';
    }
}

export function getLanguagePathByOffertaFormativaStatus(status: OffertaFormativaStatus): string {
    switch (status) {
        case OffertaFormativaStatus.NONPUBBLICA:
            return 'training_offer.status.not_published';
        case OffertaFormativaStatus.PUBBLICA:
            return 'training_offer.status.published';
        default:
            return '';
    }
}


export function getLanguagePathByProfile(profile: AuthorityType): string {
    switch (profile) {
        case AuthorityType.SUPERVISORE:
            return 'menu_filter.supervisor';
        case AuthorityType.COSUPERVISORE:
            return 'menu_filter.co_supervisor';
        case AuthorityType.COORDINATORE:
            return 'cycle_doctorate.coordinator';
        default:
            return '';
    }
}


export function getLanguagePathByAggiornamentoBudgetStudenteStatus(status: AggiornamentoBudgetStudenteStatus): string {
    switch (status) {
        case AggiornamentoBudgetStudenteStatus.RIFIUTATO:
            return 'study_plan_status.refused';
        case AggiornamentoBudgetStudenteStatus.APPROVATOPTA:
            return 'study_plan_status.approved';
        case AggiornamentoBudgetStudenteStatus.DAAPPROVARE:
            return 'budget.to_be_approved_pta';
        case AggiornamentoBudgetStudenteStatus.IMPOSTATOSUPERADMIN:
            return 'budget.set_by_superadmin';
        default:
            return '';
    }
}
export function getLanguagePathByPeriodo(periodo: TipoPeriodoEnum): string {
    switch (periodo) {
        case TipoPeriodoEnum.SOGGIORNOESTERO:
            return 'mobility.mobility_period';
        case TipoPeriodoEnum.RICERCA:
            return 'mobility.research_period';
        default:
            return '';
    }
}

export function getLanguagePathByRetribuzioneMobilitaStatus(status: RichiestaDiRetribuzioneMobilitaStatus, sottoruolo?: AuthorityType): string {
    switch (status) {
        case RichiestaDiRetribuzioneMobilitaStatus.RIFIUTATA:
            return 'mobility.retribution_status_desc.refused';
        case RichiestaDiRetribuzioneMobilitaStatus.APPROVATA:
            return 'mobility.retribution_status_desc.approved';
        case RichiestaDiRetribuzioneMobilitaStatus.APPROVATAPARZIALE:
            console.log(sottoruolo)
            switch (sottoruolo) {
                case AuthorityType.SUPERVISORE:
                    return 'mobility.retribution_status_desc.approved_by_supervisor';
                case AuthorityType.COSUPERVISORE:
                    return 'mobility.retribution_status_desc.approved_by_co_supervisor';
                default:
                    return 'mobility.retribution_status_desc.approved_by';
            }
        case RichiestaDiRetribuzioneMobilitaStatus.VALIDATAPTA:
            return 'mobility.retribution_status_desc.validated_pta';
        case RichiestaDiRetribuzioneMobilitaStatus.DAAPPROVARE:
            return 'mobility.retribution_status_desc.to_be_approved';
        default:
            return '';
    }
}

export function getLanguagePathByTipoOperazioneMobilitaStatus(status: TipoOperazioneStoricoMobilitaStudente): string {
    switch (status) {
        case TipoOperazioneStoricoMobilitaStudente.CONCESSIONE:
            return 'mobility.autorization_given';
        case TipoOperazioneStoricoMobilitaStudente.REVOCA:
            return 'mobility.autorization_revoked';
        default:
            return '';
    }
}

export function getLanguagePathByStudenteCicloStatus(status: StudenteCicloStatus): string {
    switch (status) {
        case StudenteCicloStatus.INCORSO:
            return 'common.student_status.in_progress';
        case StudenteCicloStatus.SOSPESO:
            return 'common.student_status.suspended';
        case StudenteCicloStatus.CESSATO:
            return 'common.student_status.terminated';
        default:
            return '';
    }
}

export function getLanguagePathBooleanStatus(status: BooleanStatus): string {
    return status ? 'common.yes' : 'common.no'
}

export function getLanguagePathBooleanNotNullStatus(status: BooleanStatusNotNull): string {
    return status === BooleanStatusNotNull.BOOLEAN_TRUE ? 'common.yes' : 'common.no'
}

export function getLanguagePathTipoOperazioneSbloccoPiano(tipo: TipoOperazioneSbloccoPiano): string {
    switch (tipo) {
        case TipoOperazioneSbloccoPiano.REVOCASBLOCCOMODIFICA:
            return 'study_plan_status.limit_submission_lock';
        case TipoOperazioneSbloccoPiano.SBLOCCOMODIFICA:
            return 'study_plan_status.limit_submission_unlock';
        case TipoOperazioneSbloccoPiano.REVOCASBLOCCOPRIMAPRESENTAZIONE:
            return 'study_plan_status.limit_submission_lock';
        case TipoOperazioneSbloccoPiano.SBLOCCOPRIMAPRESENTAZIONE:
            return 'study_plan_status.limit_submission_unlock';
        case TipoOperazioneSbloccoPiano.REVOCASBLOCCOSOTTOMISSIONEPIANONONVALIDO:
            return 'study_plan_status.invalid_submission_lock';
        case TipoOperazioneSbloccoPiano.SBLOCCOSOTTOMISSIONEPIANONONVALIDO:
            return 'study_plan_status.invalid_submission_unlock';
        default:
            return '';
    }
}

export function getLanguagePathByStudenteCicloMotivoStatus(status: StudenteCicloMotivoStatus): string {
    switch (status) {
        case StudenteCicloMotivoStatus.ALT:
            return 'common.student_status.reasons.ALT';
        case StudenteCicloMotivoStatus.DCS:
            return 'common.student_status.reasons.DCS';
        case StudenteCicloMotivoStatus.DEC:
            return 'common.student_status.reasons.DEC';
        case StudenteCicloMotivoStatus.IMM:
            return 'common.student_status.reasons.IMM';
        case StudenteCicloMotivoStatus.IMMSI:
            return 'common.student_status.reasons.IMMSI';
        case StudenteCicloMotivoStatus.ERRIM:
            return 'common.student_status.reasons.ERRIM';
        case StudenteCicloMotivoStatus.MAL:
            return 'common.student_status.reasons.MAL';
        case StudenteCicloMotivoStatus.MAT:
            return 'common.student_status.reasons.MAT';
        case StudenteCicloMotivoStatus.RIN:
            return 'common.student_status.reasons.RIN';
        case StudenteCicloMotivoStatus.RINC:
            return 'common.student_status.reasons.RINC';
        case StudenteCicloMotivoStatus.RINI:
            return 'common.student_status.reasons.RINI';
        case StudenteCicloMotivoStatus.TIT:
            return 'common.student_status.reasons.TIT';
        case StudenteCicloMotivoStatus.STU:
            return 'common.student_status.reasons.STU';
        default:
            return '';
    }
}

export function getLanguagePathByMissioneStatus(status: MissioneStatus, sottoruolo?: AuthorityType): string {
    switch (status) {
        case MissioneStatus.RICHIESTAAPPROVAZIONE:
            return 'missions.status.requested';
        case MissioneStatus.APPROVATAPARZIALE:
            switch (sottoruolo) {
                case AuthorityType.SUPERVISORE:
                    return 'missions.status.approved_by_supervisor';
                case AuthorityType.COSUPERVISORE:
                    return 'missions.status.approved_by_cosupervisor';
                default:
                    return 'missions.status.approved_by';
            }
        case MissioneStatus.APPROVATA:
            return 'missions.status.approved_coordinator';
        case MissioneStatus.APPROVATADIRETTOREDIDIPARTIMENTO:
            return 'missions.status.approved_director';
        case MissioneStatus.RICHIESTALIQUIDAZIONE:
            return 'missions.status.payment_requested';
        case MissioneStatus.LIQUIDATA:
            return 'missions.status.payed';
        case MissioneStatus.RIFIUTATA:
            return 'missions.status.refused';
        case MissioneStatus.RICHIESTALIQUIDAZIONERIFIUTATA:
            return 'missions.status.payment_refused';
        default:
            return '';
    }
}

export function getYesOrNo(status: YesOrNotEnum): string {
    switch (status) {
        case YesOrNotEnum.YES:
            return 'common.yes';
        case YesOrNotEnum.NO:
            return 'common.no';
        default:
            return '';
    }
}


export function getLanguagePathByElementoOffertaFormativaType(elementoOffertaFormativaType: ElementoOffertaFormativaType): string {
    switch (elementoOffertaFormativaType) {
        case ElementoOffertaFormativaType.ATTIVITAEXTRA:
            return 'student.extra_activities';
        case ElementoOffertaFormativaType.CORSO:
            return 'common.didactic_activities';
        case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
            return 'study_plan_status.student_transversal_activities';
        default:
            return '';
    }
}

export function getLanguagePathByEsternoStatus(status: OnboardingEsternoStatus): string {
    switch (status) {
        case OnboardingEsternoStatus.INVITATO:
            return 'cycle_doctorate.esterni.status.invited';
        case OnboardingEsternoStatus.DASINCRONIZZARE:
            return 'cycle_doctorate.esterni.status.to_synchronize';
        case OnboardingEsternoStatus.SINCRONIZZATO:
            return 'cycle_doctorate.esterni.status.synchronized_gestionale';
        case OnboardingEsternoStatus.RIFIUTATO:
            return 'users_list.status.refused';
        case OnboardingEsternoStatus.GIAPRESENTE:
            return 'cycle_doctorate.esterni.status.already_in_platform';
        case OnboardingEsternoStatus.LOGGATO:
            return 'cycle_doctorate.esterni.status.logged';
        default:
            return '';
    }
}

export function getLanguagePathByEsternoAmmStatus(status: OnboardingEsternoStatusForAmm): string {
    switch (status) {
        case OnboardingEsternoStatusForAmm.INVITATO:
            return 'cycle_doctorate.esterni.status.invited';
        case OnboardingEsternoStatusForAmm.DASINCRONIZZARE:
            return 'cycle_doctorate.esterni.status.to_synchronize';
        case OnboardingEsternoStatusForAmm.SINCRONIZZATO:
            return 'cycle_doctorate.esterni.status.synchronized';
        case OnboardingEsternoStatusForAmm.GIAPRESENTE:
            return 'cycle_doctorate.esterni.status.already_in_platform';
        case OnboardingEsternoStatusForAmm.LOGGATO:
            return 'cycle_doctorate.esterni.status.logged';
        case OnboardingEsternoStatusForAmm.RIFIUTATO:
            return 'users_list.status.refused';
        default:
            return '';
    }
}

export function getLanguagePathByEsternoForKeycloackModeStatus(status: OnboardingEsternoStatusForKeycloakMode): string {
    switch (status) {
        case OnboardingEsternoStatusForKeycloakMode.INVITATO:
            return 'cycle_doctorate.esterni.status.invited';
        case OnboardingEsternoStatusForKeycloakMode.DASINCRONIZZARE:
            return 'cycle_doctorate.esterni.status.to_synchronize';
        case OnboardingEsternoStatusForKeycloakMode.SINCRONIZZATO:
            return 'users_list.status.register_confirmed';
        case OnboardingEsternoStatusForKeycloakMode.GIAPRESENTE:
            return 'cycle_doctorate.esterni.status.already_in_platform';
        case OnboardingEsternoStatusForKeycloakMode.LOGGATO:
            return 'cycle_doctorate.esterni.status.logged';
        case OnboardingEsternoStatusForKeycloakMode.RIFIUTATO:
            return 'users_list.status.register_refused';
        default:
            return '';
    }
}

export function getColorByBooleanStatus(status: BooleanStatus): string {
    return status ?'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500' : 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';

}

export function getColorByBooleanNotNullStatus(status: BooleanStatusNotNull): string {
    return status === BooleanStatusNotNull.BOOLEAN_TRUE ?'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500' : 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';

}

export function getColorByAggiornamentoPianoDiStudiStatus(status: AggiornamentoPianoDiStudiStatus): string {
    switch (status) {
        case AggiornamentoPianoDiStudiStatus.BOZZA:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case AggiornamentoPianoDiStudiStatus.RIFIUTATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE:
        case AggiornamentoPianoDiStudiStatus.DAAPPROVARE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AggiornamentoPianoDiStudiStatus.APPROVATO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByPianoDiStudiStatus(status: PianoDiStudiStatusExtended): string {
    switch (status) {
        case PianoDiStudiStatusExtended.NONPRESENTATO:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case PianoDiStudiStatusExtended.RIFIUTATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case PianoDiStudiStatusExtended.ATTESAAPPROVAZIONE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case PianoDiStudiStatusExtended.APPROVATO:
        case PianoDiStudiStatusExtended.APPROVATOVALIDO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case PianoDiStudiStatusExtended.APPROVATONONVALIDO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByTipoModificaCorsoPianoDiStudi(status: TipoModificaCorsoPianoDiStudi): string {
    switch (status) {
        case TipoModificaCorsoPianoDiStudi.NESSUNAMODIFICA:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case TipoModificaCorsoPianoDiStudi.RIMOZIONE:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case TipoModificaCorsoPianoDiStudi.AGGIUNTA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case TipoModificaCorsoPianoDiStudi.MODIFICATA:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByJobStatus(status: JobStatus): string {
    switch (status) {
        case JobStatus.DAAVVIARE:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case JobStatus.FALLITO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case JobStatus.INCORSO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case JobStatus.TERMINATOCONSUCCESSO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByAttivitaExtraPianoDiStudiStatus(status: AttivitaExtraPianoDiStudiStatus): string {
    switch (status) {
        case AttivitaExtraPianoDiStudiStatus.INSERITA:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case AttivitaExtraPianoDiStudiStatus.RIFIUTATA:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case AttivitaExtraPianoDiStudiStatus.DAAPPROVARE:
        case AttivitaExtraPianoDiStudiStatus.ATTESTATOPRESENTATO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaExtraPianoDiStudiStatus.APPROVATA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByAttivitaTrasversale(status: AttivitaTrasversaleStatus): string {
    switch (status) {
        case AttivitaTrasversaleStatus.ATTESADELIBERACOORDINATORE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaTrasversaleStatus.ATTESADOCUMENTODOTTORANDO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaTrasversaleStatus.DOCUMENTODOTTORANDOCARICATO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaTrasversaleStatus.DOCUMENTODOTTORANDORIFIUTATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaTrasversaleStatus.ATTESASUPERAMENTO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaTrasversaleStatus.RICHIESTASUPERAMENTOCONDOCUMENTOSUPERVISORE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaTrasversaleStatus.RICHIESTASUPERAMENTO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AttivitaTrasversaleStatus.COMPLETATA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByTicketStatus(status: TicketStatus): string {
    switch (status) {
        case TicketStatus.Open:
        case TicketStatus.Answered:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case TicketStatus.Closed:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case TicketStatus.Pending:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case TicketStatus.Resolved:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByTicketPriority(status: TicketPriority): string {
    switch (status) {
        case TicketPriority.High:
        case TicketPriority.Urgent:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case TicketPriority.Medium:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case TicketPriority.Low:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByTicketTipologia(status: TicketTipologia): string {
    switch (status) {
        case TicketTipologia.URGENTE:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case TicketTipologia.CRITICA:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case TicketTipologia.STANDARD:
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByQuestionarioSottomessoStatus(status: QuestionarioSottomessoStatus): string {
    switch (status) {
        case QuestionarioSottomessoStatus.BOZZA:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case QuestionarioSottomessoStatus.DACOMPLETARE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case QuestionarioSottomessoStatus.COMPLETATO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorBySpesaStudenteStatus(status: SpesaStudenteStatus): string {
    switch (status) {
        case SpesaStudenteStatus.RIFIUTATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case SpesaStudenteStatus.APPROVATOPARZIALE:
        case SpesaStudenteStatus.DAAPPROVARE:
        case SpesaStudenteStatus.APPROVATO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case SpesaStudenteStatus.APPROVATOPTA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByMobilitaStatus(status: PeriodoDiMobilitaStudenteStatus): string {
    switch (status) {
        case PeriodoDiMobilitaStudenteStatus.RIFIUTATO:
        case PeriodoDiMobilitaStudenteStatus.ANNULLATO:
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONERIFIUTATA:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE:
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONEAPPROVATAPARZIALMENTE:
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case PeriodoDiMobilitaStudenteStatus.INSERITO:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case PeriodoDiMobilitaStudenteStatus.APPROVATO:
        case PeriodoDiMobilitaStudenteStatus.CONCLUSO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case PeriodoDiMobilitaStudenteStatus.SOSPESO:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByPeriodoRegistroStatus(status: PeriodoRegistroAttivitaStatus): string {
    switch (status) {
        case PeriodoRegistroAttivitaStatus.BOZZA:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
        case PeriodoRegistroAttivitaStatus.RIFIUTATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case PeriodoRegistroAttivitaStatus.APPROVATOPARZIALE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case PeriodoRegistroAttivitaStatus.INSERITO:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case PeriodoRegistroAttivitaStatus.APPROVATO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByOffertaFormativaStatus(status: OffertaFormativaStatus): string {
    switch (status) {
        case OffertaFormativaStatus.NONPUBBLICA:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case OffertaFormativaStatus.PUBBLICA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByAggiornamentoBudgetStudenteStatus(status: AggiornamentoBudgetStudenteStatus): string {
    switch (status) {
        case AggiornamentoBudgetStudenteStatus.RIFIUTATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case AggiornamentoBudgetStudenteStatus.DAAPPROVARE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case AggiornamentoBudgetStudenteStatus.APPROVATOPTA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case AggiornamentoBudgetStudenteStatus.IMPOSTATOSUPERADMIN:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByRetribuzioneMobilitaStatus(status: RichiestaDiRetribuzioneMobilitaStatus): string {
    switch (status) {
        case RichiestaDiRetribuzioneMobilitaStatus.RIFIUTATA:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case RichiestaDiRetribuzioneMobilitaStatus.DAAPPROVARE:
        case RichiestaDiRetribuzioneMobilitaStatus.APPROVATAPARZIALE:
        case RichiestaDiRetribuzioneMobilitaStatus.APPROVATA:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case RichiestaDiRetribuzioneMobilitaStatus.VALIDATAPTA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByStoricoMobilitaStatus(status: TipoOperazioneStoricoMobilitaStudente): string {
    switch (status) {
        case TipoOperazioneStoricoMobilitaStudente.REVOCA:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case TipoOperazioneStoricoMobilitaStudente.CONCESSIONE:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByStudenteStatus(status: StudenteCicloStatus): string {
    switch (status) {
        case StudenteCicloStatus.INCORSO:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case StudenteCicloStatus.SOSPESO:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
        case StudenteCicloStatus.CESSATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByAutorizzazioneStatus(status: StatoAutorizzazione): string {
    switch (status) {
        case StatoAutorizzazione.NON_AUTORIZZATO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';  // Colore per NON AUTORIZZATO
        case StatoAutorizzazione.DELIBERA_DI_AUTORIZZAZIONE_INSERITA:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';  // Colore per DELIBERA DI AUTORIZZAZIONE INSERITA
        case StatoAutorizzazione.AUTORIZZATO:
            return 'text-green-600 bg-green-100 dark:text-green-50 dark:bg-green-600';  // Colore per AUTORIZZATO
        default:
            return 'text-grey-600 bg-grey-100 dark:text-grey-50 dark:bg-grey-600';  // Colore di default
    }
}


export function getColorByMissioneStatus(status: MissioneStatus): string {
    switch (status) {

        case MissioneStatus.APPROVATADIRETTOREDIDIPARTIMENTO:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case MissioneStatus.LIQUIDATA:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case MissioneStatus.RIFIUTATA:
        case MissioneStatus.RICHIESTALIQUIDAZIONERIFIUTATA:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case MissioneStatus.APPROVATAPARZIALE:
        case MissioneStatus.APPROVATA:
        case MissioneStatus.RICHIESTALIQUIDAZIONE:
        case MissioneStatus.RICHIESTAAPPROVAZIONE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}


export function getColorBySuperamentoAnnoStatus(status: SuperamentoAnnoStudenteStatus): string {
    switch (status) {
        case SuperamentoAnnoStudenteStatus.NONAVVIATO:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case SuperamentoAnnoStudenteStatus.COMPLETATO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case SuperamentoAnnoStudenteStatus.DOCUMENTIDOTTORANDORIFIUTATI:
        case SuperamentoAnnoStudenteStatus.DOCUMENTISUPERVISORERIFIUTATI:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        case SuperamentoAnnoStudenteStatus.DOCUMENTIDOTTORANDOSOTTOMESSI:
        case SuperamentoAnnoStudenteStatus.DOCUMENTISUPERVISORESOTTOMESSI:
        case SuperamentoAnnoStudenteStatus.INATTESADOCUMENTIDOTTORANDO:
        case SuperamentoAnnoStudenteStatus.INATTESACORREZIONECFUOREDOTTORANDO:
        case SuperamentoAnnoStudenteStatus.INATTESAVERIFICACFUORESUPERVISORE:
        case SuperamentoAnnoStudenteStatus.INATTESAVERIFICACFUORECOORDINATORE:
        case SuperamentoAnnoStudenteStatus.INATTESAATTESTAZIONESUPERAMENTO:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorYesOrNo(status: YesOrNotEnum): string {
    switch (status) {
        case YesOrNotEnum.YES:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case YesOrNotEnum.NO:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}


export function getColorByEsternoStatus(status: OnboardingEsternoStatus): string {
    switch (status) {
        case OnboardingEsternoStatus.DASINCRONIZZARE:
            return 'text-yellow-600 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-600';
        case OnboardingEsternoStatus.INVITATO:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case OnboardingEsternoStatus.SINCRONIZZATO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case OnboardingEsternoStatus.GIAPRESENTE:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
        case OnboardingEsternoStatus.LOGGATO:
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        case OnboardingEsternoStatus.RIFIUTATO:
            return 'text-red-500 bg-red-100 dark:text-red-50 dark:bg-red-500';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function capitalizeString(value: string) {
    if (!!value) {
        const splitString = value
            .split(' ')
            .map(s => `${s[0].toUpperCase()}${s.slice(1)}`);
        return splitString.join(' ');
    }
}

export function getAnnoInsegnamentoLanguagePath(year: AnnoRiferimentoValues): string {
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            return 'study_plan_status.first_year';
        case AnnoRiferimentoValues.SECONDO:
            return 'study_plan_status.second_year';
        case AnnoRiferimentoValues.TERZO:
            return 'study_plan_status.third_year';
        default:
            return '';
    }
}

export function getAnniInsegnamento(years: AnnoRiferimentoValues[], translocoService: TranslocoService): string {
    const anni = []
    years.forEach(year =>{
        switch (year) {
            case AnnoRiferimentoValues.PRIMO:
                anni.push('I');
                break;
            case AnnoRiferimentoValues.SECONDO:
                anni.push('II');
                break;
            case AnnoRiferimentoValues.TERZO:
                anni.push('III');
                break;
        }
    })

    return joinArrayAnni(anni, translocoService)
}

export const joinArrayAnni = (arr: any[], translocoService: TranslocoService): string => {
    if (arr.length === 0) {
        return '';
    } else if (arr.length === 1) {
        return arr[0];
    } else {
        const last = arr.pop();
        return arr.join(', ') + ' ' + translocoService.translate('common.and', {}, translocoService.getActiveLang()) + ' ' + last;
    }
}


export function makeFilename(filenameFromBE: string, removeFinalId = false): string {
    const extension = filenameFromBE?.substring(filenameFromBE?.lastIndexOf('.'));
    const fileNameWithouthTimestamp = filenameFromBE?.substring(0, filenameFromBE?.lastIndexOf('_'));
    const fileNameFinalPart = fileNameWithouthTimestamp?.substring(fileNameWithouthTimestamp?.lastIndexOf('/') + 1);
    const timestampString = filenameFromBE?.substring(filenameFromBE?.lastIndexOf('_') + 1, filenameFromBE?.lastIndexOf('.'));
    const uploadDate = italianDateWithFormat(moment(Number(timestampString)).toDate(), 'DD_MM_YYYY');
    let fileNameToDownload =/* ciclo + '_' + codiceCorsoEsse3 + '_' + cognome + '_' + nome + '_' + */fileNameFinalPart + '_' + uploadDate + extension;
    if(removeFinalId){
        const fileNameFinalPartWithoutId = fileNameFinalPart?.substring(0, fileNameFinalPart?.lastIndexOf('_'));
        fileNameToDownload = fileNameFinalPartWithoutId + '_' + uploadDate + extension;
    }
    return fileNameToDownload;
}

export function makeFilenameFromHeaderFooterBorsa(filenameFromBE: string): string {
    const extension = filenameFromBE?.substring(filenameFromBE?.lastIndexOf('.'));
    const fileNameWithouthTimestamp = filenameFromBE?.substring(0, filenameFromBE?.lastIndexOf('_'));
    const utilParts = fileNameWithouthTimestamp.split('/');
    const fileNameFinalPart = utilParts.length >= 2 ? (utilParts[utilParts.length - 2] + '_' + utilParts[utilParts.length - 1]) : '';
    const timestampString = filenameFromBE?.substring(filenameFromBE?.lastIndexOf('_') + 1, filenameFromBE?.lastIndexOf('.'));
    const uploadDate = italianDateWithFormat(moment(Number(timestampString)).toDate(), 'DD_MM_YYYY');
    const fileNameToDownload = fileNameFinalPart + '_' + uploadDate + extension;
    return fileNameToDownload;
}

export function makeFilenameFromFE(cicloCorsoRuolo: CicloCorsoRuoloInterface, extension: string, fileTypeForName: string): string {
    const downloadDate = italianDateWithFormat(moment().toDate(), 'DD_MM_YYYY');
    const fileNameToDownload = cicloCorsoRuolo?.ciclo + '_' + cicloCorsoRuolo?.codiceCorsoStudi + '_' + fileTypeForName + '_' + downloadDate + extension;
    return fileNameToDownload;
};

export function makeFilenameFromFEOnlyCycle(ciclo: string, extension: string, fileTypeForName: string): string {
    const downloadDate = italianDateWithFormat(moment().toDate(), 'DD_MM_YYYY');
    const fileNameToDownload = ciclo + '_' + fileTypeForName + '_' + downloadDate + extension;
    return fileNameToDownload;
};

export function openMustRefreshDialog(fuseConfirmationService: FuseConfirmationService, translocoService: TranslocoService) {
    const activeLang = translocoService.getActiveLang();
    const translation = translocoService.getTranslation().get(activeLang);
    fuseConfirmationService.open({
            title: get(translation, 'common.scholarship', null),
            message: get(translation, 'common.must_refresh', null),
            icon: {
                name: 'mat_outline:info',
                color: 'warning'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
                {
                    color: 'primary',
                    label: get(translation, 'common.update', null),
                    function: () => window.location.reload()
                },
            ]
        }
    );

}

export function getColorByHttpStatus(status: string): string {
    switch (status) {
        case '200':
            return 'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500';
        default:
            return 'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600';
    }
}

export function getLanguagePathByHttpStatus(status: string): string {
    return status;
}

export function annoInsegnamentoValueToNumber(anno: AnnoRiferimentoValues | AnnoRiferimentoValues): number {
    switch (anno) {
        case AnnoRiferimentoValues.PRIMO:
            return 1;
        case AnnoRiferimentoValues.SECONDO:
            return 2;
        case AnnoRiferimentoValues.TERZO:
            return 3;
    }
}

export function elementoOffertaFormativaTypeToNumber(type: ElementoOffertaFormativaType): number {
    switch (type) {
        case ElementoOffertaFormativaType.CORSO:
            return 0;
        case ElementoOffertaFormativaType.ATTIVITAEXTRA:
            return 1;
        case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
            return 2;
    }
}

export const calcolaPercentuali = (valori: number[]): number[] => {
    if (!!valori?.length) {
        const totale = valori.reduce((acc, val) => acc + val, 0);
        if (totale > 0) {
            // Calcola le percentuali iniziali non arrotondate
            let percentuali = valori.map(val => (val / totale) * 100);

            // Arrotonda ogni percentuale a una cifra decimale
            let percentualiArrotondate = percentuali.map(p => Number(p.toFixed(1)));

            // Calcola la somma delle percentuali arrotondate
            let sommaPercentualiArrotondate = percentualiArrotondate.reduce((acc, val) => acc + val, 0);

            // Calcola la discrepanza totale rispetto a 100
            let discrepanza = Math.round((100 - sommaPercentualiArrotondate) * 10) / 10;

            // Applica la discrepanza: distribuisci ±0.1 in base alla differenza
            for (let i = 0; Math.abs(discrepanza) > 0 && i < percentualiArrotondate.length; i++) {
                if (discrepanza > 0) {
                    percentualiArrotondate[i] += 0.1;
                    discrepanza -= 0.1;
                } else if (discrepanza < 0) {
                    percentualiArrotondate[i] -= 0.1;
                    discrepanza += 0.1;
                }
            }

            // Assicura che le percentuali siano arrotondate a una cifra decimale
            return percentualiArrotondate.map(p => Number(p.toFixed(1)));
        } else {
            // Se il totale è 0, restituisce un array di zeri
            return Array(valori.length).fill(0);
        }
    }
    return [];
}

export const getColorHexStudenteCicloStatus = (status: StudenteCicloStatusPerStatsConIncoming): string => {
    switch (status) {
        case StudenteCicloStatusPerStatsConIncoming.INCORSO:
            return '#3B82F6FF';
        case StudenteCicloStatusPerStatsConIncoming.COMPLETATO:
            return '#22C55EFF';
        case StudenteCicloStatusPerStatsConIncoming.SOSPESO:
            return '#6B7280FF';
        case StudenteCicloStatusPerStatsConIncoming.CESSATO:
            return '#EF4444FF';
        case StudenteCicloStatusPerStatsConIncoming.INCOMING:
            return '#63dabe';
        default:
            return '#6B7280FF';
    }
}

export const randomColor = (s: string) => {
    let hash = 0;
    s.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour
}

export const generateGradientColor = (baseColor, index, step = 5) => {
    // Parse the baseColor assuming it's in RGB format: "rgb(r, g, b)"
    const match = baseColor.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
    if (!match) {
        console.error('Base color format is incorrect. Please use "rgb(r, g, b)" format.');
        return null;
    }

    // Extract the RGB components from the match and parse them as integers
    let [r, g, b] = match.slice(1).map(Number);

    // Modify the RGB components based on the index and step
    // This example simply adds the index times the step to each component,
    // but you can adjust the formula to create different types of gradients.
    r = Math.min(255, r + index * step);
    g = Math.min(255, g + index * step);
    b = Math.min(255, b + index * step);

    // Return the new color in RGB format
    return `rgb(${r}, ${g}, ${b})`;
}

// Convert RGB to HEX if needed
export const rgbToHex = (rgb) => {
    const [r, g, b] = rgb.match(/\d+/g).map(Number);
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}


export const getColorHexByMobilitaStatus = (status: PeriodoDiMobilitaStudenteStatus): string => {
    switch (status) {
        case PeriodoDiMobilitaStudenteStatus.RIFIUTATO:
        case PeriodoDiMobilitaStudenteStatus.ANNULLATO:
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONERIFIUTATA:
            return '#DC2626FF';
        case PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE:
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONEAPPROVATAPARZIALMENTE:
        case PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONE:
            return '#EAB308FF';
        case PeriodoDiMobilitaStudenteStatus.INSERITO:
            return '#3B82F6FF';
        case PeriodoDiMobilitaStudenteStatus.APPROVATO:
            return '#A3E635FF';
        case PeriodoDiMobilitaStudenteStatus.CONCLUSO:
            return '#4ADE80FF';
        case PeriodoDiMobilitaStudenteStatus.SOSPESO:
            return '#6B7280FF';
        default:
            return '#6B7280FF';
    }
}


export const getColorHexByAggiornamentoPianoDiStudiStatus = (status: AggiornamentoPianoDiStudiStatus): string => {
    switch (status) {
        case AggiornamentoPianoDiStudiStatus.BOZZA:
            return '#3B82F6FF';
        case AggiornamentoPianoDiStudiStatus.RIFIUTATO:
            return '#DC2626FF';
        case AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE:
        case AggiornamentoPianoDiStudiStatus.DAAPPROVARE:
            return '#EAB308FF';
        case AggiornamentoPianoDiStudiStatus.APPROVATO:
            return '#A3E635FF';
        default:
            return 'text-grey-600 bg-grey-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function openFileInBlankWindow(file: Blob, fileName: string){
    /**
     * Show file in a new window if visualizable type else download it with fileName
     */
    console.log('opening file with name:', fileName)
    const ext = fileName?.substr(fileName.lastIndexOf('.') + 1);
    const imageExt= ['png', 'jpeg', 'jpg'];
    const textExt= ['pdf'];
    const typeBlob = imageExt?.includes(ext)? ('image/'+ext): textExt?.includes(ext?.toLowerCase())? ('application/'+ext): '';
    // if no showable file type download it, else visualize
    if(typeBlob === ''){
        fs.saveAs(file, fileName);
    } else {
        const blob = new File([file], fileName,{type: typeBlob});
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL, '_blank');
    }
}

export function saveFile(file: Blob, fileName: string){
    /**
     * Show file in a new window if visualizable type else download it with fileName
     */
    console.log('opening file with name:', fileName)
    const ext = fileName?.substr(fileName.lastIndexOf('.') + 1);
    const imageExt= ['png', 'jpeg', 'jpg'];
    const textExt= ['pdf'];
    const typeBlob = imageExt?.includes(ext)? ('image/'+ext): textExt?.includes(ext)? ('application/'+ext): '';
    // if no showable file type download it, else visualize
    if(typeBlob === ''){
        fs.saveAs(file, fileName);
    } else {
        const blob = new File([file], fileName,{type: typeBlob});
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL, '_blank');
    }
}

export function replicateActivitiesWithMultipleCategories(activities?: any[], offertaFormativa?: OffertaFormativaInfoViewImpl){
    /**
     * Given that an activity can have multiple areas, replicate these ones with each single area
     */
    if(offertaFormativa?.configurazione?.ambiti_enabled) {
        return activities?.flatMap(activity => {
            if(activity?.ambiti?.length > 0){
                return activity.ambiti?.map(ambito => ({...activity, area: ambito}));
            } else {
                return activity;
            }
        })
    } else  {
        return activities;
    }
}

/** NO MORE USED
export function prepareActivitiesForTable(translation: Translation, activities?: any[], offertaFormativa?: OffertaFormativaInfoViewImpl){
    if(offertaFormativa?.configurazione?.ambiti_enabled){
        const noAreaLabel = get(translation, 'training_offer.activities_without_area', null);
        return activities?.map((activity, index) => {
            if(index == 0 || activities[index - 1]?.area?.id !== activity?.area?.id) {
                return {
                    ...activity,
                    firstActivityOfCategory: activity?.area
                };
            } else {
                return activity
            }
        });
    } else {
        return activities;
    }

}**/

export function toTitleCase(s: string){
    return s?.split(" ")?.map((l: string) => l[0].toUpperCase() + l.substring(1))?.join(" ")
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function handleErrorOnExportPDF(err, showSnackFn: () => void, code1023Fn?: (errorObject: any) => void, code1026Fn?: (errorObject: any) => void){
    if(err?.error) {
        err.error.text()?.then(errorText => {
            if(!isJsonString(errorText)){
                showSnackFn()
                return;
            }
            const errorObject = JSON.parse(errorText);
            if(errorObject?.code == 1023 && code1023Fn) {
                code1023Fn(errorObject);
            } else if(errorObject?.code == 1026 && code1026Fn) {
                code1026Fn(errorObject)
            } else {
                showSnackFn();
            }
        }).catch(() => showSnackFn());
    } else {
        showSnackFn();
    }
}

export function splitStringByLength(str: string, maxLength: number): string[] {
    const words = str.split(' ');
    const result: string[] = [];
    let current = '';

    for (const word of words) {
        if ((current + ' ' + word).trim().length > maxLength) {
            result.push(current.trim());
            current = word;
        } else {
            if (current !== '') {
                current += ' ';
            }
            current += word;
        }
    }

    // Push the remaining part
    if (current.trim() !== '') {
        result.push(current.trim());
    }

    return result;
}

export function getStudenteMotiviForStatus(status: StudenteCicloStatus[]): StudenteCicloMotivoStatus[] {
    let motivi = [];
    if(status.includes(StudenteCicloStatus.INCORSO)){
        motivi.push(...[
            StudenteCicloMotivoStatus.IMM,
            StudenteCicloMotivoStatus.IMMSI,
        ]);
    }
    if(status.includes(StudenteCicloStatus.SOSPESO)){
        motivi.push(...[
            StudenteCicloMotivoStatus.MAT,
            StudenteCicloMotivoStatus.STU,
            StudenteCicloMotivoStatus.MAL,
            StudenteCicloMotivoStatus.ALT,
        ]);
    }
    if(status.includes(StudenteCicloStatus.CESSATO)){
        motivi.push(...[
            StudenteCicloMotivoStatus.TIT,
            StudenteCicloMotivoStatus.ERRIM,
            StudenteCicloMotivoStatus.RINC,
            StudenteCicloMotivoStatus.DEC,
            StudenteCicloMotivoStatus.RIN,
            StudenteCicloMotivoStatus.RINI,
            StudenteCicloMotivoStatus.DCS,
        ]);
    }
    return motivi;
}

export function missioniDaApprovareByRole(localStorageService: LocalStorageService): MissioneStatus[] {
    const currentRuolo = localStorageService.getCicloCorsoRuolo()?.ruolo;
    const currentSottoruolo = localStorageService.getSottoruoloCiclo();
    if (currentRuolo === AuthorityType.DOCENTE) {
        switch (currentSottoruolo) {
            case AuthorityType.SUPERVISORE:
            case AuthorityType.COSUPERVISORE:
                return [MissioneStatus.RICHIESTAAPPROVAZIONE];
            case AuthorityType.COORDINATORE:
                return [MissioneStatus.APPROVATAPARZIALE];
            case AuthorityType.DIRETTOREDIDIPARTIMENTO:
                return [MissioneStatus.APPROVATA];
            default:
                return undefined;
        }
    } else {
        return undefined;
    }
}

export function getCosupPermessoApprovazione(studente: StudenteCicloInfoView, currentUserId: string){
    return studente?.cosupervisori?.find(c =>
        c?.cosupervisore?.id === currentUserId)?.hasPermessiApprovativi;
}

export type AnnoRiferimentoWithAllValues = 'PRIMO' | 'SECONDO' | 'TERZO' | 'ALL';

export const AnnoRiferimentoWithAllValues = {
    PRIMO: 'PRIMO' as AnnoRiferimentoWithAllValues,
    SECONDO: 'SECONDO' as AnnoRiferimentoWithAllValues,
    TERZO: 'TERZO' as AnnoRiferimentoWithAllValues,
    ALL: 'ALL' as AnnoRiferimentoWithAllValues,
};


export const getStatoAutorizzazione = (hasDeliberaDiProgrammazioneMobilita: boolean, hasAutorizzazioneMobilita: boolean): StatoAutorizzazione => {
    if (hasAutorizzazioneMobilita) {
        return StatoAutorizzazione.AUTORIZZATO;
    } else if (hasDeliberaDiProgrammazioneMobilita) {
        return StatoAutorizzazione.DELIBERA_DI_AUTORIZZAZIONE_INSERITA;
    } else {
        return StatoAutorizzazione.NON_AUTORIZZATO;
    }
}
