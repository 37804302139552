/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di fondo di riferimento.
 */
export type TipoFondoDiRiferimento = 'BUDGET_DOTTORANDO' | 'ALTRI_FONDI' | 'BUDGET_DOTTORANDO_PIU_ALTRI_FONDI';

export const TipoFondoDiRiferimento = {
    BUDGETDOTTORANDO: 'BUDGET_DOTTORANDO' as TipoFondoDiRiferimento,
    ALTRIFONDI: 'ALTRI_FONDI' as TipoFondoDiRiferimento,
    BUDGETDOTTORANDOPIUALTRIFONDI: 'BUDGET_DOTTORANDO_PIU_ALTRI_FONDI' as TipoFondoDiRiferimento
};