/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthorityType } from '../model/authorityType';
import { BooleanDTO } from '../model/booleanDTO';
import { BudgetCicloCorsoDiStudiInfoView } from '../model/budgetCicloCorsoDiStudiInfoView';
import { CicloCorsoDiStudiInfoDTO } from '../model/cicloCorsoDiStudiInfoDTO';
import { ClonaGruppiDaCicloPrecedenteResponse } from '../model/clonaGruppiDaCicloPrecedenteResponse';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GetConfigurazioneCicloDottoratoResponse } from '../model/getConfigurazioneCicloDottoratoResponse';
import { LongDTO } from '../model/longDTO';
import { PercorsoDiStudiView } from '../model/percorsoDiStudiView';
import { SottoruoloUtenteUserView } from '../model/sottoruoloUtenteUserView';
import { UpdateBudgetCicloCorsoDiStudiRequest } from '../model/updateBudgetCicloCorsoDiStudiRequest';
import { UpdateCommissioneDiAutovalutazioneDTO } from '../model/updateCommissioneDiAutovalutazioneDTO';
import { UpdateCommissioneDiAutovalutazioneResponseDTO } from '../model/updateCommissioneDiAutovalutazioneResponseDTO';
import { UpdateConfigurazioneSuperamentoAnnoDTO } from '../model/updateConfigurazioneSuperamentoAnnoDTO';
import { UpdateCoordinatoreCollegioDocentiRequestDTO } from '../model/updateCoordinatoreCollegioDocentiRequestDTO';
import { UpdateDirettoriDiDipartimentoDTO } from '../model/updateDirettoriDiDipartimentoDTO';
import { UpdateGruppoDiCoordinamentoDTO } from '../model/updateGruppoDiCoordinamentoDTO';
import { UpdateGruppoPTADTO } from '../model/updateGruppoPTADTO';
import { UpdateMembriCollegioDocentiDTO } from '../model/updateMembriCollegioDocentiDTO';
import { UpdateMembriCollegioDocentiResponseDTO } from '../model/updateMembriCollegioDocentiResponseDTO';
import { UpdateSegretariAmministrativiDiDipartimentoDTO } from '../model/updateSegretariAmministrativiDiDipartimentoDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CicliCorsiDiStudiService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Verifica se è possibile clonare i gruppi di un ciclo corso di studi prendendola dal ciclo precedente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkClonaGruppi(observe?: 'body', reportProgress?: boolean): Observable<BooleanDTO>;
    public checkClonaGruppi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanDTO>>;
    public checkClonaGruppi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanDTO>>;
    public checkClonaGruppi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanDTO>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/clona-gruppi/check`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifica se l&#x27;offerta formativa associata al ciclo corso di studi è PUBBLICA.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkOffertaFormativaIsPubblica(observe?: 'body', reportProgress?: boolean): Observable<BooleanDTO>;
    public checkOffertaFormativaIsPubblica(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanDTO>>;
    public checkOffertaFormativaIsPubblica(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanDTO>>;
    public checkOffertaFormativaIsPubblica(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanDTO>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/offerta-formativa/is-pubblica`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di clonare i gruppi di un ciclo corso di studi prendendola dal ciclo precedente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clonaGruppiDaCicloPrecedente(observe?: 'body', reportProgress?: boolean): Observable<ClonaGruppiDaCicloPrecedenteResponse>;
    public clonaGruppiDaCicloPrecedente(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClonaGruppiDaCicloPrecedenteResponse>>;
    public clonaGruppiDaCicloPrecedente(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClonaGruppiDaCicloPrecedenteResponse>>;
    public clonaGruppiDaCicloPrecedente(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClonaGruppiDaCicloPrecedenteResponse>('post',`${this.basePath}/api/v1/ciclo-corso-di-studi/clona-gruppi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il budget di default impostato per tutti i dottorandi del corso per ogni anno.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetCicloCorsoDiStudi(observe?: 'body', reportProgress?: boolean): Observable<BudgetCicloCorsoDiStudiInfoView>;
    public getBudgetCicloCorsoDiStudi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetCicloCorsoDiStudiInfoView>>;
    public getBudgetCicloCorsoDiStudi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetCicloCorsoDiStudiInfoView>>;
    public getBudgetCicloCorsoDiStudi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BudgetCicloCorsoDiStudiInfoView>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/budget`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i curriculum di un corso di studi per un ciclo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCicloCorsoDiStudiCurriculum(observe?: 'body', reportProgress?: boolean): Observable<Array<PercorsoDiStudiView>>;
    public getCicloCorsoDiStudiCurriculum(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PercorsoDiStudiView>>>;
    public getCicloCorsoDiStudiCurriculum(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PercorsoDiStudiView>>>;
    public getCicloCorsoDiStudiCurriculum(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PercorsoDiStudiView>>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/curriculum`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info di un corso di studi per un ciclo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCicloCorsoDiStudiInfo(observe?: 'body', reportProgress?: boolean): Observable<CicloCorsoDiStudiInfoDTO>;
    public getCicloCorsoDiStudiInfo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloCorsoDiStudiInfoDTO>>;
    public getCicloCorsoDiStudiInfo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloCorsoDiStudiInfoDTO>>;
    public getCicloCorsoDiStudiInfo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CicloCorsoDiStudiInfoDTO>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i Cosupervisori di un corso di studi per un ciclo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCosupervisoriCicloCorsoDiStudi(observe?: 'body', reportProgress?: boolean): Observable<Array<SottoruoloUtenteUserView>>;
    public getCosupervisoriCicloCorsoDiStudi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SottoruoloUtenteUserView>>>;
    public getCosupervisoriCicloCorsoDiStudi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SottoruoloUtenteUserView>>>;
    public getCosupervisoriCicloCorsoDiStudi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SottoruoloUtenteUserView>>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/cosupervisori`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i Docenti di un corso di studi per un ciclo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocentiCorsoCicloCorsoDiStudi(observe?: 'body', reportProgress?: boolean): Observable<Array<SottoruoloUtenteUserView>>;
    public getDocentiCorsoCicloCorsoDiStudi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SottoruoloUtenteUserView>>>;
    public getDocentiCorsoCicloCorsoDiStudi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SottoruoloUtenteUserView>>>;
    public getDocentiCorsoCicloCorsoDiStudi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SottoruoloUtenteUserView>>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/docenti`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il numero di dottorandi iscritti al corso di studi per ciclo che non hanno Supervisore e Cosupervisore.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNumDottorandiSenzaSupervisoreECosupervisore(observe?: 'body', reportProgress?: boolean): Observable<LongDTO>;
    public getNumDottorandiSenzaSupervisoreECosupervisore(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LongDTO>>;
    public getNumDottorandiSenzaSupervisoreECosupervisore(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LongDTO>>;
    public getNumDottorandiSenzaSupervisoreECosupervisore(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LongDTO>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/num-dottorandi-senza-supervisore-e-cosupervisore`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il numero di utenti con il ruolo indicato sul corso di studi per ciclo.
     * 
     * @param ruolo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNumUtentiConRuoloSuCorsoDiStudi(ruolo: AuthorityType, observe?: 'body', reportProgress?: boolean): Observable<LongDTO>;
    public getNumUtentiConRuoloSuCorsoDiStudi(ruolo: AuthorityType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LongDTO>>;
    public getNumUtentiConRuoloSuCorsoDiStudi(ruolo: AuthorityType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LongDTO>>;
    public getNumUtentiConRuoloSuCorsoDiStudi(ruolo: AuthorityType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ruolo === null || ruolo === undefined) {
            throw new Error('Required parameter ruolo was null or undefined when calling getNumUtentiConRuoloSuCorsoDiStudi.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ruolo !== undefined && ruolo !== null) {
            queryParameters = queryParameters.set('ruolo', <any>ruolo);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LongDTO>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/num-utenti-con-ruolo-corso-di-studi`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il numero di utenti con il sottoruolo indicato sul corso di studi per ciclo.
     * 
     * @param sottoruolo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNumUtentiConSottoruoloSuCorsoDiStudi(sottoruolo: AuthorityType, observe?: 'body', reportProgress?: boolean): Observable<LongDTO>;
    public getNumUtentiConSottoruoloSuCorsoDiStudi(sottoruolo: AuthorityType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LongDTO>>;
    public getNumUtentiConSottoruoloSuCorsoDiStudi(sottoruolo: AuthorityType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LongDTO>>;
    public getNumUtentiConSottoruoloSuCorsoDiStudi(sottoruolo: AuthorityType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sottoruolo === null || sottoruolo === undefined) {
            throw new Error('Required parameter sottoruolo was null or undefined when calling getNumUtentiConSottoruoloSuCorsoDiStudi.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sottoruolo !== undefined && sottoruolo !== null) {
            queryParameters = queryParameters.set('sottoruolo', <any>sottoruolo);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LongDTO>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/num-utenti-con-sottoruolo-corso-di-studi`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i Supervisori di un corso di studi per un ciclo.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSupervisoriCicloCorsoDiStudi(observe?: 'body', reportProgress?: boolean): Observable<Array<SottoruoloUtenteUserView>>;
    public getSupervisoriCicloCorsoDiStudi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SottoruoloUtenteUserView>>>;
    public getSupervisoriCicloCorsoDiStudi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SottoruoloUtenteUserView>>>;
    public getSupervisoriCicloCorsoDiStudi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SottoruoloUtenteUserView>>('get',`${this.basePath}/api/v1/ciclo-corso-di-studi/supervisori`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il budget di default impostato per tutti i dottorandi del corso per ogni anno.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBudgetCicloCorsoDiStudi(body: UpdateBudgetCicloCorsoDiStudiRequest, observe?: 'body', reportProgress?: boolean): Observable<BudgetCicloCorsoDiStudiInfoView>;
    public updateBudgetCicloCorsoDiStudi(body: UpdateBudgetCicloCorsoDiStudiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetCicloCorsoDiStudiInfoView>>;
    public updateBudgetCicloCorsoDiStudi(body: UpdateBudgetCicloCorsoDiStudiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetCicloCorsoDiStudiInfoView>>;
    public updateBudgetCicloCorsoDiStudi(body: UpdateBudgetCicloCorsoDiStudiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateBudgetCicloCorsoDiStudi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BudgetCicloCorsoDiStudiInfoView>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/budget`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna gli elementi della commissione di autovalutazione di un corso di studi per un ciclo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCommissioneDiAutovalutazione(body: UpdateCommissioneDiAutovalutazioneDTO, observe?: 'body', reportProgress?: boolean): Observable<UpdateCommissioneDiAutovalutazioneResponseDTO>;
    public updateCommissioneDiAutovalutazione(body: UpdateCommissioneDiAutovalutazioneDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateCommissioneDiAutovalutazioneResponseDTO>>;
    public updateCommissioneDiAutovalutazione(body: UpdateCommissioneDiAutovalutazioneDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateCommissioneDiAutovalutazioneResponseDTO>>;
    public updateCommissioneDiAutovalutazione(body: UpdateCommissioneDiAutovalutazioneDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCommissioneDiAutovalutazione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateCommissioneDiAutovalutazioneResponseDTO>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/commissione-di-autovalutazione`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna una configurazione di superamento d&#x27;anno, nello specifico: il numero di cfu assegnati col superamento d&#x27;anno e la Guida Coordinatore.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConfigurazioneSuperamentoAnno(body: UpdateConfigurazioneSuperamentoAnnoDTO, observe?: 'body', reportProgress?: boolean): Observable<GetConfigurazioneCicloDottoratoResponse>;
    public updateConfigurazioneSuperamentoAnno(body: UpdateConfigurazioneSuperamentoAnnoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetConfigurazioneCicloDottoratoResponse>>;
    public updateConfigurazioneSuperamentoAnno(body: UpdateConfigurazioneSuperamentoAnnoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetConfigurazioneCicloDottoratoResponse>>;
    public updateConfigurazioneSuperamentoAnno(body: UpdateConfigurazioneSuperamentoAnnoDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateConfigurazioneSuperamentoAnno.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetConfigurazioneCicloDottoratoResponse>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/configurazione-superamento-anno`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il Coordinatore del Collegio Docenti di un corso di studi per un ciclo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCoordinatoreCollegioDocenti(body: UpdateCoordinatoreCollegioDocentiRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<CicloCorsoDiStudiInfoDTO>;
    public updateCoordinatoreCollegioDocenti(body: UpdateCoordinatoreCollegioDocentiRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloCorsoDiStudiInfoDTO>>;
    public updateCoordinatoreCollegioDocenti(body: UpdateCoordinatoreCollegioDocentiRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloCorsoDiStudiInfoDTO>>;
    public updateCoordinatoreCollegioDocenti(body: UpdateCoordinatoreCollegioDocentiRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCoordinatoreCollegioDocenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloCorsoDiStudiInfoDTO>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/collegio-docenti/coordinatore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna i Direttori di Dipartimento di un corso di studi per un ciclo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDirettoriDiDipartimento(body: UpdateDirettoriDiDipartimentoDTO, observe?: 'body', reportProgress?: boolean): Observable<CicloCorsoDiStudiInfoDTO>;
    public updateDirettoriDiDipartimento(body: UpdateDirettoriDiDipartimentoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloCorsoDiStudiInfoDTO>>;
    public updateDirettoriDiDipartimento(body: UpdateDirettoriDiDipartimentoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloCorsoDiStudiInfoDTO>>;
    public updateDirettoriDiDipartimento(body: UpdateDirettoriDiDipartimentoDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDirettoriDiDipartimento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloCorsoDiStudiInfoDTO>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/direttori-di-dipartimento`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna gli elementi del gruppo di coordinamento di un corso di studi per un ciclo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGruppoDiCoordinamento(body: UpdateGruppoDiCoordinamentoDTO, observe?: 'body', reportProgress?: boolean): Observable<CicloCorsoDiStudiInfoDTO>;
    public updateGruppoDiCoordinamento(body: UpdateGruppoDiCoordinamentoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloCorsoDiStudiInfoDTO>>;
    public updateGruppoDiCoordinamento(body: UpdateGruppoDiCoordinamentoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloCorsoDiStudiInfoDTO>>;
    public updateGruppoDiCoordinamento(body: UpdateGruppoDiCoordinamentoDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateGruppoDiCoordinamento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloCorsoDiStudiInfoDTO>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/gruppo-di-coordinamento`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna gli elementi del Gruppo_PTA di un corso di studi per un ciclo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGruppoPTA(body: UpdateGruppoPTADTO, observe?: 'body', reportProgress?: boolean): Observable<CicloCorsoDiStudiInfoDTO>;
    public updateGruppoPTA(body: UpdateGruppoPTADTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloCorsoDiStudiInfoDTO>>;
    public updateGruppoPTA(body: UpdateGruppoPTADTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloCorsoDiStudiInfoDTO>>;
    public updateGruppoPTA(body: UpdateGruppoPTADTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateGruppoPTA.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloCorsoDiStudiInfoDTO>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/gruppo-pta`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna i membri del Collegio Docenti di un corso di studi per un ciclo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMembriCollegioDocenti(body: UpdateMembriCollegioDocentiDTO, observe?: 'body', reportProgress?: boolean): Observable<UpdateMembriCollegioDocentiResponseDTO>;
    public updateMembriCollegioDocenti(body: UpdateMembriCollegioDocentiDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateMembriCollegioDocentiResponseDTO>>;
    public updateMembriCollegioDocenti(body: UpdateMembriCollegioDocentiDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateMembriCollegioDocentiResponseDTO>>;
    public updateMembriCollegioDocenti(body: UpdateMembriCollegioDocentiDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateMembriCollegioDocenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateMembriCollegioDocentiResponseDTO>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/collegio-docenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna i Segretari Amministrativi Di Dipartimento di un corso di studi per un ciclo.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSegretariAmministrativiDiDipartimento(body: UpdateSegretariAmministrativiDiDipartimentoDTO, observe?: 'body', reportProgress?: boolean): Observable<CicloCorsoDiStudiInfoDTO>;
    public updateSegretariAmministrativiDiDipartimento(body: UpdateSegretariAmministrativiDiDipartimentoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CicloCorsoDiStudiInfoDTO>>;
    public updateSegretariAmministrativiDiDipartimento(body: UpdateSegretariAmministrativiDiDipartimentoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CicloCorsoDiStudiInfoDTO>>;
    public updateSegretariAmministrativiDiDipartimento(body: UpdateSegretariAmministrativiDiDipartimentoDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSegretariAmministrativiDiDipartimento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CicloCorsoDiStudiInfoDTO>('put',`${this.basePath}/api/v1/ciclo-corso-di-studi/segretari-amministrativi-di-dipartimento`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
