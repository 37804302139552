import {
    AggiornamentoPianoDiStudiInfoView, AggiornamentoPianoDiStudiStatus,
    MissioneInfoView,
    MissioneStatus,
    ModalitaGestioneUtentiEsterni,
    OnboardingEsternoStatus,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus,
    PeriodoRegistroAttivitaInfoView,
    PeriodoRegistroAttivitaStatus,
    RichiestaDiRetribuzioneMobilitaInfoView,
    RichiestaDiRetribuzioneMobilitaStatus,
    SpesaStudenteInfoView,
    SpesaStudenteStatus
} from "../../../../api-clients/generated/services";
import {FlowTimelineConfiguration, FlowTimelineData, FlowTimelineStepState} from "./flow-timeline.component";
import moment from "moment/moment";
import {
    OnboardingEsternoStatusForAmm,
    OnboardingEsternoStatusForKeycloakMode
} from "../../../modules/landing/users-list/esterni-list/esterni-list-utils";
import {StudyPlanAggiornamentoDataUI} from "../../interface/piano-di-studi-data-u-i";

//ACTIVITY LOG

export function buildFlowTimelineConfigurationForActivityLogValidation() {
    // this is the possible step skeleton for the flow
    return {
        steps: [
            {
                labelPending: 'activity_log.draft',
                labelCompleted: 'student.student_request',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: 'study_plan_status.to_be_approved',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: 'student.approved_by_supervisor',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: '',
                labelCompleted: '',
                labelRefused: 'budget.refused',
            }
        ]
    };
}


export function buildFlowTimelineDataForActivityLogValidation(validationRequest: PeriodoRegistroAttivitaInfoView): FlowTimelineData[] {

    const errorAfterApprovalStep = {
        state: FlowTimelineStepState.REFUSED,
        user: validationRequest.utenteRifiuto ? (validationRequest.utenteRifiuto?.cognome + ' ' + validationRequest.utenteRifiuto?.nome) : undefined,
        date: validationRequest.dataRifiuto ? moment(validationRequest.dataRifiuto).format('DD/MM/YYYY HH:mm') : undefined,
        profile: validationRequest.sottoruoloUtenteRifiuto,
        // error after coordinator approval step is shown only if  state is refused and there was second approval
        // (refusal was made after coord approval)
        skip: !(validationRequest.stato === PeriodoRegistroAttivitaStatus.RIFIUTATO && validationRequest.dataSecondaApprovazione),
    };

    const coordStep: FlowTimelineData = {
        // step COORDINATOR is considered completed if:
        // - the next state is completed (error step not skipped in this case)
        // - the state of the request is the completion for the step
        // step COORDINATOR is considered refused if:
        // - the state of the request is refused and there is first approval but not second
        // (refusal was made after sup approval but before coord approval)
        state: (!errorAfterApprovalStep.skip || validationRequest.stato === PeriodoRegistroAttivitaStatus.APPROVATO) ? FlowTimelineStepState.COMPLETED : (
            (validationRequest.stato === PeriodoRegistroAttivitaStatus.RIFIUTATO && validationRequest.dataPrimaApprovazione && !validationRequest.dataSecondaApprovazione)
                ? FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: validationRequest.secondoApprovatore ? (validationRequest.secondoApprovatore?.cognome + ' ' + validationRequest.secondoApprovatore?.nome) : undefined,
        date: validationRequest.dataSecondaApprovazione ? moment(validationRequest.dataSecondaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    // overriding user, date and profile if refused state
    coordStep.user = coordStep.state === FlowTimelineStepState.REFUSED ? (validationRequest.utenteRifiuto?.cognome + ' ' + validationRequest.utenteRifiuto?.nome) : coordStep.user;
    coordStep.date = coordStep.state === FlowTimelineStepState.REFUSED ? moment(validationRequest.dataRifiuto).format('DD/MM/YYYY HH:mm') : coordStep.date;
    coordStep.profile = coordStep.state === FlowTimelineStepState.REFUSED ? validationRequest.sottoruoloUtenteRifiuto : validationRequest.sottoruoloSecondoApprovatore;

    const supStep: FlowTimelineData = {
        // step SUPERVISOR is considered completed if:
        // - the next state is completed (error step not skipped in this case)
        // - the state of the request is the completion for the step
        // step COORDINATOR is considered refused if:
        // - the state of the request is refused and there is no approval
        // (refusal was made after student request)
        state: ([FlowTimelineStepState.COMPLETED, FlowTimelineStepState.REFUSED].includes(coordStep.state) || validationRequest.stato === PeriodoRegistroAttivitaStatus.APPROVATOPARZIALE) ? FlowTimelineStepState.COMPLETED : (
            (validationRequest.stato === PeriodoRegistroAttivitaStatus.RIFIUTATO && !validationRequest.dataPrimaApprovazione && !validationRequest.dataSecondaApprovazione)
                ? FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: validationRequest.primoApprovatore ? (validationRequest.primoApprovatore?.cognome + ' ' + validationRequest.primoApprovatore?.nome) : undefined,
        date: validationRequest.dataPrimaApprovazione ? moment(validationRequest.dataPrimaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    // overriding user, date and profile if refused state
    supStep.user = supStep.state === FlowTimelineStepState.REFUSED ? (validationRequest.utenteRifiuto?.cognome + ' ' + validationRequest.utenteRifiuto?.nome) : supStep.user;
    supStep.date = supStep.state === FlowTimelineStepState.REFUSED ? moment(validationRequest.dataRifiuto).format('DD/MM/YYYY HH:mm') : supStep.date;
    supStep.profile = supStep.state === FlowTimelineStepState.REFUSED ? validationRequest.sottoruoloUtenteRifiuto : validationRequest.sottoruoloPrimoApprovatore;

    const studentStep: FlowTimelineData = {
        // student step is always completed
        state: FlowTimelineStepState.CREATED,
        user: validationRequest.studenteCiclo?.utente ? (validationRequest.studenteCiclo?.utente?.cognome + ' ' + validationRequest.studenteCiclo?.utente?.nome) : undefined,
        date: moment(validationRequest.dataApprovazioneDottorando ?? validationRequest.dataRichiesta).format('DD/MM/YYYY HH:mm'),
    };

    // skip supervisor step if step is completed but no info about the user
    supStep.skip = (supStep.state === FlowTimelineStepState.COMPLETED && !supStep.user);
    // skip step if step is completed but no info about the user OR it is after a refuse
    coordStep.skip = (coordStep.state === FlowTimelineStepState.COMPLETED && !coordStep.user) || supStep.state === FlowTimelineStepState.REFUSED;

    return [
        studentStep,
        supStep,
        coordStep,
        errorAfterApprovalStep
    ];
}


// MISSIONS (todo refactor: refusal info are in dataPrimaApprovazione/dataSecondaApprovazione)

export function buildFlowTimelineConfigurationForMission() {
    // this is the possible step skeleton for the flow
    return {
        steps: [
            {
                labelPending: '',
                labelCompleted: 'student.student_request',
                labelRefused: '',
            },
            {
                // in attesa approvazione sup
                labelPending: 'missions.status.requested',
                labelCompleted: 'budget.approved',
                labelRefused: 'budget.refused',
            },
            {
                // in attesa approvazione coord
                labelPending: 'missions.status.approved_by_supervisor',
                labelCompleted: 'budget.approved',
                labelRefused: 'budget.refused',
            },
            {
                // in attesa approvazione dir dip
                labelPending: 'missions.status.approved_coordinator',
                labelCompleted: 'missions.status.approved_by_depdir_present',
                labelRefused: 'budget.refused',
            },
            {
                // in attesa liquidazione pta
                labelPending: 'missions.status.payment_requested',
                labelCompleted: 'missions.status.payed',
                labelRefused: 'budget.refused',
            },
        ]
    };
}


export function buildFlowTimelineDataForMission(missionDetail: MissioneInfoView): FlowTimelineData[] {
    const liqStep: FlowTimelineData = {
        // pta
        state: [
            MissioneStatus.LIQUIDATA
        ].includes(missionDetail.stato) ? FlowTimelineStepState.COMPLETED : (
            (missionDetail.stato === MissioneStatus.RICHIESTALIQUIDAZIONERIFIUTATA)
                ? FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
    };

    const depDirStep: FlowTimelineData = {
        // dep dir
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(liqStep.state) || [
            MissioneStatus.APPROVATADIRETTOREDIDIPARTIMENTO, MissioneStatus.RICHIESTALIQUIDAZIONE,
        ].includes(missionDetail.stato)) ? FlowTimelineStepState.COMPLETED : (
            (missionDetail.stato === MissioneStatus.RIFIUTATA && missionDetail.dataPrimaApprovazione && missionDetail.dataSecondaApprovazione && !missionDetail.dataApprovazioneDirettoreDiDipartimento)
                ? FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: missionDetail.approvatoreDirettoreDiDipartimento ? (missionDetail.approvatoreDirettoreDiDipartimento?.cognome + ' ' + missionDetail.approvatoreDirettoreDiDipartimento?.nome) : undefined,
        date: missionDetail.dataApprovazioneDirettoreDiDipartimento ? moment(missionDetail.dataApprovazioneDirettoreDiDipartimento).format('DD/MM/YYYY HH:mm') : undefined,
    };
    // overriding user, date and profile if refused state
    depDirStep.user = depDirStep.state === FlowTimelineStepState.REFUSED ? (missionDetail.utenteRifiuto?.cognome + ' ' + missionDetail.utenteRifiuto?.nome) : depDirStep.user;
    depDirStep.date = depDirStep.state === FlowTimelineStepState.REFUSED ? moment(missionDetail.dataRifiuto).format('DD/MM/YYYY HH:mm') : depDirStep.date;
    depDirStep.profile = depDirStep.state === FlowTimelineStepState.REFUSED ? missionDetail.sottoruoloUtenteRifiuto : undefined;

    const coordStep: FlowTimelineData = {
        // coord
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(depDirStep.state) || missionDetail.stato === MissioneStatus.APPROVATA) ? FlowTimelineStepState.COMPLETED : (
            (missionDetail.stato === MissioneStatus.RIFIUTATA && missionDetail.dataPrimaApprovazione && !missionDetail.dataSecondaApprovazione && !missionDetail.dataApprovazioneDirettoreDiDipartimento) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: missionDetail.secondoApprovatore ? (missionDetail.secondoApprovatore?.cognome + ' ' + missionDetail.secondoApprovatore?.nome) : undefined,
        date: missionDetail.dataSecondaApprovazione ? moment(missionDetail.dataSecondaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };    // overriding user, date and profile if refused state
    coordStep.user = coordStep.state === FlowTimelineStepState.REFUSED ? (missionDetail.utenteRifiuto?.cognome + ' ' + missionDetail.utenteRifiuto?.nome) : coordStep.user;
    coordStep.date = coordStep.state === FlowTimelineStepState.REFUSED ? moment(missionDetail.dataRifiuto).format('DD/MM/YYYY HH:mm') : coordStep.date;
    coordStep.profile = coordStep.state === FlowTimelineStepState.REFUSED ? missionDetail.sottoruoloUtenteRifiuto : missionDetail.sottoruoloSecondoApprovatore;

    const supStep: FlowTimelineData = {
        // sup
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(coordStep.state) || missionDetail.stato === MissioneStatus.APPROVATAPARZIALE) ? FlowTimelineStepState.COMPLETED : (
            (missionDetail.stato === MissioneStatus.RIFIUTATA && !missionDetail.dataPrimaApprovazione && !missionDetail.dataSecondaApprovazione && !missionDetail.dataApprovazioneDirettoreDiDipartimento) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: missionDetail.primoApprovatore ? (missionDetail.primoApprovatore?.cognome + ' ' + missionDetail.primoApprovatore?.nome) : undefined,
        date: missionDetail.dataPrimaApprovazione ? moment(missionDetail.dataPrimaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    // overriding user, date and profile if refused state
    supStep.user = supStep.state === FlowTimelineStepState.REFUSED ? (missionDetail.utenteRifiuto?.cognome + ' ' + missionDetail.utenteRifiuto?.nome) : supStep.user;
    supStep.date = supStep.state === FlowTimelineStepState.REFUSED ? moment(missionDetail.dataRifiuto).format('DD/MM/YYYY HH:mm') : supStep.date;
    supStep.profile = supStep.state === FlowTimelineStepState.REFUSED ? missionDetail.sottoruoloUtenteRifiuto : missionDetail.sottoruoloPrimoApprovatore;

    const studentStep: FlowTimelineData = {
        // student step is always completed
        state: FlowTimelineStepState.CREATED,
    };

    // skip step if step is completed but no info about the user OR it is after a refuse
    liqStep.skip = ![MissioneStatus.RICHIESTALIQUIDAZIONERIFIUTATA, MissioneStatus.RICHIESTALIQUIDAZIONE, MissioneStatus.LIQUIDATA].includes(missionDetail.stato);
    depDirStep.skip = depDirStep.state === FlowTimelineStepState.COMPLETED && !depDirStep.user || coordStep.state === FlowTimelineStepState.REFUSED || supStep.state === FlowTimelineStepState.REFUSED;
    coordStep.skip = coordStep.state === FlowTimelineStepState.COMPLETED && !coordStep.user || supStep.state === FlowTimelineStepState.REFUSED;
    supStep.skip = (supStep.state === FlowTimelineStepState.COMPLETED && !supStep.user);
    return [
        studentStep,
        supStep,
        coordStep,
        depDirStep,
        liqStep,
    ];
}


// ESTERNI INVITATI

export function buildFlowTimelineConfigurationForEsterniInvitati(isAreaAdministrator: boolean,
                                                                 modalitaGestioneEsterni: ModalitaGestioneUtentiEsterni,
                                                                 moderazioneAttiva: boolean): FlowTimelineConfiguration {
    // this is the possible step skeleton for the flow
    return {
        legendStateType: modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.IDPATENEO ?
            (isAreaAdministrator ? OnboardingEsternoStatusForAmm : OnboardingEsternoStatus) :
                OnboardingEsternoStatusForKeycloakMode,
        steps: [
            {
                stateForLegend: OnboardingEsternoStatus.INVITATO,
                description: 'users_list.status_descriptions.invited',
            },
            {
                stateForLegend: (modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.KEYCLOAK && !moderazioneAttiva) ? OnboardingEsternoStatusForKeycloakMode.SINCRONIZZATO : OnboardingEsternoStatus.DASINCRONIZZARE,
                description: modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.IDPATENEO ?
                    'users_list.status_descriptions.to_synchronize_idp' : (
                        (modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.KEYCLOAK && !moderazioneAttiva)
                        ? 'users_list.status_descriptions.confirmed' : 'users_list.status_descriptions.to_synchronize_keycloak'),
                alternativeSteps: [
                    {
                        stateForLegend: OnboardingEsternoStatus.GIAPRESENTE,
                        description: 'users_list.status_descriptions.already_in_platform'
                    },
                ]
            },
            ...((modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.KEYCLOAK && !moderazioneAttiva) ? [] : [{
                stateForLegend: modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.IDPATENEO ?
                    (isAreaAdministrator ? OnboardingEsternoStatusForAmm.SINCRONIZZATO : OnboardingEsternoStatus.SINCRONIZZATO) :
                    OnboardingEsternoStatusForKeycloakMode.SINCRONIZZATO,
                description: modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.IDPATENEO ?
                    (isAreaAdministrator ? 'users_list.status_descriptions.synchronized' : 'users_list.status_descriptions.synchronized_gestionale') :
                    'users_list.status_descriptions.confirmed',
                alternativeSteps: (modalitaGestioneEsterni === ModalitaGestioneUtentiEsterni.KEYCLOAK) ? [
                    {
                        stateForLegend: OnboardingEsternoStatusForKeycloakMode.RIFIUTATO,
                        description: 'users_list.status_descriptions.refused'
                    },
                ] : []
            }]),
        ],
        data: [
            {
                state: FlowTimelineStepState.STATE_LEGEND,
            },
            {
                state: FlowTimelineStepState.STATE_LEGEND,
            },
            {
                state: FlowTimelineStepState.STATE_LEGEND,
            }
        ],
    };
}


// SPESE (todo refactor: refusal info are in dataPrimaApprovazione/dataSecondaApprovazione)

export function buildFlowTimelineConfigurationForSpese() {
    // this is the possible step skeleton for the flow
    return {
        steps: [
            {
                labelPending: '',
                labelCompleted: 'student.student_request',
                labelRefused: '',
            },
            {
                // in attesa approvazione sup
                labelPending: 'missions.status.requested',
                labelCompleted: 'missions.status.approved_by_supervisor_present',
                labelRefused: 'missions.status.refused_by_supervisor',
            },
            {
                // in attesa approvazione coord
                labelPending: 'missions.status.approved_by_supervisor',
                labelCompleted: 'missions.status.approved_by_coordinator_present',
                labelRefused: 'missions.status.refused_by_coordinator',
            },
            {
                // in attesa approvazione pta
                labelPending: 'budget.approved_coordinator',
                labelCompleted: 'budget.approved_pta',
                labelRefused: 'budget.refused_by_pta',
            },
        ]
    };
}


export function buildFlowTimelineDataForSpese(spesa: SpesaStudenteInfoView): FlowTimelineData[] {
     const ptaStep: FlowTimelineData = {
        // pta
        state: [
            SpesaStudenteStatus.APPROVATOPTA
        ].includes(spesa.stato) ? FlowTimelineStepState.COMPLETED : (
            (spesa.stato === SpesaStudenteStatus.RIFIUTATO && spesa.dataTerzaApprovazione)
                ? FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: spesa.terzoApprovatore ? (spesa.terzoApprovatore?.cognome + ' ' + spesa.terzoApprovatore?.nome) : undefined,
        date: spesa.dataTerzaApprovazione ? moment(spesa.dataTerzaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    const coordStep: FlowTimelineData = {
        // coord
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(ptaStep.state) ||spesa.stato === SpesaStudenteStatus.APPROVATO) ? FlowTimelineStepState.COMPLETED : (
            (spesa.stato === SpesaStudenteStatus.RIFIUTATO && !spesa.terzoApprovatore && spesa.dataSecondaApprovazione) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: spesa.secondoApprovatore ? (spesa.secondoApprovatore?.cognome + ' ' + spesa.secondoApprovatore?.nome) : undefined,
        date: spesa.dataSecondaApprovazione ? moment(spesa.dataSecondaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    //todo for each step when using new refusal info scheme: overriding user, date and profile if refused state

    const supStep: FlowTimelineData = {
        // sup
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(coordStep.state) || spesa.stato === SpesaStudenteStatus.APPROVATOPARZIALE) ? FlowTimelineStepState.COMPLETED : (
            (spesa.stato === SpesaStudenteStatus.RIFIUTATO && !spesa.terzoApprovatore && !spesa.dataSecondaApprovazione && spesa.dataPrimaApprovazione) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: spesa.primoApprovatore ? (spesa.primoApprovatore?.cognome + ' ' + spesa.primoApprovatore?.nome) : undefined,
        date: spesa.dataPrimaApprovazione ? moment(spesa.dataPrimaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    const studentStep: FlowTimelineData = {
        // student step is always completed
        state: FlowTimelineStepState.CREATED,
        date: moment(spesa.dataRichiesta).format('DD/MM/YYYY HH:mm'),
    };

    // skip step if step is completed but no info about the user OR it is after a refuse
    ptaStep.skip = coordStep.state === FlowTimelineStepState.REFUSED || supStep.state === FlowTimelineStepState.REFUSED;
    coordStep.skip = coordStep.state === FlowTimelineStepState.COMPLETED && !coordStep.user || supStep.state === FlowTimelineStepState.REFUSED;
    supStep.skip = (supStep.state === FlowTimelineStepState.COMPLETED && !supStep.user);
    return [
        studentStep,
        supStep,
        coordStep,
        ptaStep
    ];
}



// MOBILITA (todo refactor: refusal info are in dataPrimaApprovazione/dataSecondaApprovazione)

export function buildFlowTimelineConfigurationForMobilitaRicerca() {
    // this is the possible step skeleton for the flow
    return {
        steps: [
            {
                labelPending: '',
                labelCompleted: 'student.student_request',
                labelRefused: '',
            },
            {
                labelPending: 'study_plan_status.to_be_approved',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: 'student.approved_by_supervisor',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: 'mobility.status.conclusion_request_pending_supervisor',
                labelCompleted: 'mobility.status.conclusion_request_approved',
                labelRefused: 'mobility.status.conclusion_request_approved',
            },
            {
                labelPending: 'mobility.status.conclusion_request_pending_coordinator',
                labelCompleted: 'mobility.status.conclusion_request_approved',
                labelRefused: 'mobility.status.conclusion_request_approved',
            },
        ]
    };
}


export function buildFlowTimelineDataForMobilitaRicerca(periodo: PeriodoDiMobilitaStudenteInfoView): FlowTimelineData[] {
    const conclusionCoordStep: FlowTimelineData = {
        // coord
        state: (periodo.stato === PeriodoDiMobilitaStudenteStatus.CONCLUSO) ? FlowTimelineStepState.COMPLETED : (
            (periodo.stato === PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONERIFIUTATA/* && periodo.dataApprovazioneParzialeConclusione && !periodo.dataApprovazioneConclusione*/) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: periodo.utenteConclusione ? (periodo.utenteConclusione?.cognome + ' ' + periodo.utenteConclusione?.nome) : undefined,
        profile: periodo.sottoruoloUtenteConclusione,
        //todo date: periodo.dataConclusione ? moment(periodo.dataConclusione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    //todo for each step when using new refusal info scheme: overriding user, date and profile if refused state

    const conclusionSupStep: FlowTimelineData = {
        // coord
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(conclusionCoordStep.state) || periodo.stato === PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONEAPPROVATAPARZIALMENTE) ? FlowTimelineStepState.COMPLETED : (
            (periodo.stato === PeriodoDiMobilitaStudenteStatus.RICHIESTACONCLUSIONERIFIUTATA/* && !periodo.dataApprovazioneParzialeConclusione && !periodo.dataApprovazioneConclusione*/) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: periodo.utenteConclusioneParziale ? (periodo.utenteConclusioneParziale?.cognome + ' ' + periodo.utenteConclusioneParziale?.nome) : undefined,
        profile: periodo.sottoruoloUtenteConclusioneParziale,
        //todo date: periodo.dataConclusioneParziale ? moment(periodo.dataConclusioneParziale).format('DD/MM/YYYY HH:mm') : undefined,
    };
    const coordStep: FlowTimelineData = {
        // coord
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(conclusionSupStep.state) || periodo.stato === PeriodoDiMobilitaStudenteStatus.APPROVATO) ? FlowTimelineStepState.COMPLETED : (
            (periodo.stato === PeriodoDiMobilitaStudenteStatus.RIFIUTATO && !periodo.dataSecondaApprovazione && periodo.dataPrimaApprovazione) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: periodo.secondoApprovatore ? (periodo.secondoApprovatore?.cognome + ' ' + periodo.secondoApprovatore?.nome) : undefined,
        profile: periodo.sottoruoloSecondoApprovatore,
        date: periodo.dataSecondaApprovazione ? moment(periodo.dataSecondaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    const supStep: FlowTimelineData = {
        // sup
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(coordStep.state) || periodo.stato === PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE) ? FlowTimelineStepState.COMPLETED : (
            (periodo.stato === PeriodoDiMobilitaStudenteStatus.RIFIUTATO && !periodo.dataSecondaApprovazione && !periodo.dataPrimaApprovazione) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: periodo.primoApprovatore ? (periodo.primoApprovatore?.cognome + ' ' + periodo.primoApprovatore?.nome) : undefined,
        profile: periodo.sottoruoloPrimoApprovatore,
        date: periodo.dataPrimaApprovazione ? moment(periodo.dataPrimaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    const studentStep: FlowTimelineData = {
        // student step is always completed
        state: FlowTimelineStepState.CREATED,
        user: periodo.studenteCiclo?.utente ? (periodo.studenteCiclo?.utente?.cognome + ' ' + periodo.studenteCiclo?.utente?.nome) : undefined,
        date: moment(periodo.dataRichiesta).format('DD/MM/YYYY HH:mm'),
    };

    // skip step if step is completed but no info about the user OR it is after a refuse
    conclusionCoordStep.skip = conclusionCoordStep.state === FlowTimelineStepState.COMPLETED && !conclusionCoordStep.user || coordStep.state === FlowTimelineStepState.REFUSED || supStep.state === FlowTimelineStepState.REFUSED || conclusionSupStep.state === FlowTimelineStepState.REFUSED/*todo || !periodo?.dataRichiestaConclusione*/;
    conclusionSupStep.skip = conclusionSupStep.state === FlowTimelineStepState.COMPLETED && !conclusionSupStep.user || coordStep.state === FlowTimelineStepState.REFUSED/*todo || !periodo?.dataRichiestaConclusione*/;
    coordStep.skip = coordStep.state === FlowTimelineStepState.COMPLETED && !coordStep.user || supStep.state === FlowTimelineStepState.REFUSED;
    supStep.skip = (supStep.state === FlowTimelineStepState.COMPLETED && !supStep.user);

    return [
        studentStep,
        supStep,
        coordStep,
        conclusionSupStep,
        conclusionCoordStep,
    ];
}


export function buildFlowTimelineConfigurationForMobilitaRicercaRetribuzione() {
    // this is the possible step skeleton for the flow
    return {
        steps: [
            {
                labelPending: '',
                labelCompleted: 'student.student_request',
                labelRefused: '',
            },
            {
                labelPending: 'study_plan_status.to_be_approved',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: 'student.approved_by_supervisor',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: 'mobility.retribution_status_desc.approved',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
        ]
    };
}


export function buildFlowTimelineDataForMobilitaRicercaRetribuzione(periodo: RichiestaDiRetribuzioneMobilitaInfoView): FlowTimelineData[] {
    const ptaStep: FlowTimelineData = {
        // pta
        state: (periodo.stato === RichiestaDiRetribuzioneMobilitaStatus.VALIDATAPTA) ? FlowTimelineStepState.COMPLETED : (
            (periodo.stato === RichiestaDiRetribuzioneMobilitaStatus.RIFIUTATA && periodo.dataPrimaApprovazione && periodo.dataSecondaApprovazione && !periodo.dataValidazionePta) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        //todo missing user: periodo.utenteValidazionePta ? (periodo.utenteValidazionePta?.cognome + ' ' + periodo.utenteValidazionePta.utenteConclusione?.nome) : undefined,
        profile: periodo.sottoruoloValidatorePta,
        date: periodo.dataValidazionePta ? moment(periodo.dataValidazionePta).format('DD/MM/YYYY HH:mm') : undefined,
    };
    //todo for each step when using new refusal info scheme: overriding user, date and profile if refused state

    const coordStep: FlowTimelineData = {
        // coord
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(ptaStep.state) || periodo.stato === RichiestaDiRetribuzioneMobilitaStatus.APPROVATA) ? FlowTimelineStepState.COMPLETED : (
            (periodo.stato === RichiestaDiRetribuzioneMobilitaStatus.RIFIUTATA && periodo.dataPrimaApprovazione && !periodo.dataSecondaApprovazione && !periodo.dataValidazionePta) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        //todo missing user: periodo.secondoApprovatore ? (periodo.secondoApprovatore?.cognome + ' ' + periodo.secondoApprovatore?.nome) : undefined,
        profile: periodo.sottoruoloSecondoApprovatore,
        date: periodo.dataSecondaApprovazione ? moment(periodo.dataSecondaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    const supStep: FlowTimelineData = {
        // sup
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(coordStep.state) || periodo.stato === RichiestaDiRetribuzioneMobilitaStatus.APPROVATAPARZIALE) ? FlowTimelineStepState.COMPLETED : (
            (periodo.stato === RichiestaDiRetribuzioneMobilitaStatus.RIFIUTATA && !periodo.dataSecondaApprovazione && !periodo.dataPrimaApprovazione && !periodo.dataValidazionePta) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: periodo.primoApprovatore ? (periodo.primoApprovatore?.cognome + ' ' + periodo.primoApprovatore?.nome) : undefined,
        profile: periodo.sottoruoloPrimoApprovatore,
        date: periodo.dataPrimaApprovazione ? moment(periodo.dataPrimaApprovazione).format('DD/MM/YYYY HH:mm') : undefined,
    };
    const studentStep: FlowTimelineData = {
        // student step is always completed
        state: FlowTimelineStepState.CREATED,
        //todo missing user: periodo.studenteCiclo?.utente ? (periodo.studenteCiclo?.utente?.cognome + ' ' + periodo.studenteCiclo?.utente?.nome) : undefined,
        // todo missingdate: moment(periodo.dataRichiesta).format('DD/MM/YYYY HH:mm'),
    };

    // skip step if step is completed but no info about the user OR it is after a refuse
    ptaStep.skip = ptaStep.state === FlowTimelineStepState.COMPLETED && !ptaStep.user || coordStep.state === FlowTimelineStepState.REFUSED;
    coordStep.skip = coordStep.state === FlowTimelineStepState.COMPLETED && !coordStep.user || supStep.state === FlowTimelineStepState.REFUSED;
    supStep.skip = (supStep.state === FlowTimelineStepState.COMPLETED && !supStep.user);

    return [
        studentStep,
        supStep,
        coordStep,
        ptaStep,
    ];
}



// PIANO FORMATIVO AGGIORNAMENTO (todo refactor: refusal info are in dataPrimaApprovazione/dataSecondaApprovazione)

export function buildFlowTimelineConfigurationForPlan() {
    // this is the possible step skeleton for the flow
    return {
        steps: [
            {
                labelPending: '',
                labelCompleted: 'student.student_request',
                labelRefused: '',
            },
            {
                labelPending: 'study_plan_status.to_be_approved',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
            {
                labelPending: 'student.approved_by_supervisor',
                labelCompleted: 'student.approved',
                labelRefused: 'budget.refused',
            },
        ]
    };
}


export function buildFlowTimelineDataForPlan(piano: StudyPlanAggiornamentoDataUI): FlowTimelineData[] {

    //todo for each step when using new refusal info scheme: overriding user, date and profile if refused state

    const coordStep: FlowTimelineData = {
        // coord
        state: (piano.stato === AggiornamentoPianoDiStudiStatus.APPROVATO) ? FlowTimelineStepState.COMPLETED : (
            (piano.stato === AggiornamentoPianoDiStudiStatus.RIFIUTATO && piano.dataSecondaApprovazioneRaw) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: piano.secondoApprovatore ? (piano.secondoApprovatore?.cognome + ' ' + piano.secondoApprovatore?.nome) : undefined,
        profile: piano.sottoruoloSecondoApprovatore,
        date: piano.dataSecondaApprovazioneRaw ? moment(piano.dataSecondaApprovazioneRaw).format('DD/MM/YYYY HH:mm') : undefined,
        motivazioneRifiuto: piano.motivazioneRifiuto,
    };
    const supStep: FlowTimelineData = {
        // sup
        state: ([FlowTimelineStepState.REFUSED, FlowTimelineStepState.COMPLETED].includes(coordStep.state) || piano.stato === AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE) ? FlowTimelineStepState.COMPLETED : (
            (piano.stato === AggiornamentoPianoDiStudiStatus.RIFIUTATO) ?
                FlowTimelineStepState.REFUSED : FlowTimelineStepState.PENDING),
        user: piano.primoApprovatore ? (piano.primoApprovatore?.cognome + ' ' + piano.primoApprovatore?.nome) : undefined,
        profile: piano.sottoruoloPrimoApprovatore,
        date: piano.dataPrimaApprovazioneRaw ? moment(piano.dataPrimaApprovazioneRaw).format('DD/MM/YYYY HH:mm') : undefined,
        motivazioneRifiuto: piano.motivazioneRifiuto,
    };
    const studentStep: FlowTimelineData = {
        // student step is always completed
        state: FlowTimelineStepState.CREATED,
        user: piano.studenteCiclo?.utente ? (piano.studenteCiclo?.utente?.cognome + ' ' + piano.studenteCiclo?.utente?.nome) : undefined,
        date: moment(piano.dataRichiestaRaw).format('DD/MM/YYYY HH:mm'),
    };

    // skip step if step is completed but no info about the user OR it is after a refuse
    coordStep.skip = coordStep.state === FlowTimelineStepState.COMPLETED && !coordStep.user || supStep.state === FlowTimelineStepState.REFUSED;
    supStep.skip = (supStep.state === FlowTimelineStepState.COMPLETED && !supStep.user);

    return [
        studentStep,
        supStep,
        coordStep,
    ];
}
