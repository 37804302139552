import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {LocalStorageService} from './shared/service/local-storage.service';
import {NavigationService} from './core/navigation/navigation.service';
import {FuseConfirmationService} from '../@fuse/services/confirmation';
import {Router} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {AppInitService} from './shared/service/app-init.service';
import {PathEnum} from './app.routing';
import {LogoutService} from './shared/service/logout.service';
import {catchError, finalize, forkJoin, map, of, switchMap, takeUntil, tap, throwError} from 'rxjs';
import {
    AuthorityType,
    CicloAbilitatoViewImpl,
    UserAmministrazioneInfoCicliDTO,
    UsersService
} from '../api-clients/generated/services';
import {HttpErrorResponse} from "@angular/common/http";
import {isPlatformBrowser} from "@angular/common";
import {environment} from "../environments/environment";
import {CicloConfigurationService} from "./shared/service/ciclo-configuration.service";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {


    /**
     * Constructor
     */
    constructor(private localStorageService: LocalStorageService,
                private navigationService: NavigationService,
                private fuseConfirmationService: FuseConfirmationService,
                private router: Router,
                private translocoService: TranslocoService,
                private appInitService: AppInitService,
                protected logoutService: LogoutService,
                private usersService: UsersService,
                protected domSanitizer: DomSanitizer,
                ) {


        if (!environment.production) {
            const metaTag: HTMLMetaElement = document.createElement('meta');
            metaTag.name = 'robots';
            metaTag.content = 'noindex';
            document.head.appendChild(metaTag);
        }

        this.translocoService.setActiveLang(this.localStorageService.getActiveLang() || 'it');

        if(!this.appInitService.isPrivacyOrTCSPaths()){
            this.navigationService.subNav();
            if (this.appInitService?.erroreGetAssociazioni && this.localStorageService.getAccessToken()) {
                this.router.navigate([PathEnum.HOME]);
                this.appInitService.erroreGetAssociazioni = false;
                setTimeout(() => this.gestioneErroreCicloCorsoRuoloRequest(), 200);
            }
        }
    }



    ngAfterViewInit(): void {
        if(!this.appInitService.isPrivacyOrTCSPaths()){
            if (this.appInitService?.listaRuoliCodiciDipartimenti?.length && this.localStorageService.getAccessToken()) {
                setTimeout(() => this.appInitService.checkIfIsPresentCicloCorsoRuoloInStorage(), 200);
            } else if (
                !this.appInitService?.erroreGetAssociazioni
                && !this.appInitService.isAmministratore
                && this.appInitService?.listaRuoliCodiciDipartimenti?.length === 0
                && this.localStorageService.getAccessToken()) {
                this.appInitService.openDialogRuoloNonAutorizzato();
            } else if(this.appInitService.isAmministratore){
                this.appInitService.goToAmministrazione();
                this.appInitService.checkCicliAmm();
            }
        }
    }

    gestioneErroreCicloCorsoRuoloRequest(): void {
        this.fuseConfirmationService.openErrorDialog(undefined, this.translocoService, () => this.goToLogin(), () => this.getUserRuoloCodiceDipartimentoAssociations(), 'dialog.cancel');
    }

    goToLogin(): void {
        this.localStorageService.cleanAllClassVariablesAndStorage();
        this.logoutService.logout();
        this.router.navigate([PathEnum.SIGN_IN]);
    }

    getUserRuoloCodiceDipartimentoAssociations(): void {
        this.fuseConfirmationService.showLoader();
        forkJoin([
            this.usersService.getCicliRuoliCorsoDiStudiAssociations(),
            this.usersService.getCicliCorsoDiStudiAmministrazione().pipe(
                catchError((error: HttpErrorResponse) => {
                        if (error?.status === 404) {
                            return of(null);
                        }
                        return throwError(() => error);
                    }
                ))
            ]
        ).pipe(
            switchMap(([associazioni, userAmministrazioneInfoCicli]: [Array<CicloAbilitatoViewImpl>, UserAmministrazioneInfoCicliDTO]) => {
                this.appInitService.isAmministratore = !!userAmministrazioneInfoCicli?.roles?.length;
                this.appInitService.userAmministrazioneInfoCicli = userAmministrazioneInfoCicli;
                const cicloCorsoRuoloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
                if(cicloCorsoRuoloInStorage?.ruolo === AuthorityType.DOCENTE || cicloCorsoRuoloInStorage?.ruolo === AuthorityType.PERSONALEPTA || cicloCorsoRuoloInStorage?.ruolo === AuthorityType.ESTERNO) {
                    this.appInitService.cicloCorsoRuoloForSottoruoliRequest$.next(cicloCorsoRuoloInStorage);
                    console.log('appcomponent.gestioneErroreCicloCorsoRuoloRequest() get sottoruoli')
                    return this.appInitService.getSottoruoliUtente$().pipe(
                        map(sottoruoli => associazioni)
                    )
                } else {
                    return of(associazioni);
                }
            }),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            }))
            .subscribe({
                next: (value) => {
                    // @ts-ignore
                    if (!value?.length) {
                        if (this.appInitService.isAmministratore) {
                            this.appInitService.verificaArea();
                        } else {
                            this.gestioneErroreCicloCorsoRuoloRequest();
                        }
                    } else {
                        this.appInitService.listaRuoliCodiciDipartimenti = value;
                        this.appInitService.listaRuoliCodiciDipartimenti$.next(value);
                        const dipartimentoRuoloCicloInStorage = this.localStorageService.getCicloCorsoRuolo(true);
                        if (dipartimentoRuoloCicloInStorage) {
                            const isValidDipartimentoRuoloCicloInStorage = this.appInitService.checkValidityCicloCorsoRuoloInStorage(dipartimentoRuoloCicloInStorage);
                            if (isValidDipartimentoRuoloCicloInStorage) {
                                this.appInitService.setCicloCorsoRuoloSelected$(dipartimentoRuoloCicloInStorage).subscribe({
                                    next: _ => {
                                        this.appInitService.cicloCorsoRuoloSelected = dipartimentoRuoloCicloInStorage;
                                        this.appInitService.prevalorizzaCicloCorsoRuolo();
                                    }
                                });
                            } else {
                                this.logoutService.logout();
                            }
                        } else {
                            this.appInitService.verificaArea();
                        }
                    }
                },
                error: () => {
                    this.gestioneErroreCicloCorsoRuoloRequest();
                }
            });
    }


}
