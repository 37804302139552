import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecaptchaComponent} from "./recaptcha.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {RECAPTCHA_LANGUAGE, RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
    declarations: [RecaptchaComponent],
    exports: [
        RecaptchaComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        TranslocoModule
    ],
    providers: [
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: "it",
        },
    ]
})
export class RecaptchaComponentModule {
}
