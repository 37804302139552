import {Component, InjectionToken, Injector, Input, OnInit, Pipe} from '@angular/core';
import {DialogInfoI} from '../../../shared/components/dialog-info/dialog-info.component';
import {FormControl} from '@angular/forms';
import {ComponentPortal} from "@angular/cdk/portal";
import {StudenteCicloMotivoStatus, StudenteCicloStatus} from "../../../../api-clients/generated/services";

export const INFO_BOTTOM_COMPONENT_DATA = new InjectionToken<{}>('INFO_BOTTOM_COMPONENT_DATA');
export const INFO_TOP_COMPONENT_DATA = new InjectionToken<{}>('INFO_TOP_COMPONENT_DATA');


@Component({
    selector: 'app-info-wrap',
    templateUrl: './info-wrap.component.html',
    styleUrls: ['./info-wrap.component.scss']
})
export class InfoWrapComponent implements OnInit {
    @Input() info: { key: string; value: any; style?: string ; hide?: boolean; pipe?: Pipe, showVisualizzaButton?: boolean,
        buttonAction?: () => void; subValue?: any; subLabel?: string;}[];
    @Input() colGridClass: string;
    @Input() data: DialogInfoI = {};
    @Input() ctrlUrlFoto: FormControl = new FormControl(null);

    portalBottom: ComponentPortal<any>;
    portalTop: ComponentPortal<any>;

    infoNotHidden: { key: string; value: any; style?: string ; hide?: boolean; pipe?: Pipe, showVisualizzaButton?: boolean,
        buttonAction?: () => void; subValue?: any; subLabel?: string;}[];

    constructor(private injector: Injector,) {
    }

    ngOnInit() {
        this.infoNotHidden = this.info?.filter(i => !i.hide);
        if(this.data.bottomComponent) {
            this.portalBottom = new ComponentPortal(this.data.bottomComponent, null, this.createInjectorBottom(this.data.bottomComponentData));
        }
        if(this.data.topComponent) {
            this.portalTop = new ComponentPortal(this.data.topComponent, null, this.createInjectorTop(this.data.topComponentData));
        }
    }

    createInjectorBottom(dataToPass): Injector {
        // DEPRECATED
        //const injectorTokens = new WeakMap();
        //injectorTokens.set(INFO_BOTTOM_COMPONENT_DATA, dataToPass);
        //return new PortalInjector(this.injector, injectorTokens);
        return Injector.create({
            parent: this.injector,
            providers: [
                { provide: INFO_BOTTOM_COMPONENT_DATA, useValue: dataToPass }
            ]
        });
    }

    createInjectorTop(dataToPass): Injector {
        // DEPRECATED
        //const injectorTokens = new WeakMap();
        //injectorTokens.set(INFO_BOTTOM_COMPONENT_DATA, dataToPass);
        //return new PortalInjector(this.injector, injectorTokens);
        return Injector.create({
            parent: this.injector,
            providers: [
                { provide: INFO_TOP_COMPONENT_DATA, useValue: dataToPass }
            ]
        });
    }

    protected readonly StudenteCicloMotivoStatus = StudenteCicloMotivoStatus;
    protected readonly StudenteCicloStatus = StudenteCicloStatus;
}
