import {Injectable} from '@angular/core';

import jwt_decode from 'jwt-decode';
import {TokenResponse} from './saml-auth.service';
import {AuthorityType, UserView} from "../../../api-clients/generated/services";
import {CicloCorsoRuoloInterface} from '../interface/CicloCorsoRuoloInterface';
import {Lang} from '../../layout/common/languages/languages.component';
import {BehaviorSubject} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";

export const APP_DEFAULT_PAGE_SIZE = 10;

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    tokenResponse: TokenResponse | undefined;
    dipartimentoRuoloCiclo: CicloCorsoRuoloInterface;
    accessToken: string;
    refreshToken: string;
    profile: UserView;
    lang = Object.values(Lang);
    checkCurrentProfile$ = new BehaviorSubject(this.getProfileResponse());
    checkCurrentSottoRuoloSelected$: BehaviorSubject<AuthorityType | undefined> = new BehaviorSubject(this.getSottoruoloCiclo());
    cicloAmm: number;
    constructor(private matDialog: MatDialog) {

    }

    setTokenResponse(tokens: { access_token: string; refresh_token: string }): void {
        // localStorage.setItem(StorageProperties.TOKEN_RESPONSE, JSON.stringify(tokenResponse));
        localStorage.setItem(StorageProperties.ACCESS_TOKEN, tokens.access_token);
        this.accessToken = tokens.access_token;
        localStorage.setItem(StorageProperties.REFRESH_TOKEN, tokens.refresh_token);
        this.refreshToken = tokens.refresh_token;
    }

    removeTokenResponse(): void {
        // localStorage.removeItem(StorageProperties.TOKEN_RESPONSE);
        localStorage.removeItem(StorageProperties.ACCESS_TOKEN);
        localStorage.removeItem(StorageProperties.REFRESH_TOKEN);
        this.accessToken = undefined;
        this.refreshToken = undefined;
    }

    getAccessToken(fromStorage?: boolean): string {
        if(fromStorage) {
            return localStorage.getItem(StorageProperties.ACCESS_TOKEN);
        }
        return this.accessToken;
    }

    getRefreshToken(fromStorage?: boolean): string {
        if(fromStorage) {
            return localStorage.getItem(StorageProperties.REFRESH_TOKEN);
        }
        return this.refreshToken;
    }

    decodeToken(): TokenI {
        const token = this.getAccessToken();
        if (token) {
            return jwt_decode(token);
        }
        return null;
    }

    get idStudenteByProfile(): string{
        return this.profile?.id;
    }

    isAdmin(): boolean {
        return this.decodeToken().realm_access.roles.includes(UserRolesEnum.ADMIN);
    }

    setDipartimentoRuoloCiclo(dipartimentoRuoloCiclo: CicloCorsoRuoloInterface): void{
        this.dipartimentoRuoloCiclo = dipartimentoRuoloCiclo;
        if(dipartimentoRuoloCiclo) {
            localStorage.setItem(StorageProperties.DIPARTIMENTO_RUOLO_CICLO, JSON.stringify(dipartimentoRuoloCiclo));
            this.setSottoruoloCiclo(dipartimentoRuoloCiclo?.sottoruolo);
        } else {
            localStorage.removeItem(StorageProperties.DIPARTIMENTO_RUOLO_CICLO);
        }
    }

    setCicloAmm(ciclo: number): void{
        this.cicloAmm = ciclo;
        localStorage.setItem(StorageProperties.CICLO_AMM, JSON.stringify(ciclo));
    }

    // occorre distinguere se la tripla ciclo-Corso-Ruolo vada presa dallo storage o meno
    // getItem dallo storage (fromStorage = true) solo nel caso di init/refresh dell'applicativo
    getCicloCorsoRuolo(fromStorage?: boolean): CicloCorsoRuoloInterface{
        if(!!fromStorage) {
            return JSON.parse(localStorage.getItem(StorageProperties.DIPARTIMENTO_RUOLO_CICLO));
        } else {
            return this.dipartimentoRuoloCiclo;
        }
    }


    getCicloAmm(fromStorage?: boolean): number{
        if(!!fromStorage) {
            return JSON.parse(localStorage.getItem(StorageProperties.CICLO_AMM));
        } else {
            return this.cicloAmm;
        }
    }


    removeDipartimentoRuoloCiclo(): void {
        localStorage.removeItem(StorageProperties.DIPARTIMENTO_RUOLO_CICLO);
        this.dipartimentoRuoloCiclo = undefined;
    }

    setProfileResponse(profile: UserView): void {
        localStorage.setItem(StorageProperties.PROFILE, JSON.stringify(profile));
        this.profile = profile;
        this.checkCurrentProfile$.next(profile);
    }
    getProfileResponse(): UserView {
        return JSON.parse(localStorage.getItem(StorageProperties.PROFILE));
    }

    removeProfileResponse(): void {
        localStorage.removeItem(StorageProperties.PROFILE);
        this.profile = undefined;
        this.checkCurrentProfile$.next(undefined);
    }
    cleanAllClassVariablesAndStorage(): void {
        this.removeProfileResponse();
        this.removeTokenResponse();
        this.removeDipartimentoRuoloCiclo();
        this.removeAmministrazione();
        this.matDialog?.closeAll();
    }

    getActiveLang(): string {
        const ln = localStorage.getItem(StorageProperties.ACTIVELANG) as Lang;
        return ln && this.lang.includes(ln) ? localStorage.getItem(StorageProperties.ACTIVELANG) : Lang.it;
    }

    setActiveLang(activeLang: string):void {
        localStorage.setItem(StorageProperties.ACTIVELANG, activeLang);
    }

    setSottoruoloCiclo(sottoruolo: AuthorityType): void{
        this.dipartimentoRuoloCiclo.sottoruolo = sottoruolo;
        localStorage.setItem(StorageProperties.DIPARTIMENTO_RUOLO_CICLO, JSON.stringify(this.dipartimentoRuoloCiclo));
        this.checkCurrentSottoRuoloSelected$.next(sottoruolo);
    }

    resetSottoruolo(){
        localStorage.removeItem(StorageProperties.DIPARTIMENTO_RUOLO_CICLO)
    }

    getSottoruoloCiclo(fromStorage?: boolean): AuthorityType {
        if(fromStorage) {
            return JSON.parse(localStorage.getItem(StorageProperties.DIPARTIMENTO_RUOLO_CICLO));
        }
        return this.dipartimentoRuoloCiclo?.sottoruolo;
    }

    enqueueError(errorStringifiedObject: any) {
        // getting the current queue from the local storage
        const currentQueueJSON = localStorage.getItem(StorageProperties.ERRORS_QUEUE);
        // if there is no queue set as empty
        let currentQueue = !!currentQueueJSON ? JSON.parse(currentQueueJSON) as Array<any> : [];
        // pushing the error
        currentQueue.push(errorStringifiedObject);
        // taking only the last errorsQueueCapacity errors
        if (currentQueue.length > environment.errorsQueueCapacity){
            currentQueue = currentQueue.slice(1)
        }
        // setting the local storage with the new queue
        localStorage.setItem(StorageProperties.ERRORS_QUEUE, JSON.stringify(currentQueue))
    }

    getErrors() {
        return JSON.parse(localStorage.getItem(StorageProperties.ERRORS_QUEUE));
    }

    private removeAmministrazione() {
        localStorage.removeItem(StorageProperties.CICLO_AMM);
        this.cicloAmm = undefined;
    }

    public get loggedInWithInternalEsterniClient(): boolean {
        return localStorage.getItem(StorageProperties.LOGGED_IN_WITH_INTERNAL_ESTERNI_CLIENT) == 'true';
    }

    public set loggedInWithInternalEsterniClient(value: boolean) {
        localStorage.setItem(StorageProperties.LOGGED_IN_WITH_INTERNAL_ESTERNI_CLIENT, ''+value);
    }

    public get pageSize(): number {
        return +(localStorage.getItem(StorageProperties.TABLES_PAGE_SIZE) ?? APP_DEFAULT_PAGE_SIZE);
    }

    public set pageSize(pageSize: number){
        if(!pageSize)
            return; // value not valid
        localStorage.setItem(StorageProperties.TABLES_PAGE_SIZE, ''+pageSize)
    };

}

export enum StorageProperties {
    // TOKEN_RESPONSE = 'TOKEN_RESPONSE',
    ERRORS_QUEUE = 'ERRORS_QUEUE',
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    DIPARTIMENTO_RUOLO_CICLO= 'DIPARTIMENTO_RUOLO_CICLO',
    PROFILE = 'PROFILE',
    ACTIVELANG = 'ACTIVELANG',
    CICLO_AMM= 'CICLO_AMM',
    TABLES_PAGE_SIZE= 'TABLES_PAGE_SIZE',
    LOGGED_IN_WITH_INTERNAL_ESTERNI_CLIENT= 'LOGGED_IN_WITH_INTERNAL_ESTERNI_CLIENT',
}

export enum UserRolesEnum {
    ADMIN = 'admin',
}

export interface TokenI {
    realm_access?: RealmAccessI;
    sid?: string;
    exp?: number;
}

export interface RealmAccessI {
    roles: string[];
}
