/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi operazione
 */
export type ChiaveOperazioneEnum = 'richiesta_dottorando' | 'approvazione_dottorando' | 'verifica_supervisore' | 'approvazione_supervisore' | 'verifica_coordinatore' | 'attestazione_coordinatore' | 'approvazione_coordinatore' | 'preautorizzazione_pta' | 'autorizzazione_coordinatore' | 'richiesta_conclusione_dottorando' | 'approvazione_conclusione_supervisore' | 'approvazione_conclusione_coordinatore' | 'approvazione_pta' | 'richiesta_liquidazione';

export const ChiaveOperazioneEnum = {
    RichiestaDottorando: 'richiesta_dottorando' as ChiaveOperazioneEnum,
    ApprovazioneDottorando: 'approvazione_dottorando' as ChiaveOperazioneEnum,
    VerificaSupervisore: 'verifica_supervisore' as ChiaveOperazioneEnum,
    ApprovazioneSupervisore: 'approvazione_supervisore' as ChiaveOperazioneEnum,
    VerificaCoordinatore: 'verifica_coordinatore' as ChiaveOperazioneEnum,
    AttestazioneCoordinatore: 'attestazione_coordinatore' as ChiaveOperazioneEnum,
    ApprovazioneCoordinatore: 'approvazione_coordinatore' as ChiaveOperazioneEnum,
    PreautorizzazionePta: 'preautorizzazione_pta' as ChiaveOperazioneEnum,
    AutorizzazioneCoordinatore: 'autorizzazione_coordinatore' as ChiaveOperazioneEnum,
    RichiestaConclusioneDottorando: 'richiesta_conclusione_dottorando' as ChiaveOperazioneEnum,
    ApprovazioneConclusioneSupervisore: 'approvazione_conclusione_supervisore' as ChiaveOperazioneEnum,
    ApprovazioneConclusioneCoordinatore: 'approvazione_conclusione_coordinatore' as ChiaveOperazioneEnum,
    ApprovazionePta: 'approvazione_pta' as ChiaveOperazioneEnum,
    RichiestaLiquidazione: 'richiesta_liquidazione' as ChiaveOperazioneEnum
};