<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_spese_pta.hint_compile_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

            <div class="flex flex-col gap-4 mt-6 w-full mb-2" *ngIf="speseDetailsFormArray">
                <ng-container *ngFor="let spesa of speseSelected; let i = index" class="w-full">

                    <div class="flex flex-col gap-3 bg-gray-100 rounded-xl px-4 pb-4 pt-2">
                        <div class="font-semibold text-lg mt-3">
                            {{spesa?.codiceSpesa}}
                            ({{spesa?.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + spesa?.budgetStudente?.studenteCiclo?.utente?.nome}}, {{spesa?.annoRiferimento}} {{t('common.year')}})
                        </div>
                        <div class="w-full" *ngIf="speseDetailsFormArray.at(i) as form">

                            <div class="flex flex-col pt-2 pb-1 w-full" [formGroup]="form">

                                <!--IMPORTO SPESA-->
                                <mat-form-field appearance="outline" class="w-full mt-3 mat-button-datapicker add-mobilita-mat-form-field"
                                                *ngIf="form?.get('importoSpesa') as fieldCtrl">
                                    <mat-label><strong>{{t('budget.expenditure_amount')}}</strong></mat-label>
                                    <input matInput
                                           currencyMask
                                           [options]="{ prefix: '',  suffix: ' €',
                                                       allowNegative: false,
                                                       thousands: '.', decimal: ',', align: 'left' }"
                                           [placeholder]="t('budget.expenditure_amount')"
                                           [formControl]="fieldCtrl">
                                    <mat-error *ngIf="fieldCtrl.hasError('min')">
                                        {{t('budget.min_budget_error') + ' ' + ('(' + (0.1 | currencyEuro) + ')')}}
                                    </mat-error>
                                    <mat-error *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)"
                                               class="pb-2">
                                        {{t('form.required')}}
                                    </mat-error>
                                </mat-form-field>

<!--                                &lt;!&ndash; TABELLA RIEPILOGO &ndash;&gt;-->
<!--                                <app-spesa-resume-table class="mb-11 mt-4"-->
<!--                                                        [budgetAccordions]="data?.budgetAccordions">-->
<!--                                </app-spesa-resume-table>-->

                                <!-- TIPO FONDO -->
                                <mat-form-field appearance="outline" class="w-full add-mobilita-mat-form-field mat-button-datapicker"
                                                *ngIf="form.get('tipoFondoRiferimento') as fieldCtrl">
                                    <mat-label>{{t('missions.type_fondo')}}</mat-label>
                                    <mat-select formControlName='tipoFondoRiferimento'>
                                        <mat-option *ngFor="let tipo of getTipoFondo()" [value]="tipo">
                                            {{tipo | removeUnderscores}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="fieldCtrl.hasError('required')">
                                        {{t('form.required')}}
                                    </mat-error>
                                </mat-form-field>

                                <ng-container *ngIf="form?.get('tipoFondoRiferimento')?.value && form?.get('tipoFondoRiferimento')?.value !== TipoFondoDiRiferimento.BUDGETDOTTORANDO">

                                    <!--                <ng-container *ngIf="cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneFondi) === FunzionalitaStatus.ABILITATA">-->

                                    <!--                    <mat-form-field appearance="outline" class="w-full add-mobilita-mat-form-field mat-button-datapicker mt-2"-->
                                    <!--                                    *ngIf="form.get('fondoRiferimento') as fieldCtrl">-->
                                    <!--                        <mat-label>{{t('missions.fondo_rif')}}</mat-label>-->
                                    <!--                        <mat-select formControlName='fondoRiferimento'>-->
                                    <!--                            <mat-option *ngFor="let fondo of fondiList" [value]="fondo.id">-->
                                    <!--                                {{fondo.nome}} ({{fondo.nome}})-->
                                    <!--                            </mat-option>-->
                                    <!--                            <mat-option [value]="'ALTRO'">-->
                                    <!--                                {{t('common.budget')}}-->
                                    <!--                            </mat-option>-->
                                    <!--                        </mat-select>-->
                                    <!--                        <mat-error *ngIf="fieldCtrl.hasError('required')">-->
                                    <!--                            {{t('form.required')}}-->
                                    <!--                        </mat-error>-->
                                    <!--                    </mat-form-field>-->

                                    <!--                </ng-container>-->

                                    <!-- DESCRIZIONE FONDO -->
                                    <mat-form-field class="w-full mat-button-datapicker add-mobilita-mat-form-field" appearance="outline" *ngIf="form?.get('descrizioneFondo') as fieldCtrl">
                                        <mat-label>{{t('missions.fondo_description')}}</mat-label>
                                        <ng-container>
                                            <input
                                                matInput
                                                [formControlName]="'descrizioneFondo'">
                                        </ng-container>
                                    </mat-form-field>

                                </ng-container>

                                <!-- IMPORTI SPESA ANNI E ALTRI FONDI-->
                                <div class="grid grid-cols-1 lg:grid-cols-4 gap-2 mb-2" *ngIf="form?.get('tipoFondoRiferimento')?.value">
                                    <!--IMPORTO SPESA PRIMO ANNO-->
                                    <mat-form-field appearance="outline" class="w-full mb-2"
                                                    *ngIf="form?.get('importoSpesaPrimoAnno') as fieldCtrl">
                                        <mat-label><strong>{{t('budget.expenditure_amount_first')}}</strong></mat-label>
                                        <input matInput
                                               currencyMask
                                               [options]="{ prefix: '',  suffix: ' €',
                                                               allowNegative: false,
                                                               thousands: '.', decimal: ',', align: 'left' }"
                                               [placeholder]="t('budget.expenditure_amount')"
                                               [formControl]="fieldCtrl">
                                        <mat-hint *ngIf="fieldCtrl.disabled; else enabledHintTmplt">
                                            {{t('budget.student_budget_disabled')}}
                                        </mat-hint>
                                        <ng-template #enabledHintTmplt>
                                            <mat-hint>
                                                <div class="flex flex-col">
                                                    <span>{{t('common.budget_available_at')}} I {{t('common.year')}}: {{spesa.budgetStudente?.budgetResiduoPrimoAnno | currencyEuro}}</span>
                                                    <span>{{t('common.remaining')}}: {{max([0, (spesa.budgetStudente?.budgetResiduoPrimoAnno-getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.PRIMO))])?.toFixed(2) | currencyEuro}}</span>
                                                </div>
                                            </mat-hint>
                                        </ng-template>
                                        <mat-error *ngIf="fieldCtrl.hasError('max')">
                                            {{t('budget.residual_budget_error') + ' ' + ('(' + (max([0, (spesa.budgetStudente?.budgetResiduoPrimoAnno-getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.PRIMO))])?.toFixed(2) | currencyEuro) + ')')}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)"
                                            class="pb-2">
                                            {{t('form.required')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <!--IMPORTO SPESA SECONDO ANNO-->
                                    <mat-form-field appearance="outline" class="w-full mb-2"
                                                    *ngIf="form?.get('importoSpesaSecondoAnno') as fieldCtrl">
                                        <mat-label><strong>{{t('budget.expenditure_amount_second')}}</strong>
                                        </mat-label>
                                        <input matInput
                                               currencyMask
                                               [options]="{ prefix: '',  suffix: ' €',
                                                           allowNegative: false,
                                                           thousands: '.', decimal: ',', align: 'left' }"
                                               [placeholder]="t('budget.expenditure_amount')"
                                               [formControl]="fieldCtrl">
                                        <mat-hint *ngIf="fieldCtrl.disabled; else enabledHintTmplt">
                                            {{t('budget.student_budget_disabled')}}
                                        </mat-hint>
                                        <ng-template #enabledHintTmplt>
                                            <mat-hint>
                                                <div class="flex flex-col">
                                                    <span>{{t('common.budget_available_at')}} II {{t('common.year')}}: {{spesa.budgetStudente?.budgetSecondoAnno | currencyEuro}}</span>
                                                    <span>{{t('common.remaining')}}: {{max([0, (spesa.budgetStudente?.budgetSecondoAnno-getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.SECONDO))])?.toFixed(2) | currencyEuro}}</span>
                                                </div>
                                            </mat-hint>
                                        </ng-template>
                                        <mat-error *ngIf="fieldCtrl.hasError('max')">
                                            {{t('budget.residual_budget_error') + ' ' + ('(' + (max([0, (spesa.budgetStudente?.budgetResiduoSecondoAnno-getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.SECONDO))])?.toFixed(2) | currencyEuro) + ')')}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)"
                                            class="pb-2">
                                            {{t('form.required')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <!--IMPORTO SPESA TERZO ANNO-->
                                    <mat-form-field appearance="outline" class="w-full mb-2"
                                                    *ngIf="form?.get('importoSpesaTerzoAnno') as fieldCtrl">
                                        <mat-label><strong>{{t('budget.expenditure_amount_third')}}</strong></mat-label>
                                        <input matInput
                                               currencyMask
                                               [options]="{ prefix: '',  suffix: ' €',
                                                           allowNegative: false,
                                                           thousands: '.', decimal: ',', align: 'left' }"
                                               [placeholder]="t('budget.expenditure_amount')"
                                               [formControl]="fieldCtrl">
                                        <mat-hint *ngIf="fieldCtrl.disabled; else enabledHintTmplt">
                                            {{t('budget.student_budget_disabled')}}
                                        </mat-hint>
                                        <ng-template #enabledHintTmplt>
                                            <mat-hint>
                                                <div class="flex flex-col">
                                                    <span>{{t('common.budget_available_at')}} III {{t('common.year')}}: {{spesa.budgetStudente?.budgetTerzoAnno | currencyEuro}}</span>
                                                    <span>{{t('common.remaining')}}: {{max([0, (spesa.budgetStudente?.budgetTerzoAnno-getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.TERZO))])?.toFixed(2) | currencyEuro}}</span>
                                                </div>
                                            </mat-hint>
                                        </ng-template>
                                        <mat-error *ngIf="fieldCtrl.hasError('max')">
                                            {{t('budget.residual_budget_error') + ' ' + ('(' + (max([0, (spesa.budgetStudente?.budgetResiduoTerzoAnno-getSumImportiForOtherRequestsOfSameStudent(spesa, AnnoRiferimentoValues.TERZO))])?.toFixed(2) | currencyEuro) + ')')}}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)"
                                            class="pb-2">
                                            {{t('form.required')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <!--IMPORTO SPESA ALTRI FONDI-->
                                    <mat-form-field appearance="outline" class="w-full mb-2"
                                                    *ngIf="form?.get('importoSpesaAltriFondi') as fieldCtrl">
                                        <mat-label><strong>{{t('budget.expenditure_amount_other')}}</strong></mat-label>
                                        <input matInput
                                               currencyMask
                                               [options]="{ prefix: '',  suffix: ' €',
                                                           allowNegative: false,
                                                           thousands: '.', decimal: ',', align: 'left' }"
                                               [placeholder]="t('budget.expenditure_amount')"
                                               [formControl]="fieldCtrl">
                                        <mat-error
                                            *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)"
                                            class="pb-2">
                                            {{t('form.required')}}
                                        </mat-error>
                                        <mat-hint *ngIf="fieldCtrl.disabled">
                                            {{t('budget.fondi_budget_disabled')}}
                                        </mat-hint>
                                    </mat-form-field>
                                </div>

                                <!-- NOTE ALTRI FONDI-->
                                <mat-form-field appearance="outline" class="w-full"
                                                *ngIf="form?.get('notaAltriFondi') as fieldCtrl">
                                    <mat-label><strong>{{t('budget.other_founds_note')}}</strong></mat-label>
                                    <input matInput
                                           [placeholder]="t('budget.other_founds_note_placeholder')"
                                           [formControl]="fieldCtrl">
                                </mat-form-field>

                                <mat-error *ngIf="form.hasError('sumError')" class="text-sm text-center w-full">
                                    {{t('budget.sum_error')}}
                                </mat-error>

                            </div>
                        </div>
                    </div>


                </ng-container>

            </div>

        </div>


    </ng-template>


</ng-container>
