import {MatDialogModule} from '@angular/material/dialog';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {TranslocoModule} from '@ngneat/transloco';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {MatSelectModule} from '@angular/material/select';
import {FormComponent} from './form.component';
import {FormModuleService} from './form-module.service';
import {SharedModule} from '../../../../shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';

import {RemoveUnderscoresPipe} from '../../../../shared/pipe/remove-underscores.pipe';
import {CustomDateAdapter} from '../../../../shared/service/custom-date-adapter.service';
import {NgxCurrencyModule} from 'ngx-currency';
import {InputFileComponent} from "../input-file/input-file.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {InputFileWithPreviewModule} from "../../../../shared/input-file-with-preview/input-file-with-preview.module";
import {MatRadioModule} from "@angular/material/radio";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DocumentModule} from "../../../../shared/components/document/document.module";
import {FuseAlertModule} from "../../../../../@fuse/components/alert";
import {InputFileModule} from "../input-file/input-file.module";
import {RecaptchaComponentModule} from "../../../../shared/components/recaptcha/recaptcha-component.module";


export const CUSTOM_DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [
        FormComponent,
    ],
    exports: [
        FormComponent,
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        TranslocoModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FlexModule,
        MatSelectModule,
        MatDialogModule,
        SharedModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxCurrencyModule,
        MatCheckboxModule,
        InputFileWithPreviewModule,
        MatRadioModule,
        MatTooltipModule,
        DocumentModule,
        FuseAlertModule,
        InputFileModule,
        RecaptchaComponentModule
    ],
    providers: [
        FormModuleService,
        RemoveUnderscoresPipe,
        { provide: DateAdapter, useClass: CustomDateAdapter }
    ]
})
export class FormModule {

}
