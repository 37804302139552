import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {
    INFO_BOTTOM_COMPONENT_DATA,
    INFO_TOP_COMPONENT_DATA
} from "../../../layout/common/info-dialog/info-wrap.component";
import {AuthorityType, RichiestaDiRetribuzioneMobilitaInfoView} from "../../../../api-clients/generated/services";
import {getLanguagePathByProfile} from "../../utils/utils";
import {takeUntil} from "rxjs";
import {FuseMediaWatcherService} from "../../../../@fuse/services/media-watcher";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";

export interface FlowTimelineConfiguration {
    steps: FlowTimelineStepConfiguration[];
    legendStateType?: any;
    gridCols?: number;
    nestedConfigurationsCols?: number;
    nestedConfigurations?: FlowTimelineConfiguration[];
    data?: FlowTimelineData[];
}

export interface FlowTimelineStepConfiguration {
    labelPending?: string;
    labelRefused?: string;
    labelCompleted?: string;
    stateForLegend?: any;
    description?: string;
    alternativeSteps?: FlowTimelineStepConfiguration[];
}

export interface FlowTimelineData{
    state?: FlowTimelineStepState;
    date?: string;
    user?: string;
    motivazioneRifiuto?: string;
    skip?: boolean;
    profile?: AuthorityType;
    data?: FlowTimelineData[];
}

export enum FlowTimelineStepState{
    COMPLETED="COMPLETED",
    REFUSED="REFUSED",
    PENDING="PENDING",
    CREATED="CREATED",
    STATE_LEGEND="STATE_LEGEND"
}

@Component({
    selector: 'app-flow-timeline',
    templateUrl: './flow-timeline.component.html',
    styleUrls: ['./flow-timeline.component.scss']
})
export class FlowTimelineComponent extends AbstractDefaultComponent implements OnInit {

    @Input() configuration: FlowTimelineConfiguration;
    @Input() data: FlowTimelineData[];

    isScreenSmall: boolean;

    constructor(@Optional() @Inject(INFO_TOP_COMPONENT_DATA) public injectedData: { configuration: FlowTimelineConfiguration; data: FlowTimelineData[] },
                private _fuseMediaWatcherService: FuseMediaWatcherService) {
        super();
        this.configuration = this.configuration || this.injectedData?.configuration;
        this.data = this.data || this.injectedData?.data;

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnInit(): void {
        if(this.configuration?.data){
            this.data = this.configuration.data;
        }
    }


    protected readonly FlowTimelineStepState = FlowTimelineStepState;
    protected readonly getLanguagePathByProfile = getLanguagePathByProfile;


    getAlternativeStepsConfig(alternativeSteps: FlowTimelineStepConfiguration[]) {
        return {...this.configuration, steps: alternativeSteps};
    }

    // trimSkipped(steps: FlowTimelineStepConfiguration[]) {
    //     const firstSkippedIndex = this.data?.findIndex(d => d.skip);
    //     return steps?.slice(0, firstSkippedIndex !== - 1 ? firstSkippedIndex : undefined) ?? [];
    // }
}
