<div class="privacy">
    <div class="corpopagina">
        <p style="text-align: justify;"><span>Le presenti condizioni regolano l&rsquo;uso della piattaforma web ATENA (di seguito piattaforma) di propriet&agrave; della SOUTH ENGINEERING S.r.l. con Sede Legale al Corso Garibaldi, 38 &ndash; 80021 &ndash; Afragola (NA) e Sede Operativa alla Via Costantinopoli, 5 &ndash; 81031 &ndash; Aversa (CE), P.Iva: 08796931213 Registro Imprese: Napoli n. 08796931213.</span></p>
        <p style="text-align: justify;"><span>Il documento regola il rapporto tra la piattaforma e gli utenti che &egrave; disciplinato dal codice del consumo, dal codice civile e dalla normativa sul commercio elettronico.&nbsp;</span></p>
        <p style="text-align: justify;"><span>Il documento &egrave; caratterizzato da uno stile chiaro e sintetico al fine di agevolarne la lettura, la comprensione e l&rsquo;effettiva approvazione.</span></p>
        <p style="text-align: justify;"><span>Talora si &egrave; preferito soffermarsi pi&ugrave; sugli obblighi che sui diritti dell&rsquo;utente, inderogabilmente predeterminati dal Codice del consumo (D. Lgs. 6 settembre 2005, n. 206), consultabile al link&nbsp;</span><a href="https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legislativo:2005-09-06;206"><u><span style="color:#0000ff;">https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legislativo:2005-09-06;206</span></u></a><span>&nbsp;</span></p>
        <p style="text-align: justify;"><span>Per ulteriori chiarimenti &egrave; possibile contattare South Engineering S.r.l. all&rsquo;indirizzo di posta elettronica&nbsp;</span><a href="mailto:amministrazione@southengineering.it"><u><span style="color:#0000ff;">amministrazione@southengineering.it</span></u></a><span>&nbsp;</span></p>
        <ol>
            <li>
                <p style="text-align: justify;"><span><strong>FUNZIONE DELLA PIATTAFORMA</strong></span></p>
            </li>
        </ol>
        <p style="text-align: justify;"><span>La piattaforma consente la gestione delle carriere dei dottorandi iscritti ad un ciclo di dottorato presso l&rsquo;Universit&agrave; della Campania -&nbsp;</span><em><span>Luigi Vanvitelli</span></em><span>&nbsp;che ha acquistato licenza d&rsquo;uso della piattaforma stessa.</span></p>
        <p style="text-align: justify;"><span>A ciascun utente che accede alla piattaforma viene associato un profilo, corrispondente al ruolo ricoperto in Ateneo (ad es. Dottorando, Docente, Supervisore,&nbsp;</span><em><span>etc</span></em><span>.). Tutte le informazioni che vengono inserite dall&rsquo;utente sono visibili agli utenti con &ldquo;privilegi maggiori&rdquo;.</span></p>
        <p style="text-align: justify;"><span>Si considerano utenti con &ldquo;privilegi maggiori&rdquo; coloro i quali hanno un ruolo tale da dover necessariamente essere in grado di visualizzare i dati dell&rsquo;utente stesso. A titolo esemplificativo:</span></p>
        <ul>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>il docente di un corso che &egrave; stato inserito dallo studente nel proprio piano di studi, pu&ograve; visualizzare i dati di tutti i propri studenti;</span></p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>il coordinatore del ciclo di dottorato pu&ograve; visualizzare i dati di tutti gli studenti&nbsp;iscritti a quel determinato ciclo;</span></p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>il supervisore di uno o pi&ugrave; studenti, pu&ograve; visualizzare i dati di tutti gli studenti&nbsp;di cui &egrave; supervisore;</span></p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>gli utenti con potere di Amministratore di piattaforma e gli utenti con potere di super Amministratore della piattaforma.</span></p>
            </li>
        </ul>
        <ol start="2">
            <li>
                <p style="text-align: justify;"><span><strong>ACCESSO ALLA PIATTAFORMA E NAVIGAZIONE</strong></span></p>
            </li>
        </ol>
        <p style="text-align: justify;"><span>La piattaforma &egrave; accessibile all&rsquo;utente previa identificazione mediante credenziali assegnategli dall&rsquo;Ateneo.</span></p>
        <p style="text-align: justify;"><span>L&rsquo;accesso alla piattaforma implica l&rsquo;accettazione delle condizioni che ne regolano l&rsquo;uso da parte dell&rsquo;utente, il quale &egrave; tenuto, all&rsquo;atto dell&rsquo;accesso, a prenderne visione, potendo le stesse essere modificate in qualsiasi momento da South Engineering S.r.l. mediante l&rsquo;aggiornamento della presente pagina&nbsp;</span><em><span>web</span></em><span>.</span></p>
        <p style="text-align: justify;"><span>L&rsquo;accesso alla piattaforma &egrave; di norma &egrave; possibile tutti i giorni e a tutte le ore. Esso pu&ograve; essere sospeso da South Engineering S.r.l. in caso di manutenzione o in caso di forza maggiore, anche in difetto di preavviso,, senza che ci&ograve; implichi alcuna responsabilit&agrave; da parte della Societ&agrave; nei confronti dell&rsquo;utente.</span></p>
        <p style="text-align: justify;"><span>L&rsquo;utente &egrave; tenuto a mantenere riservati i propri dati di&nbsp;</span><em><span>login</span></em><span>&nbsp;e a comunicare immediatamente all&rsquo;indirizzo&nbsp;</span><a href="mailto:amministrazione@southengineering.it"><u><span style="color:#0000ff;">amministrazione@southengineering.it</span></u></a><span>&nbsp;l&rsquo;eventuale sottrazione o divulgazione involontaria degli stessi.</span></p>
        <p style="text-align: justify;"><span>In caso di accesso alla piattaforma da parte di terzi mediante utilizzo delle credenziali di un utente, South Engineering S.r.l. sospender&agrave; temporaneamente l&rsquo;</span><em><span>account&nbsp;</span></em><span>dello stesso, anche senza preavviso, onde svolgere le opportune verifiche in esito alle quali potr&agrave; chiudere definitivamente l&rsquo;</span><em><span>account</span></em><span>.</span></p>
        <ol start="3">
            <li>
                <p style="text-align: justify;"><span><strong>RESPONSABILIT&Agrave;</strong></span></p>
            </li>
        </ol>
        <p style="text-align: justify;"><span>South Engineering S.r.l. non risponde di eventuali errori o omissioni presenti nei contenuti pubblicati sulla piattaforma o degli eventuali problemi tecnici che dovessero insorgere nell&rsquo;utilizzo della stessa.</span></p>
        <p style="text-align: justify;"><span>South Engineering S.r.l. non risponde per le azioni eseguite da terzi mediante l&rsquo;utilizzo dei dati di&nbsp;</span><em><span>login</span></em><span>&nbsp;o dell&rsquo;identit&agrave; dell&rsquo;utente, che sar&agrave; in ogni caso tenuto a rispondere delle stesse.</span></p>
        <p style="text-align: justify;"><span>South Engineering S.r.l. non &egrave; responsabile n&eacute; a titolo contrattuale n&eacute; a titolo extracontrattuale di qualunque danno o perdita che dovesse eventualmente subire l&rsquo;utente o un terzo in occasione o in conseguenza dell&rsquo;utilizzo della piattaforma.</span></p>
        <p style="text-align: justify;"><span>L&rsquo;utente &egrave; consapevole che South Engineering S.r.l. pu&ograve; subire un danno irreparabile in conseguenza della violazione delle presenti Condizioni Generali o in conseguenza dell&rsquo;uso non autorizzato/improprio della piattaforma e dei relativi contenuti, pertanto, riconosce il diritto al risarcimento economico di South Engineering S.r.l. per violazione delle presenti Condizioni Generali o per l&rsquo;utilizzo non autorizzato/improprio della piattaforma.</span></p>
        <ol start="4">
            <li>
                <p style="text-align: justify;"><span><strong>RISOLUZIONE DEL RAPPORTO</strong></span></p>
            </li>
        </ol>
        <p style="text-align: justify;"><span>South Engineering S.r.l. pu&ograve;, in via temporanea o permanete, inibire l&rsquo;uso della piattaforma all&rsquo;utente che:</span></p>
        <ul>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>ne faccia un uso improprio o illegale;</span></p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>copi, riproduca, ripubblichi, scarichi, posti, diffonda, trasmetta ovvero utilizzi altrimenti con qualsiasi modalit&agrave; i contenuti pubblicati sulla piattaforma per scopi commerciali e non esclusivamente personali;</span></p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>interferisca, danneggi o manometta la piattaforma;</span></p>
            </li>
            <li style="list-style-type:disc;">
                <p style="text-align: justify;"><span>si renda gravemente inadempiente rispetto alle presenti condizioni.</span></p>
            </li>
        </ul>
        <ol start="5">
            <li>
                <p style="text-align: justify;"><span><strong>RISERVATEZZA DEI DATI E SICUREZZA DELLE INFORMAZIONI</strong></span></p>
            </li>
        </ol>
        <p style="text-align: justify;"><span>South Engineering S.r.l. protegge i dati personali degli utenti e li tratta in conformit&agrave; alla legge e a quanto precisato nell&rsquo;informativa sulla&nbsp;</span><em><span>privacy</span></em><span>&nbsp;disponibile nella piattaforma.</span></p>
        <p style="text-align: justify;"><span>Fermo restando che South Engineering S.r.l. si impegna all&rsquo;implementazione e all&rsquo;attuazione dei pi&ugrave; avanzati protocolli per garantire &nbsp; la sicurezza delle informazioni immesse sulla piattaforma dagli utenti.&nbsp;</span></p>
        <p style="text-align: justify;"><span>L&rsquo;utente riconosce e prende atto del fatto che l&rsquo;immissione delle informazioni nella piattaforma e la conseguente diffusione delle stesse nella rete internet mediante i servizi offerti da South Engineering S.r.l. &egrave; eseguita esclusivamente a proprio rischio. L&rsquo;utente riconosce che la rete internet non &egrave; controllata da South Engineering S.r.l. e che, per la sua peculiare struttura, nessuna entit&agrave; pubblica o privata e neppure South Engineering S.r.l. &egrave; in grado di garantirne e monitorarne costantemente le prestazioni e la funzionalit&agrave;. Per ogni azione di responsabilit&agrave; che dovesse essere effettuata da Terzi nei riguardi dell&rsquo;utente relativamente a violazioni alle leggi nazionali o internazionali effettuate tramite l&rsquo;utilizzo della piattaforma, l&rsquo;utente accetta di manlevare South Engineering S.r.l.</span></p>
        <ol start="6">
            <li>
                <p style="text-align: justify;"><span><strong>LEGGE APPLICABILE E FORO COMPETENTE IN CASO DI CONTROVERSIA</strong></span></p>
            </li>
        </ol>
        <p style="text-align: justify;"><span>Il contenuto delle presenti Condizioni Generali non pu&ograve; essere modificato dal comportamento di South Engineering S.r.l. o dell&rsquo;utente, anche se ripetuto.</span></p>
        <p style="text-align: justify;"><span>Oltre ad essere parte integrante e sostanziale del modulo di accesso, queste Condizioni Generali sono disponibili sulla piattaforma&nbsp;</span><em><span>web</span></em><span>&nbsp;al&nbsp;</span><em><span>link</span></em><span>&nbsp;"<a (click)="openPrivacyOrTerminiECondizioni(false)"><u><span style="color:#0000ff;">Condizioni Generali</span></u></a>" e sono consultabili in qualsiasi momento.</span></p>
        <p style="text-align: justify;"><span>Le eventuali controversie che dovessero insorgere tra l&rsquo;utente e South Engineering S.r.l. circa l&rsquo;interpretazione e l&rsquo;applicazione delle presenti condizioni verranno regolate dalla Legge italiana.</span></p>
        <p style="text-align: justify;"><span>Nei soli casi in cui non trova applicazione l&rsquo;art. 33 del Codice del Consumo (D. Lgs. 6 settembre 2005, n. 206), che individua il Giudice competente in quello del luogo in cui l&rsquo;utente risiede o ha eletto domicilio, la competenza a conoscere delle eventuali controversie &egrave; devoluta in via esclusiva al Foro di Santa Maria Capua Vetere.</span></p>
        <p style="text-align: justify;"><span>L&rsquo;utente in caso di controversia, oltre che al Giudice italiano, pu&ograve; alternativamente ricorrere ai sensi dell&rsquo;art. 14 par. 1 del Regolamento UE 524/2013 alla Piattaforma di Risoluzione Online per la Risoluzione delle Controversie in via stragiudiziale (Piattaforma ODR Europea), utilizzando il seguente link&nbsp;</span><a href="http://ec.europa.eu/odr"><u><span style="color:#0000ff;">http://ec.europa.eu/odr</span></u></a><span>.&nbsp;</span></p>
        <ol start="7">
            <li>
                <p style="text-align: justify;"><span><strong>SPECIFICA APPROVAZIONE DELLE CONDIZIONI</strong></span></p>
            </li>
        </ol>
        <p style="text-align: justify;"><span>L&rsquo;Utente ai sensi degli art. 1341 e 1342 c.c., all&rsquo;atto e per effetto della registrazione alla piattaforma, conferma di aver letto, compreso e specificamente approvato le condizioni presenti Condizioni Generali.</span></p>
    </div>
</div>
