/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle modalità di gestione utenti esterni disponibili
 */
export type ModalitaGestioneUtentiEsterni = 'KEYCLOAK' | 'IDP_ATENEO';

export const ModalitaGestioneUtentiEsterni = {
    KEYCLOAK: 'KEYCLOAK' as ModalitaGestioneUtentiEsterni,
    IDPATENEO: 'IDP_ATENEO' as ModalitaGestioneUtentiEsterni
};